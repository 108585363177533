<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Alive animal found</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-select
          v-model="newData.aliveAnimalFound"
          label="Alive animal found"
          :items="StdChoices"
          required
          :rules="required"
          @change="numberFieldSelectChange"
        />
        <v-text-field
          v-model.number="newData.numberOfAliveAnimals"
          label="Total number alive animals found"
          type="number"
          required
          outlined
          filled
          :rules="numberfieldcontrol"
          @input="totalNumber"
          :disabled="numberFieldDisable"
        />

        <!-- AliveAnimalInfoPage -->
        <!-- Extention part -->
        <template v-if="newData.numberOfAliveAnimals > 0">
          <div v-for="(addedform, i) in adder" :key="i">
            <v-divider class="mb-5" />
            <p>Use ADD button in the end to extend the form for items that have different information</p>
            <v-text-field 
              v-model.number="addedform.number"
              :rules="numberRule" 
              label="# Animals of same description"
              type="number"
              outlined
              @input="checknumbers"
            />
            <v-select
              v-model="addedform.species"
              label="Animal species"
              :items="AnimalSpecies"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.notListedSpecies"
              label="If other specify"
              v-if="addedform.species === 'Other'"
              :disabled="!addedform.number"
            />
            <v-select
              v-model="addedform.marking"
              label="Identification marking"
              :items="StdChoicesExt"
              :disabled="!addedform.number"
            />
            <v-select
              v-model="addedform.markingType"
              label="Type of marking"
              :items="Markings"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.markingCodes"
              label="Marking codes"
              :disabled="!addedform.number"
            />
            

            <!-- START LatLng Control -->
            <p>Select marker from <b>Map Control</b> to copy values from. Use 
            <b>Confirm Marker</b> or fill in coordinates</p>
            <v-select
              v-model="pivot[i]"
              label="Select marker"
              item-text="name"
              return-object
              :items="foomarkers"
              @change="updateMarker"
            >
              <template v-slot:item="{ item }">
                <p>{{ item.name }}</p>
              </template>
              <template slot="append-item">
                <v-btn block @click.prevent="addMarker(i)">Add marker</v-btn>
              </template>
            </v-select>

            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model.number="addedform.lat"
                      label="Lat"
                      type="number"
                      required
                      outlined
                      :rules="required"
                      :disabled="!addedform.number"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model.number="addedform.lng"
                      label="Long"
                      type="number"
                      required
                      outlined
                      :rules="required"
                      :disabled="!addedform.number"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- END LatLng Control -->
            
            <!-- <v-text-field
              v-model.number="addedform.lat"
              label="Lat"
              type="number"
              :disabled="!addedform.number"
              outlined
            />
            <v-text-field
              v-model.number="addedform.lng"
              label="Lng"
              type="number"
              :disabled="!addedform.number"
              outlined
            /> -->
            
            <v-select
              v-model="addedform.amimalState"
              label="State of animal"
              :items="AnimalState"
              :disabled="!addedform.number"
            />
            <v-select
              v-model="addedform.firstAidType"
              label="First aid provided"
              :items="FirstAidType"
              :disabled="!addedform.number"
            />
            <v-select
              v-model="addedform.firstAidProvider"
              label="First aid provided by"
              :items="FirstAidProvider"
              :disabled="!addedform.number"
            />
            <v-select
              v-model="addedform.finalStatus"
              label="Final status"
              :items="FinalStatusValues"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.nameRehabilitationCentre"
              label="Sent to rehabilitation center (name)"
              :disabled="!addedform.number"
            />
          </div>
        </template>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn
        v-if="addbuttonrules"
        :disabled="addbuttondis"
        color="warning"
        @click.prevent="addform"
      >
        ADD
      </v-btn>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        color="grey lighten-2"
        @click="reset"
      >Clear</v-btn>
      <v-btn
        :disabled="!valid || !isDirty"
        color="#7eb09f"
        @click="validate"
      >Save
        <v-progress-circular color="biscuit" v-if="loading" indeterminate/>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { sync, get, call } from 'vuex-pathify'
import MultiPageMixin from '@/mixins/MultiPage.js'
import AliveAnimalInfo from '@/models/aliveanimalinfo.js'
import AliveAnimalInfoPage from '@/models/aliveanimalinfopage.js'
import { AnimalSpecies, AnimalState, FirstAidType, FirstAidProvider, FinalStatusValues, StdChoices, StdChoicesExt, Markings } from '@/utils/selectoptions.js'
import { cloneObject, getMarkers } from '@/utils/general.js'


export default {
  mixins: [MultiPageMixin],
  props: {
    AliveAnimalInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    passed: {
      type: Boolean,
      default: false
    },
    entrycode: {
      type: Number
    }
  },
  data: function () {
    return {
      foomarkers: [],
      pivot: [],
      pageclass: AliveAnimalInfoPage,
      pagesarray: 'aliveanimalinfopages',
      numbername: 'numberOfAliveAnimals',
      valid: false,
      loading: false,
      addbuttondis: true,
      adder: [],
      numberRule: [
          v => (this.valTotal(v)) || 'Input cannot exceed total number'
      ],
      maintotal: 0,
      required: [
        v => !!v || 'Field is required'
      ],
      // The following rule is used to control the number values
      // based on the select option. Used together with the 
      // numberFieldDisable
      numberfieldcontrol: [
        // v => !!v || 'Field is required',
        v => { if (!isNaN(parseFloat(v)) && v >= -1 && v <= 999) return true;
          return 'Number has to be between -1 and 999';}
      ],
      numberFieldDisable: false,
      StdChoices: StdChoices,
      AnimalSpecies: AnimalSpecies,
      AnimalState: AnimalState,
      FirstAidType: FirstAidType,
      FirstAidProvider: FirstAidProvider,
      FinalStatusValues: FinalStatusValues,
      StdChoicesExt: StdChoicesExt,
      Markings: Markings
    };
  },
  methods: {
    ...call('mapform', [
      'mapaddMarker'
    ]),
    validate () {
      this.loading = true
      if (this.$refs.form.validate()) {
        this.sanitycheck()
        this.newData.aliveanimalinfopages = this.adder
        this.$store.dispatch('incidentsnew/updAlanimal',
          {entrycode: this.entrycode,
           payload: this.newData})
          .then(() => {
            this.loading = false
            this.initData = cloneObject(this.newData)
          })
          .catch(e => {
            console.log('WE GOT THE ERROR')
            console.log(e)
          })
      } else {
        this.loading = false
      }
    },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    addMarker (i) {
      this.mapaddMarker()
      this.foomarkers.push(this.mymarker)
      this.pivot[i] = {'name': this.mymarker.name}
    },
    updateMarker(e) {
      console.log(e)
    },
    numberFieldSelectChange (e) {
      switch (e) {
        case 'Unknown':
          this.newData.numberOfAliveAnimals = -1
          this.numberFieldDisable = true
          break;
        case 'No':
          this.newData.numberOfAliveAnimals = 0
          this.numberFieldDisable = true
          break;
        case 'Yes':
          // FIXME: no need to do anything in this case
          // this.newData.numberOfAliveAnimals = null
          this.numberFieldDisable = false
          break;
      }
    }
  },
  created () {
    this.newData = new AliveAnimalInfo()
    this.foomarkers = getMarkers(this.$store.state.incidentsnew.incident)
  },
  mounted () {
    this.$root.$on('marker-control-complete', (msg) => {
      // find which entry of the pivot has the name of the marker
      for (let [index, val] of this.pivot.entries()) {
          if (val.name === msg.name ) {
            this.adder[index].lat = msg.position.lat
            this.adder[index].lng = msg.position.lng
          }
      }
    })

    if (this.passed) {
      this.initData = {...this.AliveAnimalInfo}
      this.newData = JSON.parse(JSON.stringify(this.AliveAnimalInfo))

      if('aliveanimalinfopages' in this.initData) {
        if (this.initData.aliveanimalinfopages.length > 0) {
          this.adder = [...this.newData.aliveanimalinfopages]
          this.maintotal = this.newData.numberOfAliveAnimals
        }
      }
      this.$refs.form.resetValidation()
    }
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...get('form', [
      'isDirty'
    ]),
    ...sync('mapform', {
      mymarker: "marker"
    })
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>