<template>
  <v-card>
    <v-card-title> PoisonBaitInfoPage </v-card-title>
    <v-card-text>
      <v-form>
        <v-select v-model="baitType" label="Bait Type" :items="BaitType" />
        <v-text-field v-model="baitOther" label="Specify type of bait if other" v-if="otherbait"/>
        <v-text-field v-model="lat" label="Lat" type="number" />
        <v-text-field v-model="lng" label="Lng" type="number" />
        <v-text-field v-model="codeAnalysis" label="Code for analysis" />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    baitType: "",
    baitOther: "",
    lat: "",
    lng: "",
    codeAnalysis: "",
    BaitType: [
      "Whole carcass",
      "Piece of meat",
      "Piece of sausage",
      "Liver - intestines",
      "Bones",
      "Mincemeat",
      "Hide",
      "Fat",
      "Paraffin bait - cyanide",
      "Fish",
      "Cheese",
      "Fruit",
      "Water",
      "Seeds",
      "Other",
      "Unknown",
    ],
  }),
  computed: {
    otherbait () {
      return true ? this.baitType === 'Other' : false
    }
  }
};
</script>