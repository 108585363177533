import StaticPageService from '@/services/StaticPageService'

export default {
    async getPages({ commit }) {
        try {
            commit('SET_LOADING', true)
            let pages = await StaticPageService.getStaticPages()
            commit('ADD_PAGES', pages.data)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log('Error when getting the pages')
            commit('STATICPAGES_ERROR', error)
        }
    },
    async getPage({ commit }, id) {
        try {
            commit('SET_LOADING', true)
            let page = await StaticPageService.getStaticPageById(id)
            commit('ADD_PAGE', page.data)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log(`Error when getting page: ${id}`)
            commit('STATICPAGES_ERROR', error)
        }
    },
    async createPage({ commit, rootState }, page) {
        try {
            commit('SET_LOADING', true)
            let page_post = await StaticPageService.postStaticPage(page, rootState.newauth.token)
            commit('ADD_PAGE', page_post.data)
            commit('PUSH_PAGE', page_post.data)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log('Error when posting page')
            commit('STATICPAGES_ERROR', error)
        }
    },
    async updatePage({ commit, rootState }, {id, page}) {
        try {
            commit('SET_LOADING', true)
            let page_upd = await StaticPageService.putStaticPage(id, page, rootState.newauth.token)
            let pageData = page_upd.data
            commit('ADD_PAGE', pageData)
            commit('UPDATE_PAGE', {id, pageData} )
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log(`Something went wrong when updating the page: ${id}`)
            commit('STATICPAGES_ERROR', error)
        }
    },
    async deletePage({ commit, rootState }, id) {
        try {
            commit('SET_LOADING', true)
            await StaticPageService.deleteStaticPage(id, rootState.newauth.token)
            commit('DELETE_PAGE', id)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log(`Error when deleting page: ${id}`)
            commit('STATICPAGES_ERROR', error)
        }
    }
    
}