<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Dead animal found</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-select
          v-model="newData.deadAnimalFound"
          label="Dead animal found"
          :items="StdChoices"
          required
          :rules="required"
        />
        <v-text-field
          v-model="newData.numberOfDeadAnimals"
          label="Total number dead animals found"
          type="number"
          required
          outlined
          :rules="required"
          @input="totalNumber"
          
        />


        <!-- Extention part -->
        <template v-if="newData.numberOfDeadAnimals > 0">
          <div v-for="(addedform, i) in adder" :key="i">
            <v-text-field 
              v-model="addedform.number"
              @change="subformNumber"
              :rules="numberRule" 
              label="# Animals of same description"
            />
            <v-select
              v-model="addedform.species"
              label="AnimalSpecies"
              :items="AnimalSpecies"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.notListedSpecies" 
              label="If other specify"
              v-if="addedform.species === 'Other'"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.lat"
              label="Lat"
              type="number"
              :disabled="!addedform.number"
              outlined
            />
            <v-text-field
              v-model="addedform.lng"
              label="Lng"
              type="number"
              :disabled="!addedform.number"
              outlined
            />
            <v-select
              v-model="addedform.carcass"
              label="StateCarcass"
              :items="StateCarcass"
              :disabled="!addedform.number"
            />
            <v-textarea
              v-model="addedform.comments"
              label="Comments"
              :disabled="!addedform.number"
              filled
            />
          </div>
        </template>
        

        <!-- END TESTING -->
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="grey lighten-2"
        @click="reset"
      >Clear</v-btn>
      <v-btn
        :disabled="!valid || !isDirty"
        color="#7eb09f"
        @click="validate"
      >Submit
        <v-progress-circular color="biscuit" v-if="loading" indeterminate/>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { sync, get } from 'vuex-pathify'
import AddFormPagesMixin from '@/mixins/AddFormPages.js'
import DeadAnimalInfo from '@/models/deadanimalinfo.js'
import DeadAnimalInfoPage from '@/models/deadanimalinfopage.js'
import { AnimalSpecies, StateCarcass, StdChoicesDAI } from '@/utils/selectoptions.js'

export default {
  mixins: [AddFormPagesMixin],
  props: {
    DeadAnimalInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    passed: {
      type: Boolean,
      default: false
    },
    entrycode: {
      type: Number
    }
  },
  data: function () {
    return {
      valid: true,
      loading: false,
      required: [
        v => !!v || 'Field is required'
      ],
      StdChoices: StdChoicesDAI,
      AnimalSpecies: AnimalSpecies,
      StateCarcass: StateCarcass
    };
  },
  methods: {
    validate () {
      this.loading=true
      if(this.$refs.form.validate()) {
        this.newData.deadanimalinfopages = this.adder
        this.$store.dispatch('incidentsnew/updDanimal',
          {entrycode: this.entrycode,
           payload: this.newData})
          .then(() => {
            this.loading = false
            this.initData = {...this.newData}
          })
          .catch(e => {
            console.log('WE GOT THE ERROR')
            console.log(this.$store.state.incidentsnew.error)
          })  // FIXME
      } else {
        this.loading = false
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  },
  created () {
    this.newData = new DeadAnimalInfo()
  },
  mounted () {
    if (this.passed) {
      this.initData = {...this.DeadAnimalInfo}
      this.newData = {...this.DeadAnimalInfo}

      if ('deadanimalinfopages' in this.initData) {   // in case of empty form
        if (this.initData.deadanimalinfopages.length > 0) {
          this.adder = [...this.newData.deadanimalinfopages]
          this.maintotal = this.newData.numberOfDeadAnimals
        }
      }
      this.$refs.form.resetValidation()
    }
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...get('form', [
      'isDirty'
    ])
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>