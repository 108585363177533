import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import NProgress from 'nprogress'

Vue.use(VueRouter)

// FIXME: find a better way to share config variables.
const AUTHENABLED = true

const newinc = {
    path: '/newinc/',
    meta: {
        name: 'New Incident',
        requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "editincident" */ `@/views/EditIncident.vue`),
    children: [
        {
            name: 'New Start',
            path: '',
            component: () => import(/* webpackChunkName: "informant" */ '@/components/incidents/edit/Informant'),
        },
        {
            name: 'newInformant',
            path: 'informant',
            components: {
                default: () => import(/* webpackChunkName: "informant" */ '@/components/incidents/edit/Informant'),
                control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue')
            }
        },
        {
            name: 'newNotificationInfo',
            path: 'notification',
            components: {
              default: () => import(/* webpackChunkName: "notification" */ '@/components/incidents/edit/NotificationInfo'),
              control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
              debugpanel: () => {
                if (router.app.$debugApp)
                  return import('@/components/DebugPanel.vue') 
                else return import('@/components/NoOP.vue')
              }
            }
        }
    ]
}

export default newinc