import Vue from 'vue'
// import './plugins/axios'
import './plugins'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'leaflet/dist/leaflet.css'
import 'nprogress/nprogress.css'
// import './plugins/base'
import i18n from './i18n'

import { sync } from 'vuex-router-sync'

let debugapp = (process.env.VUE_APP_DEBUG === 'true') ? true : false
Vue.prototype.$debugApp = debugapp

// part of the OLD IMPLEMENTATION

// import { setupI18n } from './plugins/i18n'
// const i18n = setupI18n(Vue)

Vue.config.productionTip = false

// Vue.use(MultiFiltersPlugin)

sync(store, router)

new Vue({
  i18n,
  router,
  store,
  vuetify,
  methods: {
    setLanguage(language) {
      const vm = this;

      localStorage.setItem('language', language)
      document.documentElement.lang = language
      vm.$i18n.locale = language
      vm.$vuetify.lang.current = language
    }
  },
  created () {
    const vm = this
    
    vm.setLanguage('en')
  },
  render: h => h(App)
}).$mount('#app')
