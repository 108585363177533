import axios from 'axios';

// const API_URL = 'http://localhost:8001/auth/jwt/login';
// const REFRESH = 'http://localhost:8001/auth/jwt/refresh';

// class AuthService {
//     login(user) {
//         const params = new URLSearchParams()
//         params.append('username', user.username)
//         params.append('password', user.password)
//         return axios
//             .post(API_URL, params)
//             .then(response => {
//                 if (response.data.access_token) {
//                     localStorage.setItem('user', JSON.stringify(response.data));
//                 }

//                 return response.data;
//             });
//     }

//     logout() {
//         localStorage.removeItem('user');
//     }

//     refresh(access_token) {
//         return axios.post(REFRESH,
//             access_token,
//             {headers: 
//                 {'Authorization': 'bearer ' + access_token}
//             }
//             )
//             .then(response => {
//                 if (response.data.access_token) {
//                     localStorage.setItem('user', JSON.stringify(response.data))
//                 }
//                 return response.data
//             })
//             .catch(error => {
//                 console.log(error)
//             })
//     }

//     // register(user) {
//     //     return axios.post(API_URL + 'signup', {
//     //         username: user.username,
//     //         email: user.email,
//     //         password: user.password
//     //     });
//     // }
// }

// export default new AuthService();

// new AuthService
// const apiUrl = 'http://192.168.2.189:8001'
const apiUrl = process.env.VUE_APP_BASE_URL

function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}

export const api = {
    async logInGetToken(username, password) {
        const params = new URLSearchParams();
        params.append('username', username)
        params.append('password', password)

        return axios.post(`${apiUrl}/auth/jwt/login`, params)
    },
    async getMe(token) {
        return axios.get(`${apiUrl}/users/me`, authHeaders(token))
    },
    /**
     *  Forgot password functionality,
     *  post to endpoint with email in the body
     */
    async passwordRecovery(email) {
        return axios.post(`${apiUrl}/auth/forgot-password`, {email: email})
    },
    async resetPassword(password, token) {
        return axios.post(`${apiUrl}/auth/reset-password`, 
        {password: password,
         token: token})
    },
    async refresh (token) {
        return axios.get(`${apiUrl}/auth/jwt/refresh`, authHeaders(token))
    }
}

