import axios from 'axios'

function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}


const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_V1,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})


export default {
    async postUnDelete (unid, token) {
        return apiClient.post('und/', {'unid': unid}, authHeaders(token))
    },
    async getUnDelete (token) {
        return apiClient.get('und/', authHeaders(token))
    },
    async delUnDelete (unid, token) {
        return apiClient.delete(`/und/${unid}`, authHeaders(token))
    }
}