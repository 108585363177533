// Pathify
import { make } from 'vuex-pathify'
import ChartService from '@/services/ChartService'

const state = {
  incidentperyear: {},
  incpermonth: {},
  // animals: {},
  loading: false,
  yearloaded: false,
  monthloaded: false,
  complaintsloaded: false,
  courtloaded: false,
  autopsyloaded: false,
  animalsloaded: false,
  toxinsloaded: false,
  complaints: {},
  court: {},
  autopsy: {},
  country: "all",
  stats: {}
}

const mutations = {
  ...make.mutations(state),
  ADD_INCIDENTPERYEAR(state, {country, payload}) {
    if (Object.hasOwn(state.stats, country) == true) {
      state.stats[country]['year'] = payload 
    } else {
      state.stats[country] = {}
      state.stats[country]['year'] = payload
    }
  },
  ADD_INCPERMONTH(state, {country, payload}) {
    if (Object.hasOwn(state.stats, country) == true) {
      state.stats[country]['month'] = payload 
    } else {
      state.stats[country] = {}
      state.stats[country]['month'] = payload
    }
  },
  ADD_COMPLAINTS(state, {country, payload}) {
    if (Object.hasOwn(state.stats, country) == true) {
      state.stats[country]['complaints'] = payload 
    } else {
      state.stats[country] = {}
      state.stats[country]['complaints'] = payload
    }
  },
  ADD_COURT(state, {country, payload}) {
    if (Object.hasOwn(state.stats, country) == true) {
      state.stats[country]['court'] = payload 
    } else {
      state.stats[country] = {}
      state.stats[country]['court'] = payload
    }
  },
  ADD_AUTOPSY(state, {country, payload}) {
    if (Object.hasOwn(state.stats, country) == true) {
      state.stats[country]['autopsy'] = payload 
    } else {
      state.stats[country] = {}
      state.stats[country]['autopsy'] = payload
    }
  },
  ADD_ANIMALS(state, {country, payload}) {
    if (Object.hasOwn(state.stats, country) == true) {
      state.stats[country]['animals'] = payload 
    } else {
      state.stats[country] = {}
      state.stats[country]['animals'] = payload
    }
  },
  ADD_TOXINS(state, {country, payload}) {
    if (Object.hasOwn(state.stats, country) == true) {
      state.stats[country]['toxins'] = payload 
    } else {
      state.stats[country] = {}
      state.stats[country]['toxins'] = payload
    }
  },
}

const actions = {
    ...make.actions(state),
    async setIncidentperyear({ commit }, country) {
      try {
          commit('loading', true)
          let data = await ChartService.getIncidentsPerYear(country)
          let payload = data.data
          commit('ADD_INCIDENTPERYEAR', {country, payload})
          commit('yearloaded', true)
      } catch (error) {
          console.log(error)
      } finally {
        commit('loading', false)
      }
    },
    async setIncpermonth({ commit }, country) {
      try {
        commit('loading', true)
        let data = await ChartService.getIncidentsPerMonth(country)
        let payload = data.data
        commit('ADD_INCPERMONTH', {country, payload})
        commit('monthloaded', true)
      } catch (error) {
        console.log(error)
      } finally {
        commit('loading', false)
      }
    },
    async setIncComplaints({ commit }, country) {
      try {
        commit('loading', true)
        let data = await ChartService.getIncComplaints(country)
        let payload = data.data
        commit('ADD_COMPLAINTS', {country, payload})
        commit('complaintsloaded', true)
      } catch (error) {
        console.log(error)
      } finally {
        commit('loading', false)
      }
    },
    async setIncCourt({ commit }, country) {
      try {
        commit('loading', true)
        let data = await ChartService.getIncCourt(country)
        let payload = data.data
        commit('ADD_COURT', {country, payload})
        commit('courtloaded', true)
      } catch (error) {
        console.log(error)
      } finally {
        commit('loading', false)
      }
    },
    async setIncAutopsy({ commit }, country) {
      try {
        commit('loading', true)
        let data = await ChartService.getIncAutopsy(country)
        let payload = data.data
        commit('ADD_AUTOPSY', {country, payload})
        commit('autopsyloaded', true)
      } catch (error) {
        console.log(error)
      } finally {
        commit('loading', false)
      }
    },
    async setAnimals({ commit }, country) {
      try {
        commit('loading', true)
        let data = await ChartService.getAnimals(country)
        let payload = data.data
        commit('ADD_ANIMALS', {country, payload})
        commit('animalsloaded', true)
      } catch (error) {
        console.log(error)
      } finally {
        commit('loading', false)
      }
    },
    async setToxins({ commit }, country) {
      try {
        commit('loading', true)
        let data = await ChartService.getToxins(country)
        let payload = data.data
        commit('ADD_TOXINS', {country, payload})
        commit('toxinsloaded', true)
      } catch (error) {
        console.log(error)
      } finally {
        commit('loading', false)
      }
    },
    async fetchall({ commit, dispatch }) {
      try {
        await dispatch('setIncidentperyear')
        await dispatch('setIncpermonth')
        commit('loaded', true)
      } catch (error) {
        console.log(error)
      }
    },
    animals({state}, country) {
      return new Promise((resolve, reject) => {
        const checkInterval = setInterval(() => {
          if (state.animalsloaded) {
            clearInterval(checkInterval)
            resolve(state.stats[country].animals)
          }
        }, 100)
      })
    },
    statCategory({state}, {country, category}) {
      return new Promise((resolve, reject) => {
        const checkInterval = setInterval(() => {
          if (state[`${category}loaded`]) {
            clearInterval(checkInterval)
            resolve(state.stats[country][category])
          }
        }, 100)
      })
    }
  }

const getters = {
  // animals: (state) => (country) => {
  //   if (Object.hasOwn(state.stats, country) == true) {
  //     return state.stats[country].animals
  //   } else {
  //     return []
  //   }
  // },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
