import axios from 'axios'

function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}


const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_V1,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})


export default {
    async postSetVersion (verinc, token) {
        return apiClient.post('ver/', {'verinc': verinc}, authHeaders(token))
    },
    async getVersions (entrycode, token) {
        return apiClient.get(`ver/inc/${entrycode}`, authHeaders(token))
    },
    async delVersion (verinc, token) {
        return apiClient.delete(`/ver/${verinc}`, authHeaders(token))
    }
}