import axios from 'axios'

function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}


const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_V1,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})


export default {
    async postSearch (query, token) {
        return apiClient.post('s/', {'query': query}, authHeaders(token))
    }
}