export default class DisposalOfDeadAnimals {
    constructor(numberOfAnimals, disposalMethod, disposedAtLocIndByAuthorities, location, disposedBy, takenByAuthToBeDisposed, takenByHiredCompany, companyName) {
        this.numberOfAnimals = numberOfAnimals;
        this.disposalMethod = disposalMethod;
        this.disposedAtLocIndByAuthorities = disposedAtLocIndByAuthorities;
        this.location = location;
        this.disposedBy = disposedBy;
        this.takenByAuthToBeDisposed = takenByAuthToBeDisposed;
        this.takenByHiredCompany = takenByHiredCompany;
        this.companyName = companyName;        
    }
}