// Utilities
import { dispatch, make } from 'vuex-pathify'
import api from '../../services/VersionService'


const state = {
  versions: [],
  version: 0,
  total: 0,
  loading: false,
  reload: null,
}

const mutations = {
    ...make.mutations(state),
    DEL_VERSION(state, id) {
        let i = state.versions.map(item => item.id).indexOf(id)
        state.versions.splice(i, 1)
    },
    DO_RELOAD(state, payload) {
        state.reload = new Date().getTime()
    }
}

// Used as a template
// const REPLACEME = async ({commit, dispatch, state, rootState}) => {
//     try {
//         commit('loading', true)

//     } catch (error) {
//         commit('error', error)
//         console.log(error)
//     } finally {
//         commit('loading', false)
//     }
// }

/**
 * Fetches all IncidentVersion objects from backend, for the given entrycode
 * 
 * @param {Object} {commit,rootState} - vuex methods
 * @param {int} entrycode - Incident's entrycode
 */
const fetchVersions = async ({commit, rootState}, entrycode) => {
    try {
        commit('loading', true)
        let response = await api.getVersions(entrycode, rootState.newauth.token)
        commit('versions', response.data)
    } catch (error) {
        commit('error', error)
        console.log(error)
    } finally {
        commit('loading', false)
    }
}

/**
 * Fetches all IncidentVersion objects for the current Incident
 * commited to the state. Mostly used with the Version Component.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 */
const fetchVersionIncident = async ({commit, rootState}) => {
    try {
        commit('loading', true)
        let response = await api.getVersions(
            rootState.incidentsnew.incident.entrycode,
            rootState.newauth.token)
        commit('versions', response.data)
    } catch (error) {
        commit('error', error)
        console.log(error)
    } finally {
        commit('loading', false)
    }
}

/**
 * Deletes IncidentVersion with given id.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {int} id - UnDelete id
 */
const delVersion = async ({commit, dispatch, rootState}, id) => {
    try {
        commit('loading', true)
        let response = await api.delVersion(id, rootState.newauth.token)
        commit('DEL_VERSION', id)
        dispatch('messages/showMessage',
                 {message: `IncidentVersion configuration deleted`, color: 'success'},
                 {root: true})
    } catch (error) {
        commit('error', error)
        dispatch('messages/showMessage',
                {message: `Error when deleting IncidentVersion configuration`, color: 'error'},
                {root: true})
    } finally {
        commit('loading', false)
    }
}


/**
 * Performs the restore operation on Incident with the selected
 * IncidentObject data, provided in the referenced id.
 * 
 * @param {Object} param0 - vuex methods
 * @param {int} id - UnDelete id
 */
const setVersion = async ({commit, dispatch, rootState}, id) => {
    try {
        commit('loading', true)
        let response = await api.postSetVersion(id, rootState.newauth.token)
        dispatch('messages/showMessage',
                {message: `Set Incident Version to: ${response.data.data.version}.`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', error)
        dispatch('messages/showMessage',
                {message: `Error when setting Incident Version with: ${id}`, color: 'error'},
                {root: true})
    } finally {
        commit('loading', false)
    }
}

const doReload = ({commit}) => {
    commit('DO_RELOAD', true)
}

const actions = {
    fetchVersions,
    fetchVersionIncident,
    delVersion,
    setVersion,
    doReload
}

const getters = {
    getVersions: state => state.versions,
    getLoading: state => state.loading,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
