/**
 * Vuex
 * 
 * @library
 * 
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

// Store functionality
import actions from './actions'
import getters from './getters'
import modules from './modules'
import mutations from './mutations'
import state from './state'

import pathify from '@/plugins/vuex-pathify'

// persisted store plugin
import createPersistedState from 'vuex-persistedstate'

const authState = createPersistedState({
  paths: ['newauth', 'incidents', 'incidentsnew']
})

// const incidentState = createPersistedState({
//   paths: ['incidents']
// })

Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
  actions,
  getters,
  modules,
  mutations,
  state,
  // plugins: [authState]
  plugins: [
    pathify.plugin,
    authState
  ]
})

export default store