var MultiPage = {
    data: function () {
        return {
            addbuttondis: true,
            adder: [],
            numberRule: [
                v => (this.valTotal(v)) || 'Input cannot exceed total number'
            ],
            maintotal: 0,
            pageclass: Object
        }
    },
    methods: {
        subformNumber(e) {
            let number = Number(e || 0)
            let total = 0
            this.adder.forEach(noform => {
                total += Number(noform.number || 0)
            })
            if (number > 0 && this.maintotal > total) {
                this.adder.push({})
            }
        },
        valTotal(v) {
            let total = 0
            this.adder.forEach(noform => {
                total += Number(noform.number || 0)
            })
            if (total > this.maintotal)
                return false
            else return true
        },
        totalNumber(e) {
            this.maintotal = Number(e || 0)
            const one = 1
            if (this.adder.length === 0) {
                this.adder = [...Array(one).keys()].map(i => this.adder[i] || new this.pageclass())
            }
            this.checknumbers()
        },
        addform() {
            this.adder.push(new this.pageclass())
            const _key = this.pagesarray
            this.newData[_key] = this.adder
        },
        checknumbers() {
            // if the total number is not 0,
            // and we have this.adder, then check the numbers
            // and enable button only if the total number X
            // is 0 < X < total
            let subnumber = 0
            if (this.adder.length > 0) {
                this.adder.forEach(function (subpage, index, arr) {
                    subnumber += Number(subpage.number)
                })
            }
            const _key = this.numbername
            let value = this.newData[_key]
            if (subnumber > 0 && subnumber < value ) {
                this.addbuttondis = false
            } else {
                this.addbuttondis = true
            }
        },
        sanitycheck() {
            this.adder = this.adder.filter(item => item.number != 0)
        }
    },
    computed: {
        addbuttonrules() {
            // the number of animals needs to be bigger than 0
            // the number in the new form in the this.adder needs to be between
            // 1 and numberofanimals
            const _key = this.numbername
            let value = this.newData[_key]
            if (value > 0) {
                return true
            } else if (value === 0) {
                return false
            }
        },
    }
}

export default MultiPage