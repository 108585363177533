<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Toxins used</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      > 
        <v-select 
          v-model="newData.toxin" 
          label="Toxin" 
          :items="Toxin"
          @change="updateToxinInfo"
        />
        <v-select
          v-model="newData.typeOfPoison"
          label="Poison type"
          :items="PoisonType"
        />
        <v-select 
          v-model="newData.group"
          label="Toxin group"
          :items="ToxinGroup"
        />
        <v-select
          v-model="newData.legalStatus"
          label="Legal status"
          :items="LegalStatus"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        color="grey lighten-2"
        @click="reset"
      >Clear</v-btn>
      <v-btn
        :disabled="!valid || !isDirty"
        color="#7eb09f"
        @click="validate"
      >Save
         <v-progress-circular color="biscuit" v-if="loading" indeterminate/>     
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { sync, get } from 'vuex-pathify'
import ToxinsUsed from '@/models/toxinsused.js'
import { PoisonType, ToxinGroup, LegalStatus, Toxins } from '@/utils/selectoptions.js'

export default {
  props: {
    ToxinsUsed: {
      type: Object,
      default: function () {
        return {}
      }
    },
    passed: {
      type: Boolean,
      default: false
    },
    entrycode: {
      type: Number
    }
  },
  data: function () {
    let _toxin = []
    let _poisontype = []
    let _group = []

    for (let i = 0; i < Toxins.length; i++ ) {
      _toxin.push(Toxins[i].name)
      if (Toxins[i].type) _poisontype.push(Toxins[i].type)
      if (Toxins[i].group) _group.push(Toxins[i].group)
    }
    return {
      valid: false,
      loading: false,
      required: [
        v => !!v || 'Field is required'
      ],
      // PoisonType: PoisonType,
      // Toxin: Toxin,
      // ToxinGroup: ToxinGroup,
      Toxin: _toxin,
      PoisonType: PoisonType,
      ToxinGroup: ToxinGroup,
      LegalStatus: LegalStatus
    }
  },
  methods: {
    validate () {
      this.loading = true
      if (this.$refs.form.validate()) {
        this.$store.dispatch('incidentsnew/updToxins', {entrycode: this.entrycode, payload: this.newData})
          .then(() => {
            this.loading = false
            this.initData = {...this.newData}
          })
          .catch(e => {
            console.log('We got the error')
            console.log(this.$store.state.incidentsnew.error)
          }) // FIXME
      } else {
        this.loading = false
      }
    },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    updateToxinInfo (e) {
      for (let i=0; i < Toxins.length; i++ ) {
        if(Toxins[i].name === e) {
          this.newData.typeOfPoison = Toxins[i].type
          this.newData.group = Toxins[i].group
          break;
        }
      }
    }
  },
  created () {
    this.newData = new ToxinsUsed()
  },
  mounted () {
    if (this.passed) {
      this.initData = {...this.ToxinsUsed}
      this.newData = this.ToxinsUsed
      this.$refs.form.resetValidation()
    }
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...get('form', [
      'isDirty'
    ])
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>