import axios from 'axios'
import { getPositioningCSS } from 'nprogress'

function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
}

const apiClient = axios.create({
    //baseURL: `http://192.168.2.189:8001/`,
    baseURL: process.env.VUE_APP_API_V1,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})

export default {
    async exportCountry(token) {
        return apiClient.post('export/',
            {file_name: 'export.xlsx'},
            {responseType: 'blob',
            ...authHeaders(token)}

        )
    },
    async exportAll(token) {
        return apiClient.post('export/all/',
            {file_name: 'export.xlsx'},
            {responseType: 'blob',
             ...authHeaders(token)})
    },
    async exportByCountry(country, token) {
        return apiClient.post(`export/c/${country}/`,
            {file_name: 'export.xlsx'},
            {responseType: 'blob',
             ...authHeaders(token)}
        )
    },
    async exportDocument(entrycode, uuid, filename, token) {
        return apiClient.post(`export/d/${entrycode}/${uuid}/`,
            {file_name: filename},
            {reponseType: 'blob',
             ...authHeaders(token)}
        )
    },
    async exportSelection(selection, token) {
        console.log('The selection is')
        console.log(selection)
        return apiClient.post(`export/s/`,
            {selection: selection},
            {responseType: 'blob',
            ...authHeaders(token)}
        )
    }
}