<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Upload images</v-card-title>
    <v-card-text>
        <v-card v-for="(image, index) in media"
            :key=index
        >
            <v-card-text>
                <v-img
                    :src="image.thumb"
                    max-height="150"
                    max-width="150"
                    @click=openGallery(index)
                />
                <h4 class="pa-2">Description:</h4>
                {{ image.caption }}
                <template v-if="image.tags.length > 0">
                  <h4 class="pa-2">Tags:</h4>
                  <v-chip v-for="(tag, i) in image.tags" :key="i">{{ tag }}</v-chip>
                </template>
            </v-card-text>
            <v-card-actions>
                <v-btn @click=getConfirm(image.id,index) >
                    Delete
                </v-btn>
            </v-card-actions>
        </v-card>
        
        <!-- <ul>
            <li
            v-for="(thumb, thumbindex) in initData.images"
            :key="thumbindex"
            @click="index=thumbindex"
            >
                <img :src="thumb">
            </li>
        </ul> -->
        <!-- <li
            v-for="(image, index) in media"
            :key="index"
            style="display: inline-block; margin: 0 5px 5px 0"
        >
            
            <img
                :src="image.thumb"
                style="height: 100px; cursor: pointer"
                @click=openGallery(index)
            >
        </li> -->
    </v-card-text>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <handy-uploader
            :documentAttachment.sync="handyAttachments"
            :fileUploaderType="'thumbnail'"
            :maxFileSize="10240"
            :imageCompressor="true"
            :imageCompressLevel="0.8"
            :maxFileCount="10"
            :badgeCounter="true"
            :changeFilename="true"
            :addFileDescription="true"
            :addFileTag="true"
            :tags="tags"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        :disabled="!hasAttachments"
        color="#7eb09f"
        @click="validate"
      >Save
         <v-progress-circular color="biscuit" v-if="loading" indeterminate/>      
      </v-btn>
    </v-card-actions>
    
    <LightBox
        ref="lightbox"
        :media="media" 
        :show-capture="true"
        :show-light-box="false"
        :interfaceHideTime=9999999999
    />

    <!-- START - CONFIRM DELETE DIALOG -->
    <v-dialog
      v-model="deletedialog"
      persistent
      width="350"
      :ripple="foo"
      style="z-index: 1301"
    >
      <v-card>
        <v-toolbar color="warning" class="justify-center mb-2 rounded-0">
                <v-toolbar-title>Confirm Image Delete</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <p>You are about to delete an image. If you are sure,
                    type the following string:</p>
                <p class="justify-center text-center" style="background-color: #e6e6e6">
                    <pre>delete</pre>
                </p>
                <p>in the text input below and press confirm.</p>
                <v-form
                    v-model="delvalid"
                    ref="confirmform"
                    lazy-validation
                > 
                    <v-text-field
                        v-model="deletecode"
                        :rules="confirmRule"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="error" @click.stop="delcancel">Cancel</v-btn>
                <v-btn color="warning" @click.stop="delconfirm">Confirm</v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END -->
  </v-card>
</template>

<script>
import { sync, get } from 'vuex-pathify'
import Images from '@/models/images.js'
import handyUploader from 'handy-uploader/src/components/handyUploader.vue'
import LightBox from 'vue-it-bigger'
import('vue-it-bigger/dist/vue-it-bigger.min.css')
import { imageTags } from '@/utils/tags.js'

export default {
  components: {
    LightBox,
    handyUploader,
  },
  props: {
    Images: {
      type: Object,
      default: function () {
        return {};
      },
    },
    passed: {
      type: Boolean,
      default: false,
    },
    entrycode: {
      type: Number
    }
  },
  data: function () {
    return {
      index: null,
      handyAttachments: [],
      tags: imageTags,
      valid: false,
      loading: false,
      required: [
        v => !!v || 'Field is required'
      ],
      // START DELETE DIALOG
      delvalid: false,
      deletedialog: false,
      todelete: "delete",
      deletecode: "",
      delid: "",
      delindex: "",
      foo: false,
      // END DELETE DIALOG
      keyImage: "1",
    };
  },
  methods: {
    async validate () {
      this.loading = true
      console.log('testing now mate')

      try {
          await this.$store.dispatch('incidentsnew/updFilesSimple', {
              entrycode: this.entrycode,
              payload: this.handyAttachments
          })
          this.loading = false
          window.location.reload()
      } catch (error) {
          console.log(error)
          this.loading = false
      }
    },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    openGallery (index) {
        this.$refs.lightbox.showImage(index)
    },
    async deleteImg (id, index) {
        console.log('This is the id: ' + id)
        try {
            await this.$store.dispatch('incidentsnew/delFilesSimple', {
                entrycode: this.entrycode,
                payload: id
            })
        } catch (error) {
            console.log(error)
        }
        this.initData.images.splice(index)
    },
    getConfirm (id, index) {
      this.delid = id
      this.delindex = index
      this.deletedialog = true
    },
    delcancel () {
        this.$refs.confirmform.resetValidation()
        this.deletecode = ""
        this.todelete = ""
        this.delid = ""
        this.delindex = ""
        this.deletedialog = false
    },
    async delconfirm () {
        if (this.$refs.confirmform.validate()) {
            this.deletecode = ""
            // delete the image
            try {
              await this.$store.dispatch('incidentsnew/delFilesSimple', {
                entrycode: this.entrycode,
                payload: this.delid
              })
            } catch (error) {
              console.log(error)
            }
            this.initData.images.splice(this.delindex)
        } else {
            this.foo = true
            console.log('the validation is not correct')
        }
        this.deletecode = ""
        this.delentrycode = ""
        this.delid = ""
        this.delindex = ""
        this.$refs.confirmform.resetValidation()
        this.deletedialog = false
    }
  },
  created () {
    this.newData = new Images()
  },
  mounted () {
    if (this.passed) {
      this.initData = {...this.Images}
      this.newData = this.Images
      this.$refs.form.resetValidation()
    }
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...get('form', [
      'isDirty'
    ]),
    hasAttachments () {
       if (this.handyAttachments.length == 0) {
           return false
       } else {
           return true
       }
    },
    media () {
        if (!this.initData.images) return []
        if (this.initData.images.length > 0 ) {
            let _media = []
            for (let i=0; i != this.initData.images.length; i ++) {
                let tmp = this.initData.images[i]
                _media.push({
                    thumb: process.env.VUE_APP_BASE_URL + '/static' + tmp.thumbpath,
                    src: process.env.VUE_APP_BASE_URL + '/static' + tmp.filepath,
                    caption: (tmp.description ? tmp.description : 'n/a'),
                    tags: (tmp.tags ? tmp.tags : null),
                    id: tmp.id
                })
            }
            return _media
        } else {
            return []
        }
    },
    confirmRule() {
        return [
            () => (this.todelete === this.deletecode) || 'Value does not match requested code',
            v => !!v || 'Fill value to confirm'
        ];
      },
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>