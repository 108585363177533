<template>
  <v-card>
    <v-card-title>EvidenceInfoPage</v-card-title>
    <v-card-text>
      <v-form>
        <v-select
          v-model="evidenceItem"
          label="Evidence Item"
          :items="EvidenceItem"
        />
        <v-text-field v-model="evidenceItemOther" label="Specify type of evidence if other" v-if="otherEvidence"/>
        <v-text-field v-model="lat" label="Lat" type="number" />
        <v-text-field v-model="lng" label="Lng" type="number" />
        <v-text-field v-model="sampleCode" label="Sample code" hint="if collected for analysis" persistent-hint />
        
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    evidenceItem: "",
    evidenceItemOther: "",
    lat: 0,
    lng: 0,
    sampleCode: "",
    EvidenceItem: [
      "Plastic gloves",
      "Pesticide bottle / pesticide container",
      "Bait container",
      "Plastic bag",
      "Cigarette butt",
      "Other",
    ],
  }),
  computed: {
    otherEvidence () {
      return true ? this.evidenceItem === 'Other' : false
    }
  }
};
</script>