export default {
    SET_LOADING(state, loading) {
        state.loading = loading
    },
    ADD_INDEXES(state, indexes) {
        state.indexes = indexes
    },
    INCIDENT_ERROR(state, error) {
        state.error = error
    },
    SET_INCIDENT_ID(state, incident) {
        state.incident = incident.data
        state.incidents.push(incident.data)
    },
    SET_INCIDENT_ENTRYCODE(state, incident) {
        state.incident = incident.data
        const indx = state.incidents.findIndex(v => v.entrycode === incident.data.entrycode)
        state.incidents.splice(indx, indx >= 0 ? 1 : 0)
        state.incidents.push(incident.data)
    },
    SET_LIVE(state, {entrycode, live}) {
        state.incident.live = live
    },
    SET_INFORMANT(state, payload) {
        state.incident.data.InformantDetails = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_NOTIFICATION(state, payload) {
        state.incident.data.NotificationInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_EVENTLOCATION(state, payload) {
        state.incident.data.PoisonLocationInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_DANIMAL(state, payload) {
        state.incident.data.DeadAnimalInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_ALANIMAL(state, payload) {
        state.incident.data.AliveAnimalInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_POISONBAIT(state, payload) {
        state.incident.data.PoisonBaitInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_EVIDENCE(state, payload) {
        state.incident.data.EvidenceInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_MOTIVE(state, payload) {
        state.incident.data.Motive = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_AUTHROTIES(state, payload) {
        state.incident.data.AuthoritiesManageOfEvent = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_ANTIPOISONDOG(state, payload) {
        state.incident.data.AntipoisonDogUnit = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_SAMPLES(state, payload) {
        state.incident.data.SampleCollectedInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_DISPOSAL(state, payload) {
        state.incident.data.DisposalOfDeadAnimals = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_OTHERREASONS(state, payload) {
        state.incident.data.OtherPossibleDeathReasons = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_COMPLAINT(state, payload) {
        state.incident.data.OfficialComplaintInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_AUTOPSY(state, payload) {
        state.incident.data.AutopsyToxicolAnalInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_TOXINS(state, payload) {
        state.incident.data.ToxinsUsed = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_GCOMMENT(state, payload) {
        state.incident.data.GeneralComments = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_PUBLIC(state, payload) {
        state.incident.data.ExtraPublicInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    DEL_INCIDENT(state, entrycode) {
        // state.indexes = state.indexes.filter(item => item.entrycode != entrycode)
        // state.incident = {}
        // state.incidents = state.incidents.filter(item => item.entrycode != entrycode)
        let i = state.indexes.map(item => item.entrycode).indexOf(entrycode)
        state.indexes.splice(i, 1)
        
        // clear incident
        state.incident = {}

        // remove incident from incidents
        let ii = state.incidents.map(item => item.entrycode).indexOf(entrycode)
        state.incidents.splice(ii, 1)
    },
    SET_LASTMODIFIED(state, payload) {
        state.lastmodified = payload
    },
    SET_LASTMODIFIEDBYUSER(state, payload) {
        state.lastmodifiedbyuser = payload
    }
}
