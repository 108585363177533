import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import store from '../store'
import NProgress from 'nprogress'
import newinc from '../router/newinc.js'

Vue.use(VueRouter)

const AUTHENABLED = true

const routes = [
  {
    path: '*',
    meta: {
      name: '',
      requiresAuth: AUTHENABLED
    },
    redirect: {
      path: '/dashboard'
    }
  },
  // This allows for pages apart from app but not rendered inside the dashboard
  {
    path: '/',
    meta: {
      name: '',
      requiresAuth: !AUTHENABLED
    },
    component: () =>
      import(/* webpackChunkName: "login" */ `@/views/LoginView.vue`),
    // redirect if already signed in
    beforeEnter: (to, from, next) => {
      // if (store.getters.authorized) {
      // if (store.state.auth.status.loggedIn) {
      if(store.state.newauth.isLoggedIn) {
        next('/dashboard')
      } else {
        next()
      }
    },
    children: [
      {
        path: '',
        component: () => import(`@/components/LoginForm.vue`)
      },
      {
        path: 'reset-password',
        name: 'restpassword',
        component: () => import(`@/components/ResetPassword.vue`)
      },
      {
        path: 'reset',
        name: 'forgotpassword',
        component: () => import(`@/components/RequestReset.vue`)
      },
    ]
  },
  // add any extra routes need in the dashboard as a child below.
  {
    path: '/dashboard',
    meta: {
      name: 'Dashboard View',
      requiresAuth: true
    },
    component: () => import(`@/views/DashboardView.vue`),
    children: [
      {
        path: '',
        name: 'Dashboard',
        // async beforeEnter(to, from, next) {
        //   try {
        //     await store.dispatch('incidents/fetchIndexes')
        //     next()
        //   } catch (error) {
        //     next({ name: 'network-issue' })
        //   }
        // },
        beforeEnter(to, from, next) {
          try {
            store.dispatch('incidentsnew/fetchIndexes')
            store.dispatch('incidentsnew/chkLastUpdate')  // only loads if update has happened
            next()
          } catch (error) {
            next({ name: 'network-issue' })
          }
        },
        // component: () => import(`@/views/KolpoFilter.vue`)
        component: () => import(`@/views/CountryFilterNew.vue`)
      },
      {
        path: 'changelog',
        name: 'Changelog',
        component: () => import(/* webpackChunkName: "changelog" */ '@/components/Changelog.vue')
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import(/* webpackChunkName: "reports" */ '@/views/Reports.vue')
      },
      {
        path: 'reports/:id',
        name: 'View Report',
        component: () => import(/* webpackChunkName: "viewreports" */ '@/views/ViewReport.vue')
      },
      {
        path: 'exports',
        name: 'Exports',
        component: () => import(/* webpackChunkName: "exports" */ '@/views/Exports.vue')
      },
      {
        path: 'exports/new',
        name: 'New Export Configuration',
        component: () => import(/* webpackChunkName: "viewexports" */ '@/views/ViewExport.vue')
      },
      {
        path: 'exports/:id',
        name: 'View Export',
        async beforeEnter(to, from, next) {
          try {
            await store.dispatch('exportserv/fetchExport', to.params.id)
            store.dispatch('exportserv/exportPreview', to.params.id)
            next()
          } catch (error) {
            next({ name: 'network-issue' })
          }
        },
        component: () => import(/* webpackChunkName: "viewexports" */ '@/views/ViewExport.vue')
      },
      {
        path: 'findinc',
        name: 'FindIncident',
        // component: () => import(/* webpackChunkName: "findincident" */ '@/views/FindIncident.vue')
        // component: () => import(/* webpackChunkName: "findincident" */ '@/views/NewMap.vue')
        component: () => import(/* webpackChunkName: "findincident" */ '@/views/ProperMap.vue')
        // component: () => import(/* webpackChunkName: "findincident" */ '@/views/PureDataTable.vue')
      },
      {
        path: '/addincident',
        name: 'Add Incident',
        component: () => import(/* webpackChunkName: "addincident" */ '@/components/AddIncident.vue')
      },
      {
        path: 'admin',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminView.vue')
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/admin/Users.vue'),
      },
      {
        path: 'users/create',
        name: 'Create Users',
        component: () => import(/* webpackChunkName: "createusers" */ '@/views/admin/Create.vue'),
      },
      {
        path: 'users/edit/:id',
        name: 'Edit User',
        component: () => import(/* webpackChunkName: "edituser" */ '@/views/admin/Edit.vue')
      },
      {
        path: 'profile',
        name: 'User Profile',
        component: () => import(/* webpackChunkName: "userprofile" */ '@/views/UserProfile.vue')
      },
      {
        path: 'view/:entrycode',
        name: 'ViewIncident',
        async beforeEnter(to, from, next) {
          try {
            // await store.dispatch('incidents/fetchIncidentByEntryCode', to.params.entrycode)
            // await store.dispatch('incidentsnew/fetchIncidentByEntryCode', to.params.entrycode)
            await store.dispatch('incidentsnew/fetchIncidentByEntryCode', to.params.entrycode)
            next()
          } catch (error) {
            next({ name: 'network-issue' })
          }
        },
        component: () => import(/* webpackChunkName: "simpleview" */ '@/views/SimpleViewIncident.vue')
      },
      {
        path: 'pages',
        name: 'ListPages',
        component: () => import(/* webpackChunkName: "listpages" */ '@/views/ListStaticPages.vue')
      },
      {
        path: 'pages/:id',
        name: 'Edit Page',
        component: () => import(/* webpackChunkName: "editpage" */ '@/views/EditStaticPage.vue')
      },
      {
        path: 'stats',
        name: 'Statistics',
        component: () => import(/* webpackChunkName: "stats" */ '@/views/Statistics.vue')
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import(/* webpackChunkName: "stats" */ '@/views/SearchView.vue')
      }
    ]
  },
  {
    path: '/editincident/:entrycode',
    meta: {
      name: 'Edit Incident',
      requiresAuth: true
    },
    async beforeEnter (to, from, next) {
      try {
        // await store.dispatch('incidents/fetchIncidentByEntryCode', to.params.entrycode)
        await store.dispatch('incidentsnew/fetchIncidentByEntryCode', to.params.entrycode)
        next()
      } catch (error) {
        next({ name: 'network-issue' })
      }
    },
    component: () => import(/* webpackChunkName: "editincident" */ `@/views/EditIncident.vue`),
    children: [
      {
        name: 'Edit Start',
        path: '',
        component: () => import(/* webpackChunkName: "informant" */ '@/components/incidents/edit/Informant'),
      },
      {
        name: 'Informant',
        path: 'informant',
        components: {
          default: () => import(/* webpackChunkName: "informant" */ '@/components/incidents/edit/Informant'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue')
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'Informant')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'NotificationInfo',
        path: 'notification',
        components: {
          default: () => import(/* webpackChunkName: "notification" */ '@/components/incidents/edit/NotificationInfo'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue'),
          debugpanel: () => {
            if (router.app.$debugApp)
              return import('@/components/DebugPanel.vue') 
            else return import('@/components/NoOP.vue')
          }
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'NotificationInfo')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'PoisonEvent',
        path: 'poisonevent',
        components: {
          default: () => import(/* webpackChunkName: "event" */ '@/components/incidents/edit/PoisonEvent'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue'),
          mapwidget: () => import(/* webpackChunkName: "mapformcontrol" */ '@/components/MapFormControl.vue'),
          debugpanel: () => {
            if (router.app.$debugApp)
              return import('@/components/DebugPanel.vue') 
            else return import('@/components/NoOP.vue')
          }
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'PoisonEvent')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'DeadAnimalFound',
        path: 'deadanimalfound',
        components: {
          default: () => import(/* webpackChunkName: "deadanimal" */ '@/components/incidents/edit/DeadAnimalFound'),  //FIXME: missing
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue'),
          mapwidget: () => import(/* webpackChunkName: "mapformcontrol" */ '@/components/MapFormControl.vue'),
          debugpanel: () => {
            if (router.app.$debugApp)
              return import('@/components/DebugPanel.vue') 
            else return import('@/components/NoOP.vue')
          }
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'DeadAnimalFound')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'AliveAnimalFound',
        path: 'aliveanimalfound',
        components: {
          default: () => import(/* webpackChunkName: "aliveanimal" */ '@/components/incidents/edit/AliveAnimalFound'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue'),
          mapwidget: () => import(/* webpackChunkName: "mapformcontrol" */ '@/components/MapFormControl.vue'),
          debugpanel: () => {
            if (router.app.$debugApp)
              return import('@/components/DebugPanel.vue') 
            else return import('@/components/NoOP.vue')
          }
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'AliveAnimalFound')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'PoisonBaitInfo',
        path: 'poisonbaitinfo',
        components: {
          default: () => import(/* webpackChunkName: "poison" */ '@/components/incidents/edit/PoisonBaitInfo'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue'),
          mapwidget: () => import(/* webpackChunkName: "mapformcontrol" */ '@/components/MapFormControl.vue'),
          debugpanel: () => {
            if (router.app.$debugApp)
              return import('@/components/DebugPanel.vue') 
            else return import('@/components/NoOP.vue')
          }
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'PoisonBaitInfo')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'EvidenceInfo',
        path: 'evidenceinfo',
        components: {
          default: () => import(/* webpackChunkName: "evidence" */ '@/components/incidents/edit/EvidenceInfo'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue'),
          mapwidget: () => import(/* webpackChunkName: "mapformcontrol" */ '@/components/MapFormControl.vue'),
          debugpanel: () => {
            if (router.app.$debugApp)
              return import('@/components/DebugPanel.vue') 
            else return import('@/components/NoOP.vue')
          }
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'EvidenceInfo')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'Motive',
        path: 'motive',
        components: {
          default: () => import(/* webpackChunkName: "motive" */ '@/components/incidents/edit/Motive'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue')
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'Motive')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'AuthoritiesInv',
        path: 'authoritiesinv',
        components: {
          default: () => import(/* webpackChunkName: "authinv" */ '@/components/incidents/edit/AuthInv'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue')
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'AuthoritiesInv')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'AntiDogUnit',
        path: 'antidogunit',
        components: {
          default: () => import(/* webpackChunkName: "antidog" */ '@/components/incidents/edit/AntiDogUnit'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue')
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'AntiDogUnit')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'SamplesCol',
        path: 'samplescol',
        components: {
          default: () => import(/* webpackChunkName: "samples" */ '@/components/incidents/edit/SamplesCol'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue'),
          debugpanel: () => {
            if (router.app.$debugApp)
              return import('@/components/DebugPanel.vue') 
            else return import('@/components/NoOP.vue')
          }
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'SamplesCol')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'DisposalDead',
        path: 'disposaldead',
        components: {
          default: () => import(/* webpackChunkName: "disposal" */ '@/components/incidents/edit/DisposalDead'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue')
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'DisposalDead')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'OtherReasons',
        path: 'otherreasons',
        components: {
          default: () => import(/* webpackChunkName: "other" */ '@/components/incidents/edit/OtherReasons'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue')
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'OtherReasons')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'OfficialComp',
        path: 'officialcomp',
        components: {
          default: () => import(/* webpackChunkName: "official" */ '@/components/incidents/edit/OfficialComp'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue'),
          debugpanel: () => {
            if (router.app.$debugApp)
              return import('@/components/DebugPanel.vue') 
            else return import('@/components/NoOP.vue')
          }
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'OfficialComp')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'Autopsy',
        path: 'autopsy',
        components: {
          default: () => import(/* webpackChunkName: "autopsy" */ '@/components/incidents/edit/Autopsy'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue'),
          debugpanel: () => {
            if (router.app.$debugApp)
              return import('@/components/DebugPanel.vue') 
            else return import('@/components/NoOP.vue')
          }
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'Autopsy')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'Toxins Used',
        path: 'toxinsused',
        components: {
          default: () => import(/* webpackChunkName: "toxinsused" */ '@/components/incidents/edit/ToxinsUsed'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue'),
          debugpanel: () => {
            if (router.app.$debugApp)
              return import('@/components/DebugPanel.vue') 
            else return import('@/components/NoOP.vue')
          }
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'Toxins Used')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'GenComments',
        path: 'gencomments',
        components: {
          default: () => import(/* webpackChunkName: "gencom" */ '@/components/incidents/edit/GenComments'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue')
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'GenComments')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'ExtraInfo',
        path: 'extrainfo',
        components: {
          default: () => import(/* webpackChunkName: "extra" */ '@/components/incidents/edit/ExtraInfo'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue')
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'ExtraInfo')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'Images',
        path: 'images',
        components: {
          default: () => import(/* webpackChunkName: "images" */ '@/components/incidents/edit/Images'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue')
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'Images')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      },
      {
        name: 'Documents',
        path: 'documents',
        components: {
          default: () => import(/* webpackChunkName: "images" */ '@/components/incidents/edit/Documents'),
          control: () => import(/* webpackChunkName: "control" */ '@/components/IncidentInfo.vue'),
          version: () => import(/* webpackChunkName: "version" */ '@/components/Version.vue')
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.newauth.userProfile.is_superuser) {
            const informantroute = to.matched.find(route => route.name === 'Documents')
            if (informantroute) {
              informantroute.components.version = null
            }
          }
          next()
        }
      }
    ]
  },
  {
    path: '/network-issue',
    meta: {
      name: 'network-issue',
      requiresAuth: true
    },
    component: () => import('@/components/NetworkIssue.vue')
  },
  // newinc    // in case we create additional routes for the new incs
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Route guard checks to see if you are logged in, if not reroutes to login
// to is where you are going, matched.some is to find which routes have requiresAuth

// OLD AUTH SERVICE
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     console.log("what???")
//     if (store.state.auth.status.loggedIn) {
//       console.log('logggeind')

//       // check token and go for refresh if close to expiration
//       if (Date.now() / 1000 >= store.state.auth.timestamp) {
//         console.log("check the token!!!")
//         store.dispatch('auth/refresh', store.state.auth.user)
//       }
//       next()
//       return
//     }
//     next('/')
//   } else {
//     next()
//   }
// })

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.newauth.isLoggedIn) {
      
      // check token and go for refresh if close to expiration
      if (Date.now() / 1000 >= store.state.newauth.timestamp) {
        console.log("Token Refresh triggered")
        store.dispatch('newauth/refreshToken')
      }
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})


router.beforeResolve((routeTo, routeFrom, next) => {
  if (routeTo.path) {
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
