/**
 * try to prefix the methods of the service with the method
 * that will execute the call, as in get/post/delete/update
 */

import axios from 'axios'

function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}

const apiClient = axios.create({
    // baseURL: `http://localhost:8001/`,
    baseURL: process.env.VUE_APP_API_V1,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})


export default {
    async getStaticPages () {
        return apiClient.get('pages/')
    },
    async getStaticPageById(id) {
        return apiClient.get(`pages/${id}`)
    },
    async postStaticPage(staticpage, token) {
        return apiClient.post(`pages/`, staticpage, authHeaders(token))
    },
    async putStaticPage(id, staticpage, token) {
        return apiClient.put(`pages/${id}`, staticpage, authHeaders(token))
    },
    async deleteStaticPage(id, token) {
        return apiClient.delete(`pages/${id}`, authHeaders(token))
    }
}
