<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Poison event's location</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-select v-model="newData.country" label="Country" :items="Country" required 
          :rules="required" />
        <v-combobox 
          v-model="newData.region" 
          label="Region"
          required 
          :rules="required"
          :items="regions"
          clearable
          />
        <v-combobox 
          v-model="newData.county"
          label="County"
          :items="counties"
          clearable
        />

        <v-combobox
          v-model="newData.municipality" 
          label="Municipality"
          required
          :rules="required"
          :items="municipalities"
          clearable
        />
        <v-text-field
          v-model="newData.municipalityDistrict"
          label="Municipality or District"
        />
        <v-text-field v-model="newData.locality" label="Village and/or locality" />

        <!-- LatLng Control -->
        <p>Select marker from <b>Map Control</b> to copy values from. Use 
        <b>Confirm Marker</b> or fill in coordinates</p>
        <v-select
          v-model="pivot"
          label="Select marker"
          item-text="name"
          return-object
          :items="foomarkers"
          @change="updateMarker"
        >
          <template v-slot:item="{ item }">
            <p>{{ item.name }}</p>
          </template>
          <template slot="append-item">
            <v-btn block @click.prevent="addMarker()">Add marker</v-btn>
          </template>
        </v-select>

        <v-row :key="refresher">
          <v-col>
            <v-row>
              <v-col>
                <v-text-field
                  v-model.number="newData.lat"
                  label="Lat"
                  type="number"
                  required
                  outlined
                  :rules="required"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="newData.lng"
                  label="Long"
                  type="number"
                  required
                  outlined
                  :rules="required"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>


        <!-- <v-text-field v-model="newData.lat" label="Lat" type="number" required outlined 
          :rules="required" />
        <v-text-field v-model="newData.lng" label="Long" type="number" required outlined 
          :rules="required" /> -->

        <v-select
          v-model="newData.urbanIncident"
          label="Urban Incident"
          :items="UrbanIncidentChoices"
          required
          :rules="required"
        />
        <v-select
          v-model="newData.privOrPubLand"
          label="Private or public land"
          :items="PrivOrPublicLand"
          required
          :rules="required"
        />
        <v-select
          v-model="newData.privOrPubHuntingReserve"
          label="Hunting Reserve"
          :items="HuntingReserve"
          required
          :rules="required"
        />
        <v-select
          v-model="newData.areaProtectionStatus1"
          label="Area's protection status 1"
          :items="ProtectionStatus"
          required
          :rules="required"
        />
        <v-select
          v-model="newData.areaProtectionStatus2"
          label="Area's protection status 2"
          :items="ProtectionStatus"
        />
        <v-select
          v-model="newData.areaProtectionStatus3"
          label="Area's protection status 3"
          :items="ProtectionStatus"
        />
        <v-select
          v-model="newData.areaProtectionStatus4"
          label="Area's protection status 4"
          :items="ProtectionStatus"
        />
        <v-select
          v-model="newData.primaryHabitat"
          label="Primary habitat (Corine code)"
          :items="PrimaryHabitat"
          required
          :rules="required"
        />
        <v-select
          v-model="newData.weatherPreviousDays"
          label="Weather during previous days"
          :items="WeatherPrevDay"
          required
          :rules="required"
        />
        <v-textarea v-model="newData.extraInfo" label="Other Information" filled />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        color="grey lighten-2"
        @click="reset"
      >Clear</v-btn>
      <v-btn
        :disabled="!valid || !isDirty"
        color="#7eb09f"
        @click="validate"
      >Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import LocationInfo from '@/models/poisonlocationinfo.js'
import { Country, UrbanIncidentChoices, PrivOrPublicLand, HuntingReserve, ProtectionStatus, PrimaryHabitat, WeatherPrevDay} from '@/utils/selectoptions.js'
import { getMarkers } from '@/utils/general.js';

const regionInfo = require('@/assets/countries_regions.json')

export default {
  components: {
  },
  props: {
    PoisonLocationInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    passed: {
      type: Boolean,
      default: false,
    },
    entrycode: {
      type: Number
    }
  },
  data: function () {
    return {
      foomarkers: [],
      pivot: {},
      refresher: 1,
      valid: true,
      required: [
        v => !!v || 'Field is required'
      ],
      // handyAttachments: [],
      mapmodel: false,
      Country: Country,
      UrbanIncidentChoices: UrbanIncidentChoices,
      PrivOrPublicLand: PrivOrPublicLand,
      HuntingReserve: HuntingReserve,
      ProtectionStatus: ProtectionStatus,
      PrimaryHabitat: PrimaryHabitat,
      WeatherPrevDay: WeatherPrevDay
    };
  },
  methods: {
    ...call('mapform', [
      'mapaddMarker'
    ]),
    mapDialog() {
      this.mapmodel = true;
    },
    gotvalues(values) {
      console.log("Message received");
      console.log(values);
      this.lat = values.lat;
      this.lng = values.lng;
      this.mapmodel = false;
    },
    validate () {
      this.loading = true
      if(this.$refs.form.validate()) {
        this.$store.dispatch('incidentsnew/updEventLocation', {entrycode: this.entrycode, payload: this.newData})
          .then(() => {
            this.loading = false
            this.initData = {...this.newData}
          })
          .catch(e => {
            console.log('We got the error')
            console.log(this.$store.state.incidentsnew.error)
          })  // FIXME
      } else {
        this.loading = false
      }
    },
    // OLD validate | use for reference for the posting of the files
    // async validate () {
    //   this.$refs.form.validate()
      
    //   const category = 'PoisonLocationInfo'

    //   // const payload = {category: category,
    //   //                  uploads: this.handyAttachments}

    //   try {
    //     await this.$store.dispatch('incidents/updFiles', {entrycode: this.entrycode, category: category, payload: this.handyAttachments})
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    addMarker () {
      this.mapaddMarker()
      this.foomarkers.push(this.mymarker)
      this.pivot = {'name': this.mymarker.name}
    },
    updateMarker (e) {
      console.log(e)
    },
  },
  created () {
    this.newData = new LocationInfo()
    this.foomarkers = getMarkers(this.$store.state.incidentsnew.incident)
  },
  mounted () {
    this.$root.$on('marker-control-complete', (msg) => {
      if (msg.name === this.pivot.name) {
        this.$set(this.newData, 'lat', msg.position.lat)
        this.$set(this.newData, 'lng', msg.position.lng)
        this.refresher += 1
      }
    })
    
    if (this.passed) {
      this.initData = {...this.PoisonLocationInfo}
      this.newData = this.PoisonLocationInfo
      this.$refs.form.resetValidation()
    }
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...get('form', [
      'isDirty'
    ]),
    ...sync('mapform', {
      mymarker: "marker"
    }),
    regions () {
      let regions = []
      if (this.newData.country) {
        regions = Object.keys(regionInfo[this.newData.country])
      } else {
        regions = ['n/a']
      }
      return regions
    },
    counties () {
      let counties = []
      if (this.newData.country && this.newData.region) {
        let _counties = regionInfo[this.newData.country][this.newData.region]
        if (!_counties) {
          counties = ['n/a']
        } else {
          counties = Object.keys(regionInfo[this.newData.country][this.newData.region])
        }
      } else {
        counties = ['n/a']
      }
      return counties
    },
    municipalities () {
      let municipalities = []
      if (this.newData.country && this.newData.region && this.newData.county) {
        try {
          let _municipalities = regionInfo[this.newData.country][this.newData.region][this.newData.county]
          if (!_municipalities) {
            municipalities = ['n/a']
          } else {
            municipalities = regionInfo[this.newData.country][this.newData.region][this.newData.county]
          }
        } catch (error) {
          municipalities = ['n/a']
        }
      } else {
        municipalities = ['n/a']
      }
      return municipalities
    }
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>