/**
 * try to prefix the methods of the service with the method
 * that will execute the call, as in get/post/delete/update
 */

import axios from 'axios'

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_V1,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})


export default {
    async getIncidentsPerYear (country) {
        return apiClient.get(`stats/incperyear?country=${country}`)
    },
    async getIncidentsPerMonth (country) {
        return apiClient.get(`stats/incpermonth?country=${country}`)
    },
    async getIncComplaints (country) {
        return apiClient.get(`stats/complaints?country=${country}`)
    },
    async getIncCourt (country) {
        return apiClient.get(`stats/court?country=${country}`)
    },
    async getIncAutopsy (country) {
        return apiClient.get(`stats/autopsy?country=${country}`)
    },
    async getAnimals (country) {
        return apiClient.get(`stats/animals?country=${country}`)
    },
    async getToxins (country) {
        return apiClient.get(`stats/toxins?country=${country}`)
    }
}
