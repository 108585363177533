export default {
    SET_LOADING(state, loading) {
        state.loading = loading
    },
    REPORT_ERROR(state, error) {
        state.error = error
    },
    ADD_REPORTS(state, reports) {
        state.reports = reports
    },
    ADD_REPORT(state, report) {
        state.report = report
    },
    PUSH_REPORT(state, report){
        state.reports.push(report)
    },
    UPDATE_REPORT(state, {id, report}) {
        state.reports[id] = report
    },
    DELETE_REPORT(state, id) {
        for (var i = 0; i < state.reports.length; i++ ) {
            if (state.reports[i] == id) {
                state.reports.splice(i, 1)
                return
            }
        }
    },
    SET_NEW_INCIDENT(state, id) {
        state.newincident = id
    }
}