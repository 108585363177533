export default class AliveAnimalInfoPage {
    constructor(number=0, species, notListedSpecies, marking, markingType, markingCodes, lat, lng, animalState, firstAidType, firstAidProvider, nameRehabilitationCenter, finalStatus) {
        this.number = number;
        this.species = species;
        this.notListedSpecies = notListedSpecies;
        this.marking = marking;
        this.markingType = markingType;
        this.markingCodes = markingCodes;
        this.lat = lat;
        this.lng = lng;
        this.animalState = animalState;
        this.firstAidType = firstAidType;
        this.firstAidProvider = firstAidProvider;
        this.nameRehabilitationCenter = nameRehabilitationCenter;
        this.finalStatus = finalStatus;
    }
}