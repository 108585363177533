export default {
    getIndexes: state => state.indexes,
    getIncident: state => state.incident,
    getIncidentByEntryCode: (state) => _entrycode => {
        return state.incidents.find(({entrycode}) => entrycode === _entrycode)
    },
    getIncidentById: (state) => _id => {
        return state.incidents.find(({id}) => id === _id)
    },
    getIndexesByCountry: (state) => (_country) => {
        return state.indexes.filter((el) => {
            return el.country === _country
        })
    },
    getLastModified: (state) => state.lastmodified,
    getLastModifiedByUser: (state) => state.lastmodifiedbyuser,
    indexLoading: state => state.loading
}