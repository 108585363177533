import axios from 'axios'
import authHeader from './AuthHeader'


function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}

const apiClient = axios.create({
    //baseURL: `http://192.168.2.189:8001/`,
    baseURL: process.env.VUE_APP_API_V1,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})


const apiClientBase = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})

export default {
    async getAllUsers(token) {
        return apiClient.get('allusers/', authHeaders(token))
    },
    async createUser(payload, token) {
        return apiClientBase.post('auth/register', payload, authHeaders(token))
    },
    async editUser(userid, payload, token) {
        return apiClientBase.patch(`users/${userid}`, payload, authHeaders(token))
    },
    async delUser(userid, token) {
        return apiClientBase.delete(`users/${userid}`, authHeaders(token))
    },
    async inviteUser(userid, token) {
        return apiClientBase.post(`invite/?userid=${userid}`, {}, authHeaders(token))
    }
}