// Utilities
import { dispatch, make } from 'vuex-pathify'
import AdminService from '../../services/AdminService'


const state = {
  users: [],
  nousers: 0,
  error: {},
  loading: false,
}

const mutations = make.mutations(state)

const actions = {
    async fetchUsers ({commit, rootState}) {
        try {
            // console.log('fetching users!')
            commit('loading', true)
            let resp = await AdminService.getAllUsers(rootState.newauth.token)
            commit('users', resp.data)
            commit('loading', false)
            commit('nousers', resp.data.length)
        } catch (error) {
            commit('loading', false)
            console.log('Error retrieving the users from server')
            commit('error', error)  // FIXME: check if throw e is needed
        }
    },
    async createUser ({commit, dispatch, rootState}, payload) {
        try {
            commit('loading', true)
            let resp = await AdminService.createUser(payload, rootState.newauth.token)
            await dispatch('fetchUsers')
            commit('loading', false)
        } catch (error) {
            commit('loading', false)
            console.log('Error creating the user')
            commit('error', error)  // FIXME: check if throw e is needed
        }
    },
    async editUser ({commit, dispatch, rootState}, {userid, payload}) {
        try {
            commit('loading', true)
            let resp = await AdminService.editUser(userid, payload, rootState.newauth.token)
            await dispatch('fetchUsers')
            commit('loading', false)
        } catch (error) {
            commit('loading', false)
            console.log('Error editing the user')
            commit('error', error) // FIXME: check if throw e is needed
        }
    },
    async deleteUser ({commit, dispatch, rootState}, {userid}) {
        try {
            commit('loading', true)
            let resp = await AdminService.delUser(userid, rootState.newauth.token)
            await dispatch('fetchUsers')
            commit('loading', false)
        } catch (error) {
            commit('loading', false)
            console.log('Error deleting the user')
            commit('error', error)  // FIXME: cehck if throw e is needed
        }
    },
    async inviteUser ({commit, dispatch, rootState}, {userid}) {
        try {
            commit('loading', true)
            let payload = {'userid': userid}
            let resp = await AdminService.inviteUser(userid, rootState.newauth.token)
            commit('loading', false)
        } catch (error) {
            commit('loading', false)
            console.log('Error inviting the user')
            commit('error', error)
        }
    }
}

const getters = {
    getUser: (state) => (userId) => {
        const _user = state.users.filter((user) => user.id === userId)
        if (_user.length === 1) {
            // console.log(JSON.stringify(_user[0], null, 2))
            return _user[0]
        } else {
            return {}
        }
    }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
