export default class PoisonLocationInfo {
    constructor(country, region, county, municipality, municipalityDistrict,
                locality, lat, lng, urbanIncident, privOrPubLand,
                 privOrPubHuntingReserve, areaProtectionStatus1, areaProtectionStatus2,
                 areaProtectionStatus3, areaProtectionStatus4, primaryHabitat,
                 weatherPreviousDays, extraInfo) {
        this.country = country;
        this.region = region;
        this.county = county;
        this.municipality = municipality;
        this.municipalityDistrict = municipalityDistrict;
        this.locality = locality;
        this.lat = lat;
        this.lng = lng;
        this.urbanIncident = urbanIncident;
        this.privOrPubLand = privOrPubLand;
        this.privOrPubHuntingReserve = privOrPubHuntingReserve;
        this.areaProtectionStatus1 = areaProtectionStatus1;
        this.areaProtectionStatus2 = areaProtectionStatus2;
        this.areaProtectionStatus3 = areaProtectionStatus3;
        this.areaProtectionStatus4 = areaProtectionStatus4;
        this.primaryHabitat = primaryHabitat;
        this.weatherPreviousDays = weatherPreviousDays;
        this.extraInfo = extraInfo;
    }
}