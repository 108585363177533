export default class SampleCollectedInfoPage {
    constructor(number=0, sampleEvidenceCode, storedIn, collectedBy, collectedOther, storedBy, storedOther, storingPlace, sentTo, sentToOther, dateSent, results) {
        this.number = number;
        this.sampleEvidenceCode = sampleEvidenceCode;
        this.storedIn = storedIn;
        this.collectedBy = collectedBy;
        this.collectedOther = collectedOther;
        this.storedBy = storedBy;
        this.storedOther = storedOther;
        this.storingPlace = storingPlace;
        this.sentTo = sentTo;
        this.sentToOther = sentToOther;
        this.dateSent = dateSent;
        this.results = results;
    }
}