
const user = JSON.parse(localStorage.getItem('user'));

// Proper default initialization no DEV
const _initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

// During development DEBUG FIXME
// const _initialState = user
//     ? { status: { loggedIn: true }, user }
//     : { status: { loggedIn: true }, user: {} };

export default {
    ..._initialState,
    timestamp: ""
}