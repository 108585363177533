/**
 * try to prefix the methods of the service with the method
 * that will execute the call, as in get/post/delete/update
 */

import axios from 'axios'
import authHeader from './AuthHeader'
import { getLocalToken } from '@/utils/general'


function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}

const apiClientNoAuth = axios.create({
    baseURL: `http://localhost:8001/`,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

const localtoken = getLocalToken()

const apiClient = axios.create({
    // baseURL: `http://192.168.2.189:8001/`,
    baseURL: process.env.VUE_APP_API_V1,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // ...authHeader()
        //'Authorization': 'bearer ' + localtoken
    }
})

export default {
    async chkLastUpdate (payload, token) {
        return apiClient.post('index/', payload, authHeaders(token))
    },
    async getIncidents (token) {
        return apiClient.get('inc/all/', authHeaders(token))
    },
    async getIncidentsIndex (token) {
        return apiClient.get('index/all/', authHeaders(token))
    },
    async getIncidentById(id, token) {
        return apiClient.get(`incidents/${id}`, authHeaders(token))
    },
    async getIncidentByEntryCode(entrycode, token) {
        return apiClient.get(`inc/ent/${entrycode}`, authHeaders(token))
    },
    async postIncident(token) {
        return apiClient.post('inc/', {},  authHeaders(token))
    },
    async deleteIncident(entrycode, token) {
        return apiClient.delete(`inc/${entrycode}`, authHeaders(token))
    },
    async postLive(payload, token) {
        return apiClient.post('inc/live/', payload, authHeaders(token))
    },
    async postInformant(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/informant/`, payload, authHeaders(token))
    },
    async postNotification(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/notification/`, payload, authHeaders(token))
    },
    async postEventLocation(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/poisonloc/`, payload, authHeaders(token))
    },
    async postDanimal(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/danimal/`, payload, authHeaders(token))
    },
    async postAlanimal(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/alanimal/`, payload, authHeaders(token))
    },
    async postPoisonbait(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/poisonbait/`, payload, authHeaders(token))
    },
    async postEvidence(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/evidence/`, payload, authHeaders(token))
    },
    async postMotive(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/motive/`, payload, authHeaders(token))
    },
    async postAuthorities(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/authorities/`, payload, authHeaders(token))
    },
    async postAntipoisondog(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/antipoisondog/`, payload, authHeaders(token))
    },
    async postSamples(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/samples/`, payload, authHeaders(token))
    },
    async postDisposal(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/disposal/`, payload, authHeaders(token))
    },
    async postOtherreasons(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/otherreasons/`, payload, authHeaders(token))
    },
    async postComplaint(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/complaint/`, payload, authHeaders(token))
    },
    async postAutopsy(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/autopsy/`, payload, authHeaders(token))
    },
    async postToxins(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/toxins/`, payload, authHeaders(token))
    },
    async postGcomment(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/gcomment/`, payload, authHeaders(token))
    },
    async postPublic(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/public/`, payload, authHeaders(token))
    },
    async postMarkers(entrycode, payload, token) {
        return apiClient.post(`inc/${entrycode}/markers/`, payload, authHeaders(token))
    },
    // POST FILES
    async postFiles(entrycode, category, payload, token) {
        // FIXME: following was original
        // return apiClient.post(`inc/${entrycode}/file/${category}`, payload, authHeaders(token))
        return apiClient.post(`upl/${entrycode}/${category}/hocuspocus/`, payload, authHeaders(token))
    },
    async postFilesSimple(entrycode, payload, token) {
        return apiClient.post(`upl/${entrycode}/`, payload, authHeaders(token))
    },
    async postFilesDoc(entrycode, payload, token) {
        return apiClient.post(`upl/doc/${entrycode}/`, payload, authHeaders(token))
    },
    async delImage(entrycode, id, token) {
        return apiClient.delete(`upl/${entrycode}/${id}`, authHeaders(token))
    },
    async delDocument(entrycode, id, token) {
        return apiClient.delete(`upl/doc/${entrycode}/${id}`, authHeaders(token))
    },
    async getLastModifiedByUser (token) {
        return apiClient.get('index/latest', authHeaders(token))
    },
    async getLastModified (token) {
        return apiClient.get('index/a/latest', authHeaders(token))
    },
    async getLastModifiedPaginated (skip, limit, token) {
        return apiClient.get(`index/p/latest?offset=${skip}&limit=${limit}`, authHeaders(token))
    },
    async getTotal (token) {
        return apiClient.get('index/total', authHeaders(token))
    }
}