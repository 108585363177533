// Utilities
import { dispatch, make } from 'vuex-pathify'
import api from '../../services/ExportService'


const state = {
  exports: [],
  export: {},
  error: {},
  indexes: [],
  loading: false,
}

const mutations = {
    ...make.mutations(state),
    DEL_EXPORT(state, id) {
        let i = state.exports.map(item => item.id).indexOf(id)
        state.exports.splice(i, 1)
    },
    ADD_EXPORT(state, payload) {
        state.exports.push(payload)
    },
    UPD_EXPORT(state, payload) {
        let i = state.exports.map(item => item.id).indexOf(payload.id)
        state.exports[i] = payload
    }
}

// Used as a template
// const REPLACEME = async ({commit, dispatch, state, rootState}) => {
//     try {
//         commit('loading', true)

//     } catch (error) {
//         commit('error', error)
//         console.log(error)
//     } finally {
//         commit('loading', false)
//     }
// }

/**
 * Fetches all exports for current user from backend.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 */
const fetchExports = async ({commit, rootState}) => {
    try {
        commit('loading', true)
        let response = await api.getExports(rootState.newauth.token)
        commit('exports', response.data)
    } catch (error) {
        commit('error', error)
        dispatch('messages/showMessage',
                {message: `Error when fetching Exports`, color: 'error'},
                {root: true})
    } finally {
        commit('loading', false)
    }
}

/**
 * Fetches the `Export` with given id.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 * @param {int} id - Export id
 */
const fetchExport = async ({commit, rootState}, id) => {
    try {
        commit('loading', true)
        let response = await api.getExport(id, rootState.newauth.token)
        commit('export', response.data)
        commit('UPD_EXPORT', response.data)
    } catch (error) {
        commit('error', error)
        dispatch('messages/showMessage',
                {message: `Error when fetching Export with id: ${id}`, color: 'error'},
                {root: true})
    } finally {
        commit('loading', false)
    }
}

/**
 * Creates a new Export object.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 * @param {Object} payload - payload of ExportCreate to post
 */
const createExport = async ({commit, rootState}, payload) => {
    try {
        commit('loading', true)
        let response = await api.postCreateExport(payload, rootState.newauth.token)
        commit('export', response.data)
        commit('ADD_EXPORT', response.data)
    } catch (error) {
        commit('error', error)
        console.log(error)
    } finally {
        commit('loading', false)
    }
}

/**
 * Deletes an Export object with given id.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 * @param {int} id - Export id
 */
const delExport = async ({commit, dispatch, rootState}, id) => {
    try {
        commit('loading', true)
        let response = await api.delExport(id, rootState.newauth.token)
        commit('DEL_EXPORT', id)
        commit('export', {})
        dispatch('messages/showMessage',
                 {message: `Export configuration deleted`, color: 'success'},
                 {root: true})
    } catch (error) {
        commit('error', error)
        dispatch('messages/showMessage',
                {message: `Error when deleting Export configuration`, color: 'error'},
                {root: true})
    } finally {
        commit('loading', false)
    }
}

/**
 * Updates an Export object with given id.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 * @param {Object} payload - payload of ExportUpdate to post
 */
const updateExport = async ({commit, dispatch, rootState}, {id, payload}) => {
    try {
        commit('loading', true)
        let response = await api.postUpdateExport(id, payload, rootState.newauth.token)
        commit('export', response.data)
        commit('UPD_EXPORT', response.data)
        dispatch('messages/showMessage', {message: 'Updated export configuration', color: 'success'}, {root: true})
    } catch (error) {
        commit('error', error)
        dispatch('messages/showMessage',
                {message: `Error when updating Export configuration`, color: 'error'},
                {root: true})
    } finally {
        commit('loading', false)
    }
}


/**
 * Gets all the itesm that represent the filtered collection of IncidentIndexes.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 * @param {int} id - Export id
 */
const exportPreview = async ({commit, rootState}, id) => {
    try {
        commit('loading', true)
        let response = await api.getExportPreview(id, rootState.newauth.token)
        // console.log('exportPreview - response:' + response.data)
        commit('indexes', response.data)
    } catch (error) {
        commit('error', error)
        dispatch('messages/showMessage',
                {message: `Error when creating Preview for Export configuration`, color: 'error'},
                {root: true})
    } finally {
        commit('loading', false)
    }
}

/**
 * Posts filter information to preview Incidents
 * 
 * @param {Object} {commit,rootState} - vuex methods
 * @param {Object} payload - payload filters for preview
 */
const getPreview = async ({commit, rootState}, payload) => {
    try {
        commit('loading', true)
        let response = await api.postPreview(payload, rootState.newauth.token)
        commit('indexes', response.data)
    } catch (error) {
        commit('error', error)
        console.log(error)
    } finally {
        commit('loading', false)
    }
}

const actions = {
    fetchExports,
    fetchExport,
    createExport,
    delExport,
    updateExport,
    exportPreview,
    getPreview
}

const getters = {
    getIndexes: state => state.indexes,
    exportLoading: state => state.loading,
    getExports: state => state.exports,
    getExportById: (state) => _id => {
        return state.exports.find(({id}) => id === _id)
    },
    getExport: state => state.export,
    getError: state => state.error
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
