/**
 * try to prefix the methods of the service with the method
 * that will execute the call, as in get/post/delete/update
 */

import axios from 'axios'

function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}

const apiClient = axios.create({
    // baseURL: `http://localhost:8001/`,
    baseURL: process.env.VUE_APP_API_V1,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})


export default {
    async getReports (token) {
        return apiClient.get('report/', authHeaders(token))
    },
    async getReportById(id, token) {
        return apiClient.get(`report/${id}/`, authHeaders(token))
    },
    async postReport(report, token) {
        return apiClient.post(`report/`, report, authHeaders(token))
    },
    async putReport(id, report, token) {
        return apiClient.put(`report/${id}/`, report, authHeaders(token))
    },
    async deleteReport(id, token) {
        return apiClient.delete(`report/${id}/`, authHeaders(token))
    },
    async createIncident(id, token) {
        return apiClient.get(`report/${id}/create/`, authHeaders(token))
    }
}
