export default class InformantDetails {
    constructor(entrycode = 0, nameAndSurname, occupation, agencyOrganization, townOrVillage, phone, email, extraInformation) {
        this.entrycode = entrycode;
        this.nameAndSurname = nameAndSurname;
        this.occupation = occupation;
        this.agencyOrganization = agencyOrganization;
        this.townOrVillage = townOrVillage;
        this.phone = phone;
        this.email = email;
        this.extraInformation = extraInformation;
    }
}