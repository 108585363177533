<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Samples and/or evidence collected</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model.number="newData.numberOfSamplesCollected"
          label="Number of samples and evidence collected"
          type="number"
          required
          outlined
          filled
          :rules="numberrange"
          @input="totalNumber"
        />

        <!-- SampleCollectedInfoPage -->
        <!-- Extention part -->
        <template v-if="newData.numberOfSamplesCollected > 0">
          <div v-for="(addedform, i) in adder" :key="i">
            <v-divider class="mb-5" />
            <p>Use ADD button in the end to extend the form for items that have different information</p>
            <v-text-field 
              v-model.number="addedform.number"
              type="number"
              @input="checknumbers"
              :rules="numberRule" 
              label="# Samples of same description"
              outlined
            />
            <v-text-field
              v-model="addedform.sampleEvidenceCode"
              label="Sample/Evidence code"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.storedIn"
              label="Stored in"
              :disabled="!addedform.number"
            />
            <v-select
              v-model="addedform.collectedBy"
              label="Collected by"
              :items="CollectedBy"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.collectedOther"
              label="Specify who if other"
              v-if="addedform.collectedBy === 'Other'"
              :disabled="!addedform.number"
            />
            <v-select 
              v-model="addedform.storedBy" 
              label="Stored by" 
              :items="CollectedBy"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.storedOther"
              label="Specify who if other"
              v-if="addedform.storedBy === 'Other'"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.storingPlace"
              label="Storing place"
              :disabled="!addedform.number"
            />
            <v-select 
              v-model="addedform.sentTo" 
              label="Sent to" 
              :items="SentTo"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.sentToOther"
              label="Specify where if other"
              v-if="addedform.sentTo === 'Other'"
              :disabled="!addedform.number"
            />
            <v-menu 
              v-model="dateSentMenu" 
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Date sent"
                  v-model="dateSent"
                  readonly
                  v-on="on"
                  append-icon="mdi-calendar"
                  :disabled="!addedform.number"
                  outlined
                required/>
              </template>
              <v-date-picker
                v-model="addedform.dateSent"
                :locale="locale"
                min="1980-01-01"
                max="2050-01-01"
                @change="setDateSent"
                @input="dateSentMenu = false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-model="addedform.results"
              label="Results"
              :disabled="!addedform.number"
            />
          </div>
        </template>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn
        v-if="addbuttonrules"
        :disabled="addbuttondis"
        color="warning"
        @click.prevent="addform"
      >
        ADD
      </v-btn>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        color="grey lighten-2"
        @click="reset"
      >Clear</v-btn>
      <v-btn
        :disabled="!valid || !isDirty"
        color="#7eb09f"
        @click="validate"
      >Save
        <v-progress-circular color="biscuit" v-if="loading" indeterminate/>      
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { sync, get } from 'vuex-pathify'
import MultiPageMixin from '@/mixins/MultiPage.js'
import SampleCollectedInfo from '@/models/samplecollectedinfo.js'
import SampleCollectedInfoPage from '@/models/samplecollectedinfopage.js'
import { CollectedBy, SentTo } from '@/utils/selectoptions.js'
import { formatDate, cloneObject } from '@/utils/general.js'
import { DateTime } from 'luxon'


export default {
  mixins: [MultiPageMixin],
  props: {
    SampleCollectedInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    passed: {
      type: Boolean,
      default: false
    },
    entrycode: {
      type: Number
    }
  },
  data: function () {
    return {
      pageclass: SampleCollectedInfoPage,
      pagesarray: 'samplecollectedinfopages',
      numbername: 'numberOfSamplesCollected',
      valid: false,
      loading: false,
      required: [
        v => !!v || 'Field is required'
      ],
      numberrange: [
        // v => !!v || 'Field is required',
        v => { if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
          return 'Number has to be between 0 and 999';}
      ],
      dateSentMenu: false,
      dateSent: this.passed ? this.SampleCollectedInfo.dateSent : "",
      CollectedBy: CollectedBy,
      SentTo: SentTo
    };
  },
  methods: {
    validate () {
      this.loading=true
      if(this.$refs.form.validate()) {
        // check the adder contents
        this.sanitycheck()
        this.newData.samplecollectedinfopages = this.adder
        this.$store.dispatch('incidentsnew/updSamples',
          {entrycode: this.entrycode,
           payload: this.newData})
          .then(() => {
            this.loading = false
            this.initData = cloneObject(this.newData)
          })
          .catch(e => {
            console.log(this.$store.state.incidentsnew.error)
            throw(e)
          })  // FIXME
      } else {
        this.loading = false
      }
    },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    setDateSent (evt) {
      this.dateSent = formatDate(evt)
    }
  },
  created () {
    //this.newData = new SampleCollectedInfo()
  },
  mounted () {
    if (this.passed) {
      this.initData = {...this.SampleCollectedInfo}
      this.newData = JSON.parse(JSON.stringify(this.SampleCollectedInfo))

      if ('samplecollectedinfopages' in this.initData) {
        if (this.newData.samplecollectedinfopages.length > 0) {
          this.adder = [...this.newData.samplecollectedinfopages]
          this.maintotal = this.newData.numberOfSamplesCollected

          // fix the dates
          this.newData.samplecollectedinfopages.forEach(page => {
            page['dateSent'] = DateTime.fromFormat(page['dateSent'], 'dd/MM/yyyy').toISODate()
          })
          this.initData.samplecollectedinfopages.forEach(page => {
            page['dateSent'] = DateTime.fromFormat(page['dateSent'], 'dd/MM/yyyy').toISODate()
          })
        }
      }

      // if (this.newData.samplecollecteddateSent) {
      //   this.newData.dateSent = DateTime.fromFormat(this.newData.dateSent, 'dd/MM/yyyy').toISODate()
      //   this.initData.dateSent = DateTime.fromFormat(this.initData.dateSent, 'dd/MM/yyyy').toISODate()
      // }

      this.$refs.form.resetValidation()
      this.checknumbers()
    }
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...get('form', [
      'isDirty'
    ]),
    locale() {
      return this.$i18n.locale
    }
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>