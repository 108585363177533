<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Notification of poison event</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <!-- START Date of notification  -->
        <v-menu
          v-model="notificationDayMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date of notification"
              v-model="formattedNotificationDay"
              readonly
              :value="parsedinput"
              v-on="on"
              required
              outlined
              append-icon="mdi-calendar"
              :rules="required"
            />
          </template>
          <v-date-picker
            v-model="notificationDay"
            locale="el-EL"
            min="1980-01-01"
            max="2050-01-01"
            @input="notificationDayMenu = false"
          ></v-date-picker>
        </v-menu>
        <!-- END date of notification -->

        <!-- START Date of event -->
        <v-menu
          v-model="eventDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date of notification"
              v-model="eventDate"
              readonly
              :value="parsedinput"
              v-on="on"
              required
              outlined
              append-icon="mdi-calendar"
              :rules="required"
            />
          </template>
          <v-date-picker
            v-model="eventDate"
            locale="el-EL"
            min="1980-01-01"
            max="2050-01-01"
            @input="eventDateMenu = false"
          ></v-date-picker>
        </v-menu>
        <!-- END date of event -->

        <v-textarea
          filled
          v-model="eventDiscoveredWhile"
          label="Poison event discovered while"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        color="grey lighten-2"
        @click="reset"
      >Clear</v-btn>
      <v-btn
        :disabled="!valid"
        color="#7eb09f"
        @click="validate"
      >Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    NotificationInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    passed: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      valid: true,
      required: [
        v => !!v || 'Field is required'
      ],
      notificationDay: this.passed ? this.NotificationInfo.notificationDay : null,
      formattedNotificationDay: this.passed ? this.NotificationInfo.notificationDay : null,
      eventDate: this.passed ? this.NotificationInfo.eventDate : "",
      eventDiscoveredWhile: this.passed ? this.NotificationInfo.eventDiscoveredWhile : "",
      notificationDayMenu: this.passed ? this.NotificationInfo.notificationDayMenu : "",
      eventDateMenu: this.passed ? this.NotificationInfo.eventDateMenu : "",
    };
  },
  computed: {
    parsedinput() {
      if (this.notificationDay) {
        console.log("asdfasdf");
        let splitdate = this.notificationDay.split("-");
        return "" + splitdate[2] + "/" + splitdate[1] + "/" + splitdate[0];
      }
      return "";
    },
  },
  watch: {
    notificationDay (val) {
      this.formattedNotificationDay = this.formatDate(this.notificationDay)
    }
  },
  methods: {
    formatDate (date) {
      if(!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    validate () {
      this.$refs.form.validate()
    },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>