<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Disposal of Dead Animals</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model="newData.numberOfAnimals"
          label="Number of animals"
          type="number"
          outlined
        />
        <v-select
          v-model="newData.disposalMethod"
          label="Disposal method"
          :items="AnimalDisposalMethods"
        />
        <v-select
          v-model="newData.disposedAtLocIndByAuthorities"
          label="Disposed at location indicated by authorities"
          :items="StdChoices"
        />
        <v-text-field
          v-model="newData.location"
          label="Location"
        />
        <v-select
          v-model="newData.disposedBy"
          label="Disposed by"
          :items="DisposedBy"
        />

        <v-select
          v-model="newData.takenByAuthToBeDisposed"
          label="Taken by authorities to be disposed of"
          :items="StdChoices"
        />

        <v-select
          v-model="newData.takenByHiredCompany"
          label="Taken by hired company"
          :items="StdChoices"
        />
        <v-text-field
          v-model="newData.companyName"
          label="Company name"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        color="grey lighten-2"
        @click="reset"
      >Clear</v-btn>
      <v-btn
        :disabled="!valid || !isDirty"
        color="#7eb09f"
        @click="validate"
      >Save
        <v-progress-circular color="biscuit" v-if="loading" indeterminate/>     
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { sync, get } from 'vuex-pathify'
import DisposalOfDeadAnimals from '@/models/disposalofdeadanimals.js'
import { StdChoices, AnimalDisposalMethods, DisposedBy } from '@/utils/selectoptions.js'

export default {
  props: {
    DisposalOfDeadAnimals: {
      type: Object,
      default: function () {
        return {}
      }
    },
    passed: {
      type: Boolean,
      default: false
    },
    entrycode: {
      type: Number
    }
  },
  data: function () {
    return {
      valid: false,
      loading: false,
      required: [
        v => !!v || 'Field is required'
      ],
      StdChoices: StdChoices,
      AnimalDisposalMethods: AnimalDisposalMethods,
      DisposedBy: DisposedBy
    };
  },
  methods: {
    validate () {
      this.loading = true
      if (this.$refs.form.validate()) {
        this.$store.dispatch('incidentsnew/updDisposal', {entrycode: this.entrycode, payload: this.newData})
          .then(() => {
            this.loading = false
            this.initData = {...this.newData}
          })
          .catch(e => {
            console.log('WE GOT THE ERROR')
            console.log(this.$store.state.incidentsnew.error)
          })  // FIXME
      } else {
        this.loading = false
      }
    },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  },
  created () {
    this.newData = new DisposalOfDeadAnimals()
  },
  mounted () {
    if (this.passed) {
      this.initData = {...this.DisposalOfDeadAnimals}
      this.newData = this.DisposalOfDeadAnimals
      this.$refs.form.resetValidation()
    }
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...get('form', [
      'isDirty'
    ])
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>