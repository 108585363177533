import IncidentService from '@/services/IncidentService.js'
import { formatDate } from '@/utils/general.js'
import { commit, dispatch } from 'vuex-pathify'


// FIXME add finally clauses everywhere to simplify the 
// code and the mutliple loading flags.
export default {
    async fetchIndexes({ commit, dispatch, rootState }) {
        try {
            commit('SET_LOADING', true)
            let indexes = await IncidentService.getIncidentsIndex(rootState.newauth.token)
            commit('ADD_INDEXES', indexes.data)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log('Something went wrong while fetching indexes')
            commit('INCIDENT_ERROR', error)
            // FIXME: push to login again, since we are not authenticated any more
            await dispatch('newauth/actionLogOut', null, { root: true })
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async fetchIncidentById({commit, rootState}, id) {
        try {
            commit('SET_LOADING', true)
            let incident = await IncidentService.getIncidentById(id, rootState.newauth.token)
            commit('SET_INCIDENT_ID', incident)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log('Error when getting Incidents by ID')
            commit('INCIDENT_ERROR', error)
            // FIXME: push to login again, since we are not authenticated any more
            await dispatch('newauth/actionLogOut', null, { root: true })
        }
    },
    async fetchIncidentByEntryCode({commit, rootState}, entrycode) {
        try {
            commit('SET_LOADING', true)
            let incident = await IncidentService.getIncidentByEntryCode(entrycode, rootState.newauth.token)
            commit('SET_INCIDENT_ENTRYCODE', incident)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log('Error when getting Incidents by EntryCode')
            commit('INCIDENT_ERROR', error)
        }
    },
    async createIncident({commit, rootState}) {
        try {
            commit('SET_LOADING', true)
            let incident = await IncidentService.postIncident(rootState.newauth.token)
            commit('SET_LOADING', false)
            return incident.data
        } catch (error) {
            commit('SET_LOADING', false)
            console.log('Error when creating incident')
            commit('INCIDENT_ERROR', error)
            // FIXME: push to login again, since we are not authenticated any more
            await dispatch('newauth/actionLogOut', null, { root: true })
        }
    },
    async deleteIncident({commit, rootState}, entrycode) {
        try {
            commit('SET_LOADING', true)
            await IncidentService.deleteIncident(entrycode, rootState.newauth.token)
            commit('DEL_INCIDENT', entrycode)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log('Error when deleting incident')
            commit('INCIDENT_ERROR', error)
            throw error
        }
    },
    async toggleLive({commit, rootState}, payload) {
        try {
            await IncidentService.postLive(payload, rootState.newauth.token)
            commit('SET_LIVE', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', error)
        }
    },
    async updInformant({commit, rootState}, {entrycode, payload}) {
        try {
            // in the case of Informant we need to add the entrycode
            // in the Informant object as well
            payload.entryCode = entrycode
            await IncidentService.postInformant(entrycode, payload, rootState.newauth.token)
            commit('SET_INFORMANT', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', error)
            // FIXME: push to login again, since we are not authenticated any more
            await dispatch('newauth/actionLogOut', null, { root: true })
        }
    },
    async updNotification({commit, rootState}, {entrycode, payload}) {
        try {
            // clone payload to format the dates...
            let _payload = JSON.parse(JSON.stringify(payload))
            _payload.eventDate = formatDate(_payload.eventDate)
            _payload.notificationDay = formatDate(_payload.notificationDay)
            await IncidentService.postNotification(entrycode, _payload, rootState.newauth.token)
            commit('SET_NOTIFICATION', _payload)
        } catch (error) {
            commit('INCIDENT_ERROR', error)
            // FIXME: push to login again, since we are not authenticated any more
            await dispatch('newauth/actionLogOut', null, { root: true })
        }
    },
    async updEventLocation({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postEventLocation(entrycode, payload, rootState.newauth.token)
            commit('SET_EVENTLOCATION', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', error)
            await dispatch('newauth/actionLogOut', null, { root: true })
        }
    },
    async updDanimal({commit, rootState}, {entrycode, payload}) {
        // check if there are images in the payload
        try {
            for(let i = 0; i < payload.deadanimalinfopages.length; i++) {
                let images = payload.deadanimalinfopages[i].hasOwnProperty('imagebuffer') ? payload.deadanimalinfopages[i].imagebuffer : []
                if (images.length > 0) {
                    try {
                        await IncidentService.postFiles(
                            entrycode,
                            'DeadAnimalInfo',
                            images)
                        console.log('posting images correctly')
                    } catch (error) {
                        console.log('error posting the images')
                        console.log(error)
                    }
                }                
            }

        } catch (error) {
            console.log('error posting the images')
            console.log(error)
        }

        try {
            
            // let images = payload.hasOwnProperty('imagebuffer') ? payload.images : []
            // if (images.length > 0) {
            //     try {
            //         await IncidentService.postFiles(entrycode, 'DeadAnimalInfo', images)
            //         console.log('posting images correctly')
            //     } catch (error) {
            //         console.log('error posting the images')
            //         console.log(error)
            //     }
            // }

            await IncidentService.postDanimal(entrycode, payload, rootState.newauth.token)
            commit('SET_DANIMAL', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updAlanimal({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postAlanimal(entrycode, payload, rootState.newauth.token)
            commit('SET_ALANIMAL', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updPoisonbait({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postPoisonbait(entrycode, payload, rootState.newauth.token)
            commit('SET_POISONBAIT', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updEvidence({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postEvidence(entrycode, payload, rootState.newauth.token)
            commit('SET_EVIDENCE', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updMotive({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postMotive(entrycode, payload, rootState.newauth.token)
            commit('SET_MOTIVE', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updAuthorities({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postAuthorities(entrycode, payload, rootState.newauth.token)
            commit('SET_AUTHROTIES', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updAntipoisondog({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postAntipoisondog(entrycode, payload, rootState.newauth.token)
            commit('SET_ANTIPOISONDOG', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updSamples({commit, rootState}, {entrycode, payload}) {
        try {
            // We have the date: dateSent
            let _payload = JSON.parse(JSON.stringify(payload))
            _payload.samplecollectedinfopages.forEach(page => {
                page['dateSent'] = formatDate(page['dateSent'])
            })
            await IncidentService.postSamples(entrycode, _payload, rootState.newauth.token)
            commit('SET_SAMPLES', _payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updDisposal({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postDisposal(entrycode, payload, rootState.newauth.token)
            commit('SET_DISPOSAL', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updOtherreasons({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postOtherreasons(entrycode, payload, rootState.newauth.token)
            commit('SET_OTHERREASONS', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updComplaint({commit, rootState}, {entrycode, payload}) {
        try {
            // We have the date: dateComplaint, dateOfCourt
            let _payload = JSON.parse(JSON.stringify(payload))
            if (_payload.dateComplaint) {
                _payload.dateComplaint = formatDate(_payload.dateComplaint)
            }
            if (_payload.dateOfCourt) {
                _payload.dateOfCourt = formatDate(_payload.dateOfCourt)
            }
            await IncidentService.postComplaint(entrycode, _payload, rootState.newauth.token)
            commit('SET_COMPLAINT', _payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updAutopsy({commit, rootState}, {entrycode, payload}) {
        try {
            // We have the date: dateOfReport
            let _payload = JSON.parse(JSON.stringify(payload))
            _payload.dateOfReport = formatDate(_payload.dateOfReport)
            await IncidentService.postAutopsy(entrycode, _payload, rootState.newauth.token)
            commit('SET_AUTOPSY', _payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updToxins({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postToxins(entrycode, payload, rootState.newauth.token)
            commit('SET_TOXINS', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updGcomment({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postGcomment(entrycode, payload, rootState.newauth.token)
            commit('SET_GCOMMENT', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updPublic({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postPublic(entrycode, payload, rootState.newauth.token)
            commit('SET_PUBLIC', payload)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    // POST FILES
    async updFiles({commit, rootState}, {entrycode, category,  payload}) {
        try {
            await IncidentService.postFiles(entrycode, category, payload, rootState.newauth.token)
            console.log('SUCCESS in posting the files!!!!')  // FIXME
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updFilesSimple({commit, rootState}, {entrycode, payload}) {
        // FIXME: needs to have a return value for the path of the files on the server
        // and update the thumbnails in the location of the updating of the files.
        try {
            await IncidentService.postFilesSimple(entrycode, payload, rootState.newauth.token)
            console.log('Success in posting the files!!!')
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async updFilesDoc({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.postFilesDoc(entrycode, payload, rootState.newauth.token)
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
        }
    },
    async delFilesSimple({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.delImage(entrycode, payload, rootState.newauth.token)
            console.log('Image properly deleted')
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    async delDocuments({commit, rootState}, {entrycode, payload}) {
        try {
            await IncidentService.delDocument(entrycode, payload, rootState.newauth.token)
            console.log('Document properly deleted')
        } catch (error) {
            commit('INCIDENT_ERROR', JSON.stringify(error))
            throw error
        }
    },
    // GET LAST MODIFIED ENTRIES BY USER
    async fetchLastModifiedByUser({commit, rootState}) {
        try {
            commit('SET_LOADING', true)
            let indexes = await IncidentService.getLastModifiedByUser(rootState.newauth.token)
            commit('SET_LASTMODIFIEDBYUSER', indexes.data)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            commit('INCIDENT_ERROR', error)
        }
    },
    async fetchLastModified({commit, rootState}) {
        try {
            commit('SET_LOADING', true)
            let indexes = await IncidentService.getLastModified(rootState.newauth.token)
            commit('SET_LASTMODIFIED', indexes.data)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            commit('INCIDENT_ERROR', error)
        }
    }

}