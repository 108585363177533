<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Informant Details</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <!-- <v-text-field v-model="entryCode" label="Entrycode" type="number" /> -->
        <v-combobox
          v-model="newData.nameAndSurname" 
          label="Name & Surname"
          required 
          :rules="required"
          :items="anonymous"
          chips
          clearable
        />
        <v-text-field v-model="newData.occupation" label="Informer's occupation" required 
          :rules="required" />
        <v-text-field v-model="newData.agencyOrganization" label="Agency/Organization"  
          />
        <v-text-field v-model="newData.townOrVillage" label="Town/village"  
           />
        <v-text-field v-model="newData.phone" label="Phone" />
        <v-text-field v-model="newData.email" label="Email"  
           />
        <v-text-field v-model="newData.extraInformation" label="Other source of information (e.g. Webpage)" />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        color="grey lighten-2"
        @click="reset"
      >Clear</v-btn>
      <v-btn
        :disabled="!valid || !isDirty"
        color="#7eb09f"
        @click="validate"
      >Save
        <v-progress-circular color="biscuit" v-if="loading" indeterminate/>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {set, get, sync} from 'vuex-pathify'
import Informant from '@/models/informantdetails.js'

export default {
  props: {
    InformantDetails: {
      type: Object,
      default: function () {
        return {}
      }
    },
    passed: {
      type: Boolean,
      default: false
    },
    entrycode: {
      type: Number
    }
  },
  // data: () => ({
  //   entryCode: 0,
  //   nameAndSurname: this.passed ? this.InformantDetails.nameAndSurname : "",
  //   occupation: this.passed ? this.InformantDetails.occupation : "",
  //   agencyOrganization: this.passed ? this.InformantDetails.agencyOrganization : "",
  //   townOrVillage: this.passed ? this.InformantDetails.townOrVillage : "",
  //   phone: this.passed ? this.InformantDetails.phone :  "",
  //   email: this.passed ? this.InformantDetails.email : "",
  //   extraInformation: this.passed ? this.InformantDetails.extraInformation : "",
  // }),
  data: function () {
    return {
      dirty: false, // FIXME testing if we can control avoiding losing info
      valid: true,
      loading: false,
      required: [
        v => !!v || 'Field is required'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    // entryCode: 0,
    // nameAndSurname: this.passed ? this.InformantDetails.nameAndSurname : "",
    // occupation: this.passed ? this.InformantDetails.occupation : "",
    // agencyOrganization: this.passed ? this.InformantDetails.agencyOrganization : "",
    // townOrVillage: this.passed ? this.InformantDetails.townOrVillage : "",
    // phone: this.passed ? this.InformantDetails.phone :  "",
    // email: this.passed ? this.InformantDetails.email : "",
    // extraInformation: this.passed ? this.InformantDetails.extraInformation : "",

    }
  },
  methods: {
    validate () {
      this.loading = true
      if (this.$refs.form.validate()) {
        this.$store.dispatch('incidentsnew/updInformant', {entrycode: this.entrycode, payload: this.newData})
          .then(() => {
            this.loading = false
            this.initData = {...this.newData}
          })
          .catch(e => {
            console.log('WE GOT THE ERROR')
            // console.log(this.$store.state.incidents.error)
            console.log(this.$store.state.incidentsnew.error)
          })  // FIXME
      } else {
        this.loading = false
      }
    },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    confirmLeave() {
      return window.confirm('Do you really want to leave? you have unchanged changes!')
    },
    confirmStayInDirtyForm() {
      console.log("inside the confirstay in dirty form")
      return this.dirty && !this.confirmLeave()
    },
    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    }
  },
  created () {
    this.newData = new Informant()
  },
  mounted () {
    if (this.passed) {
      this.initData = {...this.InformantDetails}
      this.newData = this.InformantDetails
      this.$refs.form.resetValidation()
    }
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...get('form', [
      'isDirty'
    ]),
    anonymous () {
      return [
        'anonymous'
      ]
    }
  }
  // // FIXME: main trick found in: https://stackoverflow.com/a/56551646
  // beforeRouteLeave (to, from, next) {
  //     const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
  //   // if the form is dirty and the user did not confirm leave,
  //   // prevent losing unsaved changes by canceling navigation
  //   console.log('paparia')
  //   if (this.confirmStayInDirtyForm()){
  //     next(false)
  //   } else {
  //     // Navigate to next view
  //     next('/dashboard')
  //   }
  // },
  // created () {
  //   window.addEventListener('beforeunload', this.beforeWindowUnload)
  // },
  // beforeDestroy () {
  //   window.removeEventListener('beforeunload', this.beforeWindowUnload)
  // },
  // // Another method, mentioned in: https://stackoverflow.com/a/61457253
  // // mounted () {
  // //   window.onbeforeunload = () => (this.unsavedChanges ? true : null);
  // // }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>