// import AuthService from "../../../services/AuthService"
import api from "../../../services/AuthService"

export default {
    login({ commit }, user) {
        return api.login(user).then(
            user => {
                console.log('THIS IS THE LOGIN!')
                commit('loginSuccess', user)
                commit('setTimestamp', Date.now())
                return Promise.resolve(user)
            },
            error => {
                commit('loginFailure')
                return Promise.reject(error)
            }
        )
    },
    logout({ commit }) {
        api.logout()
        commit('logout')
    },
    register({ commit }, user) {
        return api.register(user).then(
            response => {
                commit('registerSuccess')
                return Promise.resolve(response.data)
            },
            error => {
                commit('registerFailure')
                return Promise.reject(error)
            }
        )
    },
    refresh({ commit }, user) {
        console.log('the auth action here!')
        return api.refresh(user.access_token).then(
            response=>{
                commit('loginSuccess', user)
                commit('setTimestamp', Date.now())
            },
            error => {
                console.log(error)
            }
        )
    }
}