<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Dead animal found</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-select
          v-model="newData.deadAnimalFound"
          label="Dead animal found"
          :items="StdChoices"
          required
          :rules="required"
          @change="numberFieldSelectChange"
        />
        <v-text-field
          v-model.number="newData.numberOfDeadAnimals"
          label="Total number dead animals found"
          type="number"
          required
          outlined
          filled
          :rules="numberfieldcontrol"
          @input="totalNumber"
          :disabled="numberFieldDisable"
        />


        <!-- Extention part -->
        <template v-if="newData.numberOfDeadAnimals > 0">
          <div v-for="(addedform, i) in adder" :key="i">
            <v-divider class="mb-5" />
            <p>Use ADD button in the end to extend the form for items that have different information</p>
            <v-text-field 
              v-model.number="addedform.number"
              label="# Animals of same description"
              type="number"
              outlined
              @input="checknumbers"
              :rules="numberRule" 
            />
            <v-select
              v-model="addedform.species"
              label="Animal species"
              :items="AnimalSpecies"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.notListedSpecies" 
              label="If other specify"
              v-if="addedform.species === 'Other'"
              :disabled="!addedform.number"
            />

            <v-select
              v-model="addedform.marking"
              label="Identification marking"
              :items="StdChoicesExt"
              :disabled="!addedform.number"
            />
            <v-select
              v-model="addedform.markingType"
              label="Type of marking"
              :items="Markings"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.markingCodes"
              label="Marking codes"
              :disabled="!addedform.number"
            />

            <p>Select marker from <b>Map Control</b> to copy values from. Use 
            <b>Confirm Marker</b> or fill in coordinates</p>
            <v-select
              v-model="pivot[i]"
              label="Select marker"
              item-text="name"
              return-object
              :items="foomarkers"
              @change="updateMarker"
            >
              <!-- <template slot="no-data">
                <v-btn block @click.prevent="addMarker" >Add marker</v-btn>
              </template> -->
              <template v-slot:item="{ item }">
                <p>{{ item.name }}</p>
              </template>
              <template slot="append-item">
                <v-btn block @click.prevent="addMarker(i)">Add marker</v-btn>
              </template>
            </v-select>
            <!-- button drag logic -->
            <!-- <v-btn v-if="dragMarker" @click.prevent="dragfinished(i)">Finish dragging Marker</v-btn>
            <v-btn v-else @click.prevent="allowdrag">Drag Marker</v-btn> -->
            <!-- end button drag logic -->

            <!-- START original approach on lng-lat selection -->
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model.number="addedform.lat"
                      label="Lat"
                      type="number"
                      :disabled="!addedform.number"
                      outlined
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model.number="addedform.lng"
                      label="Lng"
                      type="number"
                      :disabled="!addedform.number"
                      outlined
                    />
                  </v-col>


                  <!-- <v-col>
                    <v-row>
                      <v-col>
                        <v-btn block @click.prevent="controlMarker">
                          Control Marker
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn block @click.prevent="changeCenter">
                          move center
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
            <!-- END lng-lat selection -->



            <!-- <v-text-field
              v-model.number="addedform.lat"
              label="Lat"
              type="number"
              :disabled="!addedform.number"
              outlined
            />
            <v-text-field
              v-model.number="addedform.lng"
              label="Lng"
              type="number"
              :disabled="!addedform.number"
              outlined
            /> -->



            <v-select
              v-model="addedform.carcass"
              label="StateCarcass"
              :items="StateCarcass"
              :disabled="!addedform.number"
            />
            <v-textarea
              v-model="addedform.comments"
              label="Comments"
              :disabled="!addedform.number"
              filled
            />
          </div>
        </template>

      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn
        v-if="addbuttonrules"
        :disabled="addbuttondis"
        color="warning"
        @click.prevent="addform"
      >
        ADD
      </v-btn>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        color="grey lighten-2"
        @click="reset"
      >Clear</v-btn>
      <v-btn
        :disabled="!valid || !isDirty"
        color="#7eb09f"
        @click="validate"
      >Save
        <v-progress-circular color="biscuit" v-if="loading" indeterminate/>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { sync, get, call } from 'vuex-pathify'
import MultiPageMixin from '@/mixins/MultiPage.js'
import DeadAnimalInfo from '@/models/deadanimalinfo.js'
import DeadAnimalInfoPage from '@/models/deadanimalinfopage.js'
import { AnimalSpecies, StateCarcass, StdChoicesDAI, StdChoicesExt, Markings } from '@/utils/selectoptions.js'
import { cloneObject, getMarkers } from '@/utils/general.js'

export default {
  mixins: [MultiPageMixin],
  props: {
    DeadAnimalInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    passed: {
      type: Boolean,
      default: false
    },
    entrycode: {
      type: Number
    }
  },
  data: function () {
    return {
      foomarkers: [],
      pivot: [],
      moveMarker: false,
      dragMarker: true,
      pageclass: DeadAnimalInfoPage,
      pagesarray: 'deadanimalinfopages',
      numbername: 'numberOfDeadAnimals',
      valid: false,
      loading: false,
      addbuttondis: true,
      adder: [],
      numberRule: [
          v => (this.valTotal(v)) || 'Input cannot exceed total number'
      ],
      maintotal: 0,
      required: [
        v => !!v || 'Field is required'
      ],
      // The following rule is used to control the number values
      // based on the select option. Used together with the 
      // numberFieldDisable
      numberfieldcontrol: [
        // v => !!v || 'Field is required',
        v => { if (!isNaN(parseFloat(v)) && v >= -1 && v <= 999) return true;
          return 'Number has to be between -1 and 999';}
      ],
      numberFieldDisable: false,
      StdChoices: StdChoicesDAI,
      AnimalSpecies: AnimalSpecies,
      StateCarcass: StateCarcass,
      StdChoicesExt: StdChoicesExt,
      Markings: Markings
    };
  },
  watch: {
    lalamarker (val) {
      console.log('akkakaskfasdf')
      console.log(val)
    }
  },
  methods: {
    ...call('mapform', [
      'setMarker',
      'setCenter',
      'setMagic',
      'mapaddMarker'
    ]),
    validate () {
      this.loading=true
      if(this.$refs.form.validate()) {
        // check the adder contents
        this.sanitycheck()
        this.newData.deadanimalinfopages = this.adder
        this.$store.dispatch('incidentsnew/updDanimal',
          {entrycode: this.entrycode,
           payload: this.newData})
          .then(() => {
            // check if we are running validation automatically?
            this.loading = false
            this.initData = cloneObject(this.newData)
          })
          .catch(e => {
            console.log('WE GOT THE ERROR')
            console.log(this.$store.state.incidentsnew.error)
          })  // FIXME
      } else {
        this.loading = false
      }
    },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    controlMarker () {
      this.setMarker()
      // this.setMagic()
    },
    changeCenter () {
      this.setCenter()
      this.setMagic()
    },
    addMarker (i) {
      // console.log('Settin the marker in the: ' + i)
      this.mapaddMarker()
      this.foomarkers.push(this.mymarker)
      this.pivot[i] = {'name': this.mymarker.name}
      // this.adder[i].lat = this.mymarker.position.lat
      // this.adder[i].lng = this.mymarker.position.lng
      // console.log(this.mymarker)
    },
    dragfinished(i) {
      this.mymarker.draggable = false
      this.adder[i].lat = this.mymarker.position.lat
      this.adder[i].lng = this.mymarker.position.lng
      this.dragMarker = false
    },
    allowdrag() {
      this.mymarker.draggable = true
      this.dragMarker = true
    },
    updateMarker(e) {
      console.log(e)
    },
    numberFieldSelectChange (e) {
      switch (e) {
        case 'Unknown':
          this.$set(this.newData, 'numberOfDeadAnimals', -1)
          // this.newData.numberOfDeadAnimals = -1
          this.numberFieldDisable = true
          break;
        case 'No':
          this.$set(this.newData, 'numberOfDeadAnimals', 0)
          // this.newData.numberOfDeadAnimals = 0
          this.numberFieldDisable = true
          break;
        case 'Yes':
          // this.$set(this.newData, 'numberOfDeadAnimals', 1)
          // this.totalNumber(1)
          // this.newData.numberOfDeadAnimals = 0
          this.numberFieldDisable = false
          break;
        default:
          // this.$set(this.newData, 'numberOfDeadAnimals', null)
          // this.newData.numberOfDeadAnimals = 0
          this.numberFieldDisable = false
      }
    }
  },
  created () {
    this.newData = new DeadAnimalInfo()
    this.foomarkers = getMarkers(this.$store.state.incidentsnew.incident)
  },
  mounted () {
    // Test communication with mapformcontrol
    this.$root.$on('marker-control-complete', (msg) => {

      console.log('the pivot is:')
      console.log(this.pivot)
      // find which entry of the pivot has the name of the marker
      for (let [index, val] of this.pivot.entries()) {
          if (val.name === msg.name ) {
            this.adder[index].lat = msg.position.lat
            this.adder[index].lng = msg.position.lng
          }
      }
      // this.adder[0].lat = msg.position.lat,
      // this.adder[0].lng = msg.position.lng
    })

    if (this.passed) {
      this.initData = this.DeadAnimalInfo
      this.newData = JSON.parse(JSON.stringify(this.DeadAnimalInfo))

      if ('deadanimalinfopages' in this.initData) {   // in case of empty form
        if (this.initData.deadanimalinfopages.length > 0) {
          this.adder = [...this.newData.deadanimalinfopages]
          this.maintotal = this.newData.numberOfDeadAnimals
        }
      }
      this.$refs.form.resetValidation()
      this.checknumbers()
    }
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...sync('mapform', {
      mymarker: "marker"
    }),
    ...get('form', [
      'isDirty'
    ]),
    lalamarker () {
      return this.mymarker
    }
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>