// Utilities
import { make } from 'vuex-pathify'
import { latLng } from "leaflet";

import { getMarkers } from '@/utils/general.js'


const state = {
  lat: 0,
  lng: 0,
  tracking: false,
  mapCenter: latLng(47.41322, -1.219482),
  markers: [],
  marker: {},
  magicnumber: 1,
  foomarkers: [],
  tmpcenter: latLng(38.682613, 22.660929)
}

// const mutations = make.mutations(state)

const mutations = {
  ...make.mutations(state),

  MARKERS (state, payload) {
    state.markers.push(payload)
  },
  FOOMARKERS (state, payload) {
    state.foomarkers = payload
  }
}

const actions = {

  // FIXME: figure out how to set the position of the
  // next marker if the initial markers are 0
  mapaddMarker({commit, state}){
    // we add the name before we update the state.markers
    let markerName = 'M' + (state.foomarkers.length + 1)
    console.log(state.markers.length)
    const _marker = {
      // position: {lat: 38.8582603286814, lng: 22.33427308647427},
      position: state.tmpcenter,
      draggable: true,
      visible: true,
      name: markerName
    }
    commit('marker', _marker)
    // commit('markers', _marker)
    commit('MARKERS', _marker)
  },
  // setMarker({commit, state}) {
  //   if (state.markers.length > 0) {
  //     commit('markers', [])
  //     commit('lat', 0)
  //     commit('lng', 0)
  //   }
  //   const _marker = {
  //     // position: { lat: state.mapCenter.lat, lng: state.mapCenter.lng},
  //     position: { lat: 38.8582603286814, lng: 22.33427308647427},
  //     draggable: true,
  //     visible: true
  //   }
  //   commit('marker', _marker)
  //   // state.markers.push(_marker)
  //   commit('markers', [_marker])
  // },
  // setCenter({commit}) {
  //   commit('mapCenter', latLng(40.15287578, 21.36524752))
  // },
  // setMagic({commit, state}) {
  //   let number = state.magicnumber
  //   number += 1
  //   commit('magicnumber', number)
  // },

  setMarkers({commit, state, rootState}) {
    let tmpmark = getMarkers(rootState.incidentsnew.incident)
    commit('FOOMARKERS', tmpmark)
  }
  // setMarkers({commit, state, rootState}) {
  //   console.log('we are in set Markers')
  //   const checkInterval = setInterval(() => {
  //     if(!rootState.incidentsnew.loading) {
  //       clearInterval(checkInterval)
  //       let tmpmark = getMarkers(rootState.incidentsnew.incident)
  //       commit('FOOMARKERS', tmpmark)
  //     }
  //   }, 100)
  // }

}

const getters = {
  // dark: (state, getters) => {
  //   return (
  //     state.dark ||
  //     getters.gradient.indexOf('255, 255, 255') === -1
  //   )
  // },
  // gradient: state => {
  //   return state.gradients[state.drawer.gradient]
  // },
  // image: state => {
  //   return state.drawer.image === '' ? state.drawer.image : state.images[state.drawer.image]
  // },
  // isDirty: (state) => {
  //   // if initData is empty, then any change of initData makes things dirty!
  //   if (Object.keys(state.initData).length === 0) {
  //     if (Object.keys(state.newData).length === 0)
  //       return false
  //     else 
  //       return true
  //   }
  //   // if we have initial data, dirty is if initData != newData
  //   if (Object.keys(state.initData).length > 0) {
  //     //return !(JSON.stringify(state.initData) === JSON.stringify(state.newData))
  //     return !deepEqual(state.initData, state.newData)
  //   }
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
