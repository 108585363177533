<template>
  <v-card>
    <v-card-title> AliveAnimalInfoPage </v-card-title>
    <v-card-text>
      <v-form>
        <v-select
          v-model="species"
          label="AnimalSpecies"
          :items="AnimalSpecies"
        />
        <v-text-field v-model="notListedSpecies" label="If other specify" v-if="otherspecies"/>
        <v-text-field v-model="lat" label="Lat" type="number" />
        <v-text-field v-model="lng" label="Lng" type="number" />
        
        <v-select
          v-model="amimalState"
          label="State of animal"
          :items="AnimalState"
        />
        <v-select
          v-model="firstAidType"
          label="First aid provided"
          :items="FirstAidType"
        />
        <v-select
          v-model="firstAidProvider"
          label="First aid provided by"
          :items="FirstAidProvider"
        />
        <v-select
          v-model="finalStatus"
          label="Final status"
          :items="FinalStatusValues"
        />
        <v-text-field
          v-model="nameRehabilitationCentre"
          label="Sent to rehabilitation center (name)"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    species: "",
    notListedSpecies: "",
    lat: "",
    lng: "",
    amimalState: "null",
    firstAidType: "null",
    firstAidProvider: "null",
    nameRehabilitationCentre: "",
    finalStatus: "",
    AnimalSpecies: [
      "Bird - Accipiter brevipes",
      "Bird - Accipiter gentilis",
      "Bird - Accipiter nisus",
      "Bird - Aegolius funereus",
      "Bird - Aegypius monachus",
      "Bird - Aquila chrysaetos",
      "Bird - Aquila clanga",
      "Bird - Aquila heliaca",
      "Bird - Aquila nipalensis",
      "Bird - Aquila pomarina",
      "Bird - Asio flammeus",
      "Bird - Asio otus",
      "Bird - Athene noctua",
      "Bird - Bubo bubo",
      "Bird - Buteo buteo",
      "Bird - Buteo lagopus",
      "Bird - Buteo rufinus",
      "Bird - Circaetus gallicus",
      "Bird - Circus aeruginosus",
      "Bird - Circus cyaneus",
      "Bird - Circus macrourus",
      "Bird - Circus pygargus",
      "Bird - Corvidae spp",
      "Bird - Corvus corax",
      "Bird - Corvus corone",
      "Bird - Elanus caeruleus",
      "Bird - Falco biarmicus",
      "Bird - Falco cherrug",
      "Bird - Falco columbarius",
      "Bird - Falco eleonorae",
      "Bird - Falco naumanni",
      "Bird - Falco pelegrinoides",
      "Bird - Falco peregrinus",
      "Bird - Falco subbuteo",
      "Bird - Falco tinnunculus",
      "Bird - Falco vespertinus",
      "Bird - Glaucidium passerinum",
      "Bird - Gypaetus barbatus",
      "Bird - Gyps fulvus",
      "Bird - Haliaeetus albicilla",
      "Bird - Hieraaetus fasciatus",
      "Bird - Hieraaetus pennatus",
      "Bird - Larus spp.",
      "Bird - Milvus migrans",
      "Bird - Milvus milvus",
      "Bird - Neophron percnopterus",
      "Bird - Other bird",
      "Bird - Otus scops",
      "Bird - Pandion haliaetus",
      "Bird - Passerine",
      "Bird - Pelecanus crispus",
      "Bird - Pelecanus onocrotalous",
      "Bird - Pernis apivorus",
      "Bird - Pica pica",
      "Bird - Raptor spp",
      "Bird - Strix aluco",
      "Bird - Tyto alba",
      "Cat - Felis catus",
      "Dog - Hunting dog",
      "Dog - Pet dog",
      "Dog - Shepherd dog",
      "Dog - Stray dog",
      "Dog - Truffle dog",
      "Dog - Unidentified dog",
      "Farm animal - Chicken",
      "Farm animal - Cow",
      "Farm animal - Donkey",
      "Farm animal - Goat",
      "Farm animal - Horse",
      "Farm animal - Other",
      "Farm animal - Pig",
      "Farm animal - Sheep",
      "Farm animal - Turkey",
      "Impossible to identify",
      "Insect - Bee",
      "Insect - Other insect",
      "Insect - Wasp",
      "Reptile - Other reptile",
      "Reptile - Testudo graeca",
      "Reptile - Testudo hermanni",
      "Reptile - Testudo marginata",
      "Wild mammal - Canis aureus",
      "Wild mammal - Canis lupus",
      "Wild mammal - Erinaceus europaeus",
      "Wild mammal - Felis silvestris",
      "Wild mammal - Large mammal spp",
      "Wild mammal - Martes foina",
      "Wild mammal - Martes martes",
      "Wild mammal - Meles meles",
      "Wild mammal - Mustela nivalis",
      "Wild mammal - Other mammal",
      "Wild mammal - Small mammal spp",
      "Wild mammal - Sus scrofa",
      "Wild mammal - Ursus arctos",
      "Wild mammal - Vulpes vulpes",
      "Other"
    ],
    AnimalState: [
      "Vomiting",
      "Convulsions",
      "Vomiting and convulsions",
      "Saliva",
      "Saliva and convulsions",
      "Head drooping / Unable to stand",
      "Lethargic",
      "Bleeding from mouth/gums",
      "Bleeding from anus",
      "Internal hematoma",
      "No external symptoms",
      "Other",
      "Unknown",
    ],
    FirstAidType: [
      "Apomorphine",
      "Atropine",
      "Activated charcoal",
      "Apomorphine & Atropine",
      "Apomorphine, Atropine & Activated charcoal",
      "Rodenticide antidote",
      "Other",
      "Died before it was possible to provide first aid",
      "None",
      "Unknown",
    ],
    FirstAidProvider: [
      "Vet",
      "Warden",
      "NGO staff",
      "Animal Owner",
      "Other",
      "Unknown",
      "Not applicable",
    ],
    FinalStatusValues: [
      "Alive",
      "Dead",
      "Dead despite first aid",
      "Unknown"
    ]
  }),
  computed: {
    otherspecies () {
      return true ? this.species === 'Other' : false
    }
  }
};
</script>