// Utilities
import { dispatch, make } from 'vuex-pathify'
import api from '../../services/SearchService'


const state = {
  query: '',
  indexes: [],
  total: 0,
  loading: false,
}

const mutations = {
    ...make.mutations(state),
}

// Used as a template
// const REPLACEME = async ({commit, dispatch, state, rootState}) => {
//     try {
//         commit('loading', true)

//     } catch (error) {
//         commit('error', error)
//         console.log(error)
//     } finally {
//         commit('loading', false)
//     }
// }

/**
 * Fetches all Indexes from backend, that reflect on the query string.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 */
const doSearch = async ({commit, rootState}, query) => {
    try {
        commit('loading', true)
        let response = await api.postSearch(query, rootState.newauth.token)
        commit('indexes', response.data)
    } catch (error) {
        commit('error', error)
        console.log(error)
    } finally {
        commit('loading', false)
    }
}

const actions = {
    doSearch
}

const getters = {
    getIndexes: state => state.indexes,
    getLoading: state => state.loading,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
