import axios from 'axios'

function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}


const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_V1,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})


export default {
    async getExports (token) {
        return apiClient.get('exp/', authHeaders(token))
    },
    async getExport (id, token) {
        return apiClient.get(`exp/e/${id}`, authHeaders(token))
    },
    async postCreateExport (payload, token) {   
        return apiClient.post(`exp/`, payload, authHeaders(token))
    },
    async delExport (id, token) {
        return apiClient.delete(`exp/e/${id}`, authHeaders(token))
    },
    async postUpdateExport (id, payload, token) {
        console.log('postUpdateExport - id:' + id)
        console.log('postUpdateExport - payload:' + payload)
        return apiClient.post(`exp/e/${id}`, payload, authHeaders(token))
    },
    async getExportPreview (id, token) {
        return apiClient.get(`exp/e/${id}/preview`, authHeaders(token))
    },
    async postPreview (payload, token) {
        return apiClient.post(`exp/p`, payload, authHeaders(token))
    },
    async getExportFile (id, token) {
        return apiClient.post(`exp/e/${id}/file`,
            {file_name: 'export.xlsx'},
            {responseType: 'blob',
            ...authHeaders(token)}
        )
    }
}