<template>
  <!-- <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <router-link :to="{name: 'Home' }">
        <v-img
          alt="Vuetify Logo"
          class=" mr-2"
          contain
          src="@/assets/logo_hos.jpg"
          transition="scale-transition"
          width="100"
        />
        </router-link> -->

        <!-- <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->


      <!-- </div>

      <v-spacer/>
      <router-link :to="{name: 'changelog'}">
        <span class="font-weight-light white--text">
          Version: {{ $store.getters.appVersion }}
        </span>
      </router-link>

      <v-spacer></v-spacer>

      <language-menu/>


      <v-btn
        href="https://winwo.io"
        target="_blank"
        text
      >
        <span class="mr-2">Winwo Labs</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main> -->
      <router-view/>
    <!-- </v-main>
  </v-app> -->
</template>

<script>
import LanguageMenu from './components/LanguageMenu.vue';

export default {
  components: { LanguageMenu },
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style lang='scss'>
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,700&display=swap');
// @import url('https://fonts.googleapis.com/css? family=Comfortaa&display=swap');

$body-font-family: 'Poppins';
$title-font: 'Poppins';

.v-application {
   font-family: $body-font-family, sans-serif !important;
    .title { // To pin point specific classes of some components
       font-family: $title-font, sans-serif !important;
    }
    .text-h5 {
      font-family: $title-font, sans-serif !important;
    }
    .a {
      font-family: $title-font, sans-serif !important;
    }
 }

</style>
