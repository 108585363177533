<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Notification of poison event</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <!-- START Date of notification  -->
        <v-menu
          v-model="notificationDayMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date of notification"
              v-model="notificationDay"
              readonly
              v-on="on"
              required
              outlined
              append-icon="mdi-calendar"
              :rules="required"
            />
          </template>
          <v-date-picker
            v-model="newData.notificationDay"
            :locale="locale"
            min="1980-01-01"
            max="2050-01-01"
            @change="setNotificationDay"
            @input="notificationDayMenu = false"
          ></v-date-picker>
        </v-menu>
        <!-- END date of notification -->

        <!-- START Date of event -->
        <v-menu
          v-model="eventDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date of event"
              v-model="eventDate"
              readonly
              v-on="on"
              required
              outlined
              append-icon="mdi-calendar"
              :rules="[checkDate(newData.notificationDay, newData.eventDate)]"
            />
          </template>
          <v-date-picker
            v-model="newData.eventDate"
            :locale="locale"
            min="1980-01-01"
            max="2050-01-01"
            @change="setEventDate"
            @input="eventDateMenu = false"
          ></v-date-picker>
        </v-menu>
        <!-- END date of event -->

        <v-textarea
          filled
          v-model="newData.eventDiscoveredWhile"
          label="Poison event discovered while"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        color="grey lighten-2"
        @click="reset"
      >Clear</v-btn>
      <v-btn
        :disabled="!valid || !isDirty"
        color="#7eb09f"
        @click="validate"
      >Save
        <v-progress-circular color="biscuit" v-if="loading" indeterminate/>      
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { sync, get } from 'vuex-pathify'
import Notification from '@/models/notificationinfo.js'
import { DateTime } from 'luxon'
import { formatDate } from '@/utils/general.js'

export default {
  props: {
    NotificationInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    passed: {
      type: Boolean,
      default: false,
    },
    entrycode: {
      type: Number
    }
  },
  data: function () {
    return {
      valid: false,
      loading: false,
      required: [
        v => !!v || 'Field is required'
      ],
      notificationDay: this.passed ? this.NotificationInfo.notificationDay : null,
      // formattedNotificationDay: this.passed ? this.NotificationInfo.notificationDay : null,
      // formattedEventDate: this.passed ? this.NotificationInfo.eventDate : null,
      eventDate: this.passed ? this.NotificationInfo.eventDate : "",
      //eventDiscoveredWhile: this.passed ? this.NotificationInfo.eventDiscoveredWhile : "",
      notificationDayMenu: false,
      eventDateMenu: false,
    };
  },
  methods: {
    validate () {
      this.loading = true
      if (this.$refs.form.validate()) {
        this.$store.dispatch('incidentsnew/updNotification',
          {entrycode: this.entrycode,
           payload: this.newData})
          .then(() => {
            this.loading = false
            this.initData = JSON.parse(JSON.stringify(this.newData))
          })
          .catch(e => {
            console.log('WE GOT THE ERROR')
            console.log(this.$store.state.incidentsnew.error)
          })
      } else {
        this.loading = false
      }
    },
    checkDate(notification_date, event_date) {
      if (!notification_date && !event_date) {
        return true
      }
      let not_date = DateTime.fromISO(notification_date)
      let evt_date = DateTime.fromISO(event_date)

      return (evt_date > not_date) ? 'Event date cannot be after notification date' : true
    },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    setNotificationDay (evt) {
      this.notificationDay = formatDate(evt)
    },
    setEventDate (evt) {
      this.eventDate = formatDate(evt)
    },
  },
  created () {
    // this.notificationDay = formatDate(this.notificationDay)
    // this.eventDate = formatDate(this.eventDate)
  },
  mounted () {
    if (this.passed) {
      // console.log(this.NotificationInfo)
      this.initData = {...this.NotificationInfo}
      this.newData =  JSON.parse(JSON.stringify(this.NotificationInfo))
      let foovent = this.NotificationInfo.eventDate
      let fooday = this.NotificationInfo.notificationDay
      

      // format dates to make sure we are compatible
      if (this.newData.eventDate) {      
        this.newData.eventDate = DateTime.fromFormat(this.newData.eventDate, 'dd/MM/yyyy').toISODate()
        this.initData.eventDate = DateTime.fromFormat(this.initData.eventDate, 'dd/MM/yyyy').toISODate()
        // this.eventDate = formatDate(this.newData.eventDate)
        // this.eventDate = formatDate(foovent)
      }
      if (this.newData.notificationDay !== "") {
        this.newData.notificationDay = DateTime.fromFormat(this.newData.notificationDay, 'dd/MM/yyyy').toISODate()
        this.initData.notificationDay = DateTime.fromFormat(this.initData.notificationDay, 'dd/MM/yyyy').toISODate()
        // this.notificationDay = formatDate(this.newData.notificationDay)
        // this.notificationDay = formatDate(fooday)
      }
      this.$refs.form.resetValidation()

      // format the dates
      // this.notificationDay = formatDate(this.notificationDay)
      // this.eventDate = formatDate(this.eventDate)
    }
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...get('form', [
      'isDirty'
    ]),
    locale () {
      return this.$i18n.locale
    }
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>