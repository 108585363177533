/**
 * @module store/modules/newauth
 * 
 * Implements the authentication mechanics with the backend.
 */

// Utilities
import { commit, dispatch, make } from 'vuex-pathify'
import { api } from '@/services/AuthService.js'
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils/general'
import router from '@/router'

const state = {
  token: "",
  isLoggedIn: false,
  logInError: false,
  userProfile: null,
  timestamp: null,
  logInErrorMsg: "",
  loading: false,
  user: null
}

const mutations = make.mutations(state)
/**
 * Actions specified here
 */


/**
 * After successful login we clear out any parameter
 * in the store might have been used to communicate
 * a failed login attempt.
 * @param {Object} {commit} - vuex commit 
 */
const resetLoginState = async({commit}) => {
  commit('logInError', false)
  commit('logInErrorMsg', '')
}

/**
 * Calls all the actions after the login operation.
 * 
 * - Calls for the user profile
 * - Sets a notification object
 * - Redirects to a new route
 * @param {Object} {dispatch,commit} - vuex dispatch,commit
 */
const afterLogin = async ({dispatch, commit}) => {
  // Call for the user profile
  await dispatch('actionGetUserProfile')

  // Set a notification object
  commit('notifications/notification', {
    content: 'Logged In',
    color: 'success'
  }, {root:true})

  // Redirect to new route
  await dispatch('actionRouteLoggedIn')
}

/**
 * Retrieves the user profile for the current user.
 * 
 * This api call is performed based on the state.token
 * @param {Object} {state,commit,dispatch)} - vuex options
 */
const actionGetUserProfile = async ({ state, commit, dispatch }) => {
  let response;
  try {
    response = await api.getMe(state.token)
    if (response.status == 200) {
      commit('userProfile', response.data)
    }
  } catch (error) {
    response = error.response
    await dispatch('actionCheckApiError', response)
  }
}

/**
 * Checks the error of the response and performs proper action
 * if required. 
 * 
 * E.g. if we have a 401, then we force a logout
 * @param {Object} {dispatch} - vuex dispatch 
 * @param {Object} payload - error payload
 */
const actionCheckApiError = async ({ dispatch }, payload) => {
  if (payload.status === 401) {
    await dispatch('actionLogOut')
  }
}

/**
 * Wraps actions required for performing a logout
 * 
 * - Removes the login information
 * - Forces a redirect to the login page
 * @param {Object} {dispatch} - vuex dispatch
 */
const actionLogOut = async ({ dispatch }) => {
  await dispatch('actionRemoveLogIn')
  await dispatch('actionRouteLogOut')
}

/**
 * Resets all the login related state information
 * @param {Object} {commit} - vuex commit
 */
const actionRemoveLogIn = async ({ commit }) => {
  removeLocalToken()
  commit('token', '')
  commit('isLoggedIn', false)
  commit('logInError', false)
  commit('userProfile', null)
  commit('timestamp', null)
}

/**
 * Checks if the `router.currentRoute.path` is not `login`
 * page and then pushes to `router.push('/')`
 */
const actionRouteLogOut = () => {
  if (router.currentRoute.path !== '/login') {
    router.push('/')
  }
}

/**
 * Checks if `isLoggedIn` is true and attempts to retrieve the
 * token from localStorage.
 * 
 * Then attempts to reinstate a logged in state.
 * 
 * If not we perform the logout action.
 * @param {Object} {state,commit,dispatch} - vuex methods
 */
const actionCheckLoggedIn = async ({ state, commit, dispatch}) => {
  if (!state.isLoggedIn) {
    let token = state.token;
    let response;
    if (!token) {
      const localToken = getLocalToken();
      if (localToken) {
        commit('token', localToken);
        token = localToken;
      }
    }
    if (token) {
      try {
        await dispatch('actionGetUserProfile')
        commit('isLoggedIn', true)
      } catch (error) {
        await dispatch('actionLogOut');
      }
    } else {
      await dispatch('actionLogOut');
    }
  }
}

/**
 * Checks if the `router.currentRoute.path` is `login` or `/` and
 * then pushes to the `router.push('/dashboard')
 */
const actionRouteLoggedIn = async () => {
  if (router.currentRoute === '/login' || router.currentRoute.path === '/') {
    router.push('/dashboard')
  }
}

/**
 * Performs all actions related to a new udpated token.
 * 
 * - updates token state
 * - saves token in LocalStorage
 * - sets `isLoggedIn` to true
 * - updates timestamp values
 * - calls `resetLoginState`
 * 
 * @param {Obejct} {commit} - vuex methods
 * @param {string} token - access token
 *
 */
const actionSetToken = async ({commit, dispatch}, token) => {
  commit('token', token)
  saveLocalToken(token)
  commit('isLoggedIn', true)

  // Set the timestamp to be ready for checking for refresh
  //    We transform the Date from milliseconds to secods and we add 
  //    a couple seconds 2800 (not in 3600). 
  let _timestamp = (Date.now() / 1000) + 2800
  // let _timestamp = (Date.now() / 1000) + 20
  commit('timestamp', _timestamp)
  dispatch('resetLoginState')
}

/**
 * Refreshes access token.
 * 
 * Makes a call to the refresh endpoint and on success
 * updates store and localstorage with the token value.
 * 
 * On error, we force a logout
 * 
 * @param {Object} {commit,state,dispatch} - vuex methods
 */
const refreshToken = async ({ commit, state, dispatch }) => {
  let response;
  try {
    response = await api.refresh(state.token)
    const token = response.data.access_token
    if (token) {
      dispatch('actionSetToken', token)
    } else {
      await dispatch('actionLogOut')
    }
  } catch (err) {
    // FIXME: maybe set a notification here of expired token?
    await dispatch('actionLogOut')
  }
}

const actions = {
  
  async logIn({commit, dispatch}, user) {
    // edge case when doing login after failed attempt
    await dispatch('resetLoginState')
    commit('loading', true)
    let response;
    try {
      response = await api.logInGetToken(user.username, user.password)
    } catch (err) {
      response = err.response
      commit('logInError', true)
      commit('logInErrorMsg', response.data.detail)
      commit('loading', false)
      return false
    }
    // Perform success login actions
    commit('loading', false)
    commit('token', response.data.access_token)
    saveLocalToken(response.data.access_token)
    commit('isLoggedIn', true)

    // set the timestamp used for refresh
    let timestamp = (Date.now() / 1000) + 2800
    commit('timestamp', timestamp)
    dispatch('resetLoginState')
    
    // Now perform post login actions
    await dispatch('afterLogin')
  },
  // async actionLogIn({ commit, dispatch }, user) {
  //   try {
  //     const response = await api.logInGetToken(user.username, user.password)
  //     const token = response.data.access_token
  //     if (token) {
  //       saveLocalToken(token)
  //       commit('token', token)
  //       commit('isLoggedIn', true)
  //       commit('logInError', false)
  //       await dispatch('actionGetUserProfile')
  //       await dispatch('actionRouteLoggedIn')
  //       commit('notifications/notification', { content: 'Logged In', color: 'success' }, {root: true})

  //       // Set the timestamp to be ready for checking for refresh
  //       //    We transform the Date from milliseconds to secods and we add 
  //       //    a couple seconds 2800 (not in 3600). 
  //       let _timestamp = (Date.now() / 1000) + 2800
  //       // let _timestamp = (Date.now() / 1000) + 20
  //       commit('timestamp', _timestamp)
  //     } else {
  //       await dispatch('actionLogOut')
  //     }
  //   } catch (err) {
  //     console.log('*' * 100)
  //     console.log(err)
  //     commit('logInError', true)
  //     await dispatch('actionLogOut')
  //   }
  // },
  // async actionGetUserProfile({ state, commit, dispatch }) {
  //   try {
  //     const response = await api.getMe(state.token)
  //     if (response.data) {
  //       commit('userProfile', response.data)
  //     }
  //   } catch (error) {
  //     await dispatch('actionCheckApiError')
  //   }
  // },
  // async actionCheckLoggedIn({ state, commit, dispatch}) {
  //   if (!state.isLoggedIn) {
  //     let token = state.token;
  //     if (!token) {
  //       const localToken = getLocalToken();
  //       if (localToken) {
  //         commit('token', localToken);
  //         token = localToken;
  //       }
  //     }
  //     if (token) {
  //       try {
  //         const response = await api.getMe(token);
  //         commit('isLoggedIn', true);
  //         commit('userProfile', response.data);
  //       } catch (error) {
  //         await dispatch('actionRemoveLogIn');
  //       }
  //     } else {
  //       await dispatch('actionRemoveLogIn');
  //     }
  //   }
  // },
  // actionRouteLoggedIn() {
  //   if (router.currentRoute === '/login' || router.currentRoute.path === '/') {
  //     router.push('/dashboard')
  //   }
  // },
  // async actionRemoveLogIn({ commit }) {
  //   removeLocalToken()
  //   commit('token', '')
  //   commit('isLoggedIn', false)
  //   commit('logInError', false)
  //   commit('userProfile', null)
  //   commit('timestamp', null)
  // },
  // async actionLogOut({ dispatch }) {
  //   await dispatch('actionRemoveLogIn')
  //   await dispatch('actionRouteLogOut')
  // },
  // actionRouteLogOut() {
  //   if (router.currentRoute.path !== '/login') {
  //     // router.push('/login')
  //     router.push('/')
  //   }
  // },
  // async refreshToken({ commit, state, dispatch }) {
  //   try {
  //     const response = await api.refresh(state.token)
  //     const token = response.data.access_token
  //     console.log('---------------')
  //     if (token) {
  //       saveLocalToken(token)
  //       commit('token', token)
  //       commit('isLoggedIn', true)
  //       commit('logInError', false)

  //       // Set the timestamp to be ready for checking for refresh
  //       //    We transform the Date from milliseconds to secods and we add 
  //       //    a couple seconds 2800 (not in 3600). 
  //       let _timestamp = (Date.now() / 1000) + 2800
  //       // let _timestamp = (Date.now() / 1000) + 20
  //       commit('timestamp', _timestamp)
  //     } else {
  //       console.log('werwrwerwerwer')
  //       await dispatch('actionLogOut')
  //     }
  //   } catch (err) {
  //     console.log('eimaste sto catch')
  //     console.log(err)
  //     commit('logInError', true)
  //     await dispatch('actionLogOut')
  //   }
  // },
  // async actionCheckApiError({ dispatch }, payload) {
  //   if (payload.response.status === 401) {
  //     await dispatch('actionLogOut')
  //   }
  // },
  async passwordRecovery({ commit, dispatch }, email) {
    const loadingNotification = { content: 'Sending password recovery email', showProgress: true };
    commit('notifications/notification', { content: 'Logged In', color: 'success' }, {root: true})
    try {
        commit('notifications/notification', loadingNotification, {root: true});
        const response = (await Promise.all([
            api.passwordRecovery(email),
            await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ]))[0];
        // commitRemoveNotification(context, loadingNotification);  // FIXME: remove notification
        commit('notifications/notification', { content: 'Password recovery email sent', color: 'success' }, {root: true});
        await dispatch('actionLogOut')
    } catch (error) {
        // commitRemoveNotification(context, loadingNotification);  // FIXME: remove notification
        commit('notifications/notification', { color: 'error', content: 'Incorrect username' }, {root: true});
    }
  },
  async resetPassword({ commit, dispatch }, { password, token }) {
    const loadingNotification = { content: 'Resetting password', showProgress: true };
    try {
      commit('notifications/notification', loadingNotification, {root: true});
      const response = (await Promise.all([
            api.resetPassword(password, token),
            await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ]))[0];
        // commitRemoveNotification(context, loadingNotification);  // FIXME: remove notification
        commit('notifications/notification', { content: 'Password successfully reset', color: 'success' }, {root: true});
        await dispatch('actionLogOut')
    } catch (error) {
        // commitRemoveNotification(context, loadingNotification);  // FIXME: remove notification
        commit('notifications/notification', { color: 'error', content: 'Error resetting password' }, {root: true});
    }
  },
  resetLoginState,
  afterLogin,
  actionGetUserProfile,
  actionCheckApiError,
  actionLogOut,
  actionRemoveLogIn,
  actionRouteLogOut,
  actionCheckLoggedIn,
  actionRouteLoggedIn,
  actionRouteLoggedIn,
  actionSetToken,
  refreshToken
}

const getters = {
  loading: state => state.loading,
  loggedIn: state => state.isLoggedIn,
  logInError: state => state.logInError,
  errormsg: state => state.logInErrorMsg
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
