var AddFormPage = {
    data: function () {
        return {
            adder: [],
            numberRule: [
                v => (this.valTotal(v)) || 'Input cannot exceed total number'
            ],
            maintotal: 0
        }
    },
    methods: {
        subformNumber(e) {
            console.log('subformNumber')
            let number = Number(e || 0)
            let total = 0
            this.adder.forEach(noform => {
                total += Number(noform.number || 0)
            })
            if (number > 0 && this.maintotal > total) {
                this.adder.push({})
            }
        },
        valTotal(v) {
            let total = 0
            this.adder.forEach(noform => {
                total += Number(noform.number || 0)
            })
            if (total > this.maintotal)
                return false
            else return true
        },
        totalNumber(e) {
            console.log(e)
            // const numberAnimals = Number(e || 0)
            // this.adder = [...Array(numberAnimals).keys()].map(i => this.adder[i] || {})
            // console.log(this.adder)

            // Add the main number in the main
            this.maintotal = Number(e || 0)

            // push one creation
            const one = 1
            this.adder = [...Array(one).keys()].map(i => this.adder[i] || {})
            console.log(this.adder)
        }
    }
}

export default AddFormPage