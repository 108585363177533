// Utilities
import { dispatch, make } from 'vuex-pathify'
import ExportFileService from '../../services/ExportFileService'


const state = {
  error: {},
  loading: false,
}

const mutations = make.mutations(state)

const actions = {
    async exportIncidents ({commit, rootState}) {
        try {
            commit('loading', true)
            let resp = await ExportFileService.exportCountry(rootState.newauth.token)
            commit('loading', false)
            return resp
        } catch (error) {
            commit('loading', false)
            console.log('Error exporting incidents from server')
            commit('error', error)  // FIXME: check if throw e is needed
        }
    },
    async exportAllIncidents ({commit, rootState}) {
        try {
            commit('loading', true)
            let resp = await ExportFileService.exportAll(rootState.newauth.token)
            commit('loading', false)
        } catch (error) {
            commit('loading', false)
            console.log('Error exporting the data')
            commit('error', error)  // FIXME: check if throw e is needed
        }
    },
    async exportSelection ({commit, rootState}, selection) {
        try {
            commit('loading', true)
            let resp = await ExportFileService.exportSelection(selection, rootState.newauth.token)
        } catch (error) {
            commit('error', error)
        } finally {
            commit('loading', false)
        }
    }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
