import axios from 'axios'

function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}


const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_V1,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})


export default {
    async getActivities (token) {
        return apiClient.get('/l', authHeaders(token))
    },
    async getActivitiesPaginated (skip, limit, token) {
        return apiClient.get(`/l/p?offset=${skip}&limit=${limit}`, authHeaders(token))
    },
    async getTotal (token) {
        return apiClient.get('/l/total', authHeaders(token))
    },
    async getUserTotal (token) {
        return apiClient.get('/l/user/total', authHeaders(token))
    },
    async getUserActivities (token) {
        return apiClient.get(`/l/user`, authHeaders(token))
    },
    async getUserActivitiesPaginated (skip, limit, token) {
        return apiClient.get(`/l/user/p?offset=${skip}&limit=${limit}`, authHeaders(token))
    },
}