// Utilities
import { make } from 'vuex-pathify'

const state = {
    snackbar: false,
    message: "Hello World",
    color: "success",
    timeout: 5000
}

const mutations = make.mutations(state)

const showSnackbar = ({commit, state}) => {
    state.snackbar = true
    setTimeout(() => {state.snackbar=false}, state.timeout)
}

const setMessage = ({commit, state}, payload) => {
    commit('message', payload.message)
    commit('color', payload.color)
}

const showMessage = ({dispatch}, payload) => {
    dispatch('setMessage', payload)
    dispatch('showSnackbar')
    console.log('message:' + payload.message)
}

const actions = {
    showSnackbar,
    setMessage,
    showMessage
}

const getters = {
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
