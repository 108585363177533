<template>
  <v-card>
    <v-card-title> SampleCollectedInfoPage </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
          v-model="sampleEvidenceCode"
          label="Sample/Evidence Code"
        />
        <v-text-field v-model="storedIn" label="Stored in" />
        <v-select
          v-model="collectedBy"
          label="CollectedBy"
          :items="CollectedBy"
        />
        <v-text-field v-model="collectedOther" label="Specify who if other"
          v-if="collectOther" />
        <v-select 
          v-model="storedBy" 
          label="Stored by" 
          :items="CollectedBy" 
        />
        <v-text-field v-model="storedOther" label="Specify who if other" v-if="storeOther" />
        <v-text-field v-model="storingPlace" label="Storing Place" />
        <v-select 
          v-model="sentTo" 
          label="Sent To" 
          :items="SentTo" 
        />

        <v-text-field v-model="sentToOther" label="Specify where if other" v-if="sentOther" />

        <v-menu 
          v-model="dateSentMenu" 
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date Sent"
              v-model="dateSent"
              readonly
              :value="parsedInput"
              v-on="on"
            required/>
          </template>
          <v-date-picker
            v-model="dateSent"
            locale="el-EL"
            min="1980-01-01"
            max="2050-01-01"
            @input="dateSentMenu = false"
          ></v-date-picker>
        </v-menu>
        
        
        <v-text-field v-model="results" label="Results" />

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    dateSentMenu: false,
    sampleEvidenceCode: "",
    storedIn: "",
    collectedBy: "null",
    collectedOther: "",
    storedBy: "null",
    storedOther: "",
    storingPlace: "",
    sentTo: "null",
    sentToOther: "",
    dateSent: "",
    results: "",
    CollectedBy: ["Authority", "NGO", "Other", "Unknown"],
    SentTo: ["Toxicological lab", "Police CSI", "Other", "Unknown"],
  }),
  computed: {
    collectOther() {
      return true ? this.collectedBy === "Other" : false;
    },
    storeOther() {
      return true ? this.storedBy === "Other" : false;
    },
    sentOther() {
      return true ? this.sentTo === "Other": false;
    },
    parsedInput () {
      if (this.dateSent) {
        let splitdate = this.dateSent.split('-')
        return '' + splitdate[2] + '/' + splitdate[1] + '/' + splitdate[0]
      }
      return ''
    }
  },
};
</script>