// Utilities
import { dispatch, make } from 'vuex-pathify'
import api from '../../services/UnDeleteService'


const state = {
  undos: [],
  total: 0,
  loading: false,
}

const mutations = {
    ...make.mutations(state),
    DEL_UNDO(state, id) {
        let i = state.undos.map(item => item.id).indexOf(id)
        state.undos.splice(i, 1)
    }
}

// Used as a template
// const REPLACEME = async ({commit, dispatch, state, rootState}) => {
//     try {
//         commit('loading', true)

//     } catch (error) {
//         commit('error', error)
//         console.log(error)
//     } finally {
//         commit('loading', false)
//     }
// }

/**
 * Fetches all UnDelete from backend.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 */
const fetchUnDelete = async ({commit, rootState}) => {
    try {
        commit('loading', true)
        let response = await api.getUnDelete(rootState.newauth.token)
        commit('undos', response.data)
    } catch (error) {
        commit('error', error)
        console.log(error)
    } finally {
        commit('loading', false)
    }
}


/**
 * Deletes UnDelete with given id.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {int} id - UnDelete id
 */
const delUnDelete = async ({commit, dispatch, rootState}, id) => {
    try {
        commit('loading', true)
        let response = await api.delUnDelete(id, rootState.newauth.token)
        commit('DEL_UNDO', id)
        dispatch('messages/showMessage',
                 {message: `UnDelete configuration deleted`, color: 'success'},
                 {root: true})
    } catch (error) {
        commit('error', error)
        dispatch('messages/showMessage',
                {message: `Error when deleting UnDelete configuration`, color: 'error'},
                {root: true})
    } finally {
        commit('loading', false)
    }
}


/**
 * Performs the restore operation on UnDelete with given id.
 * 
 * @param {Object} param0 - vuex methods
 * @param {int} id - UnDelete id
 */
const postUndo = async ({commit, dispatch, rootState}, id) => {
    try {
        commit('loading', true)
        let response = await api.postUnDelete(id, rootState.newauth.token)
        dispatch('messages/showMessage',
                {message: `Restored Incident with entrycode: ${response.data.entrycode}.`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', error)
        dispatch('messages/showMessage',
                {message: `Error when restoring UnDelete configuration with id: ${id}`, color: 'error'},
                {root: true})
    } finally {
        commit('loading', false)
    }
}

const actions = {
    fetchUnDelete,
    delUnDelete,
    postUndo
}

const getters = {
    getUndos: state => state.undos,
    getLoading: state => state.loading,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
