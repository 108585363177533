// Utilities
import { make } from 'vuex-pathify'


const state = {
  dirty: false,
  initData: {},
  newData: {}
}

const mutations = make.mutations(state)

const actions = {
  // fetch: ({ commit }) => {
  //   const local = localStorage.getItem('vuetify@user') || '{}'
  //   const user = JSON.parse(local)

  //   for (const key in user) {
  //     commit(key, user[key])
  //   }

  //   if (user.dark === undefined) {
  //     commit('dark', window.matchMedia('(prefers-color-scheme: dark)'))
  //   }
  // },
  // update: ({ state }) => {
  //   if (!IN_BROWSER) return

  //   localStorage.setItem('vuetify@user', JSON.stringify(state))
  // },
}

// TAKEN FROM: https://stackoverflow.com/a/32922084
function deepEqual(x, y) {
  return (x && y && typeof x === 'object' && typeof y === 'object') ?
    (Object.keys(x).length === Object.keys(y).length) &&
      Object.keys(x).reduce(function(isEqual, key) {
        return isEqual && deepEqual(x[key], y[key]);
      }, true) : (x === y);
}

const getters = {
  // dark: (state, getters) => {
  //   return (
  //     state.dark ||
  //     getters.gradient.indexOf('255, 255, 255') === -1
  //   )
  // },
  // gradient: state => {
  //   return state.gradients[state.drawer.gradient]
  // },
  // image: state => {
  //   return state.drawer.image === '' ? state.drawer.image : state.images[state.drawer.image]
  // },
  isDirty: (state) => {
    // if initData is empty, then any change of initData makes things dirty!
    if (Object.keys(state.initData).length === 0) {
      if (Object.keys(state.newData).length === 0)
        return false
      else 
        return true
    }
    // if we have initial data, dirty is if initData != newData
    if (Object.keys(state.initData).length > 0) {
      //return !(JSON.stringify(state.initData) === JSON.stringify(state.newData))
      return !deepEqual(state.initData, state.newData)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
