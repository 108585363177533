// Utilities
import { dispatch, make } from 'vuex-pathify'
import api from '../../services/ActivityService'


const state = {
  activities: [],
  userActivities: [],
  total: 0,
  usertotal: 0,
  loading: false,
}

const mutations = {
    ...make.mutations(state),
}

// Used as a template
// const REPLACEME = async ({commit, dispatch, state, rootState}) => {
//     try {
//         commit('loading', true)

//     } catch (error) {
//         commit('error', error)
//         console.log(error)
//     } finally {
//         commit('loading', false)
//     }
// }

/**
 * Fetches all Activity objects from backend.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 */
const fetchActivities = async ({commit, rootState}) => {
    try {
        commit('loading', true)
        let response = await api.getActivities(rootState.newauth.token)
        commit('activities', response.data)
    } catch (error) {
        commit('error', error)
        console.log(error)
    } finally {
        commit('loading', false)
    }
}

/**
 * Fetch the total `Activity` objects for admin.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 */
const fetchTotal = async ({commit, rootState}) => {
    try {
        commit('loading', true)
        let response = await api.getTotal(rootState.newauth.token)
        commit('total', response.data)
    } catch (error) {
        commit('error', error)
        console.log(error)
    } finally {
        commit('loading', false)
    }
}


/**
 * Fetch the total `Activity` objects for user.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 */
const fetchUserTotal = async ({commit, rootState}) => {
    try {
        commit('loading', true)
        let response = await api.getUserTotal(rootState.newauth.token)
        commit('usertotal', response.data)
    } catch (error) {
        commit('error', error)
        console.log(error)
    } finally {
        commit('loading', false)
    }
}

/**
 * Fetches the `Activity` objects for the current user.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 */
const fetchUserActivities = async ({commit, rootState}) => {
    try {
        commit('loading', true)
        let response = await api.getUserActivities(rootState.newauth.token)
        commit('userActivities', response.data)
    } catch (error) {
        commit('error', error)
        console.log(error)
    } finally {
        commit('loading', false)
    }
}

/**
 * Wraps multiple calls for easy fetching of data
 * and calls appropriate methods if user is an admin
 * or not.
 * 
 * @param {Object} param0 - vuex methods
 * @param {boolean} admin - is admin or not
 */
const fetchActs = async ({dispatch}, admin) => {
    if (admin === true) {
        dispatch('fetchActivities')
        dispatch('fetchTotal')
    } else {
        dispatch('fetchUserActivities')
        dispatch('fetchUserTotal')
    }
}

const actions = {
    fetchActivities,
    fetchUserActivities,
    fetchTotal,
    fetchUserTotal,
    fetchActs
}

const getters = {
    getActivities: state => state.activities,
    getUserActivities: state => state.userActivities,
    getLoading: state => state.loading,
    getError: state => state.error,
    getTotal: state => state.total,
    getUserTotal: state => state.usertotal
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
