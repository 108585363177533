export default class AuthoritiesPage {
    constructor(number=0, agency, timeNotified, presentAtManagement, reason, nameSurnameContact, position, phone, email) {
        this.number = number;
        this.agency = agency;
        this.timeNotified = timeNotified;
        this.presentAtManagement = presentAtManagement;
        this.reason = reason;
        this.nameSurnameContact = nameSurnameContact;
        this.position = position;
        this.phone = phone;
        this.email = email;
    }
}