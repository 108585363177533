// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: false,
  mini: false,
  // items: [
  //   {
  //     title: 'Dashboard',
  //     icon: 'mdi-view-dashboard',
  //     to: '/',
  //   },
  //   {
  //     title: 'Pages',
  //     icon: 'mdi-image',
  //     items: [
  //       {
  //         title: 'Pricing',
  //         to: '/pages/pricing/',
  //       },
  //       {
  //         title: 'RTL Support',
  //         to: '/pages/rtl/',
  //       },
  //       {
  //         title: 'Timeline',
  //         to: '/components/timeline/',
  //       },
  //       {
  //         title: 'Login Page',
  //         to: '/pages/login/',
  //       },
  //       {
  //         title: 'Register Page',
  //         to: '/pages/register/',
  //       },
  //       {
  //         title: 'Lock Screen Page',
  //         to: '/pages/lock/',
  //       },
  //       {
  //         title: 'User Profile',
  //         to: '/components/profile/',
  //       },
  //       {
  //         title: 'Error Page',
  //         to: '/pages/error/',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Components',
  //     icon: 'mdi-view-comfy',
  //     items: [
  //       {
  //         title: 'Multi Level Collapse',
  //         items: [{
  //           title: 'Example',
  //         }],
  //       },
  //       {
  //         title: 'Buttons',
  //         to: '/components/buttons/',
  //       },
  //       {
  //         title: 'Grid System',
  //         to: '/components/grid/',
  //       },
  //       {
  //         title: 'Tabs',
  //         to: '/components/tabs/',
  //       },
  //       {
  //         title: 'Notifications',
  //         to: '/components/notifications/',
  //       },
  //       {
  //         title: 'Icons',
  //         to: '/components/icons/',
  //       },
  //       {
  //         title: 'Typography',
  //         to: '/components/typography/',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Forms',
  //     icon: 'mdi-clipboard-outline',
  //     items: [
  //       {
  //         title: 'Regular Forms',
  //         to: '/forms/regular/',
  //       },
  //       {
  //         title: 'Extended Forms',
  //         to: '/forms/extended/',
  //       },
  //       {
  //         title: 'Valiation Forms',
  //         to: '/forms/validation/',
  //       },
  //       {
  //         title: 'Wizard',
  //         to: '/forms/wizard/',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Tables',
  //     icon: 'mdi-grid',
  //     items: [
  //       {
  //         title: 'Regular Tables',
  //         to: '/tables/regular/',
  //       },
  //       {
  //         title: 'Extended Tables',
  //         to: '/tables/extended/',
  //       },
  //       {
  //         title: 'DataTables Tables',
  //         to: '/tables/data-tables/',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Maps',
  //     icon: 'mdi-map-marker',
  //     items: [
  //       {
  //         title: 'Google Maps',
  //         to: '/maps/google/',
  //       },
  //       {
  //         title: 'Fullscreen Map',
  //         to: '/maps/fullscreen/',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Widgets',
  //     icon: 'mdi-widgets',
  //     to: '/widgets/',
  //   },
  //   {
  //     title: 'Charts',
  //     icon: 'mdi-chart-timeline-variant',
  //     to: '/charts/',
  //   },
  //   {
  //     title: 'Calendar',
  //     icon: 'mdi-calendar-range-outline',
  //     to: '/calendar/',
  //   },
  // ],
  items: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
    {
      title: 'Find Incident',
      icon: 'mdi-magnify',
      to: '/'
    },
    {
      title: 'Add Incident',
      icon: 'mdi-plus',
      to: '/addincident/informant'
    },
    {
      title: 'Reports',
      icon: 'mdi-alert-circle-outline',
      to: '/reports'
    },
    {
      title: 'Pages',
      icon: 'mdi-image',
      to: '/'
    },
    {
      title: 'Users',
      icon: 'mdi-account-multiple',
      to: '/'
    }
  ],
  viewcol: 12,  // used to control the dashboard/View column width
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
