/**
 * @module store/modules/incidents
 * 
 * Implements the store functions for the incidents.
 */
import { commit, dispatch, make } from 'vuex-pathify'
// import { IncidentService as api } from '@/services/IncidentService.js'
import api from '@/services/IncidentService.js'
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils/general'
import router from '@/router'
import { formatDate } from '@/utils/general.js'

const state = {
    indexes: [],
    loading: false,
    incident: {},
    incidents: [],
    error: {},
    lastmodified: [],
    lastmodifiedbyuser: [],
    firstget: true,
    lastcheck: 0,
    total: 0,
    reload: null
}

const mutations = {
    ...make.mutations(state),
    SET_INCIDENT_ID(state, incident) {
        // FIXME: check if we need to place the incident in the right position
        // in the array
        state.incident = incident.data
        state.incidents.push(incident.data)
    },
    SET_INCIDENT_ENTRYCODE(state, incident) {
        state.incident = incident.data
        const indx = state.incidents.findIndex(v => v.entrycode === incident.data.entrycode)
        state.incidents.splice(indx, indx >= 0 ? 1 : 0)
        state.incidents.push(incident.data)
    },
    DEL_INCIDENT(state, entrycode) {
        let i = state.indexes.map(item => item.entrycode).indexOf(entrycode)
        state.indexes.splice(i, 1)
        
        // clear incident
        state.incident = {}

        // remove incident from incidents
        let ii = state.incidents.map(item => item.entrycode).indexOf(entrycode)
        state.incidents.splice(ii, 1)
    },
    SET_LIVE(state, {entrycode, live}){
        state.incident.live = live
    },
    SET_INFORMANT(state, payload) {
        state.incident.data.InformantDetails = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_NOTIFICATION(state, payload) {
        state.incident.data.NotificationInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_EVENTLOCATION(state, payload) {
        state.incident.data.PoisonLocationInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_DANIMAL(state, payload) {
        state.incident.data.DeadAnimalInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_ALANIMAL(state, payload) {
        state.incident.data.AliveAnimalInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_POISONBAIT(state, payload) {
        state.incident.data.PoisonBaitInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_EVIDENCE(state, payload) {
        state.incident.data.EvidenceInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_MOTIVE(state, payload) {
        state.incident.data.Motive = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_AUTHROTIES(state, payload) {
        state.incident.data.AuthoritiesManageOfEvent = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_ANTIPOISONDOG(state, payload) {
        state.incident.data.AntipoisonDogUnit = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_SAMPLES(state, payload) {
        state.incident.data.SampleCollectedInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_DISPOSAL(state, payload) {
        state.incident.data.DisposalOfDeadAnimals = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_OTHERREASONS(state, payload) {
        state.incident.data.OtherPossibleDeathReasons = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_COMPLAINT(state, payload) {
        state.incident.data.OfficialComplaintInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_AUTOPSY(state, payload) {
        state.incident.data.AutopsyToxicolAnalInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_TOXINS(state, payload) {
        state.incident.data.ToxinsUsed = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_GCOMMENT(state, payload) {
        state.incident.data.GeneralComments = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    SET_PUBLIC(state, payload) {
        state.incident.data.ExtraPublicInfo = payload
        const indx = state.incidents.findIndex(v => v.entrycode === state.incident.entrycode)
        if (indx >= 0) {
            state.incidents[indx] = state.incident
        }
    },
    DO_RELOAD(state, payload) {
        state.reload = new Date().getTime()
    }
}


/**
 * Checks with the backend if we have an update.
 * 
 * @param {Object} {commit,dispatch,state,rootState} - vuex methods
 */
const chkLastUpdateOrig = async ({commit, dispatch, state, rootState}) => {
    try {
        commit('loading', true)
        if (state.firstget == true) {
            await dispatch('fetchIndexes')
            commit('firstget', false)
            commit('lastcheck', Date.now())
        } else {
            const payload = {
                'timestamp': state.lastcheck
            }
            let response = await api.chkLastUpdate(payload, rootState.newauth.token)

            if (response.data == true) {
                dispatch('fetchIndexes')
                commit('lastcheck', Date.now())
            } else {
                // pass
                console.log('there is no update')
            }
        }   
    } catch (error) {
        commit('error', error)
        console.log(error)
    } finally {
        commit('loading', false)
    }
}


/**
 * Checks with the backend if we have an update.
 * 
 * @param {Object} {commit,dispatch,state,rootState} - vuex methods
 */
const chkLastUpdate = async ({commit, dispatch, state, rootState}) => {
    try {
        commit('loading', true)
        if (state.firstget == true) {
            await dispatch('fetchIndexes')
            await dispatch('fetchTotal')
            commit('firstget', false)
            commit('lastcheck', Date.now())
        } else {
            const payload = {
                'timestamp': state.lastcheck,
                'total': state.total
            }
            let response = await api.chkLastUpdate(payload, rootState.newauth.token)

            if (response.data == true) {
                dispatch('fetchIndexes')
                dispatch('fetchTotal')
                commit('lastcheck', Date.now())
            } else {
                // pass
                console.log('there is no update')
            }
        }   
    } catch (error) {
        commit('error', error)
        console.log(error)
    } finally {
        commit('loading', false)
    }
}

/**
 * Fetches indexes from api.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 */
const fetchIndexes = async ({ commit, dispatch, rootState }) => {
    try {
        commit('loading', true)
        let indexes = await api.getIncidentsIndex(rootState.newauth.token)
        commit('indexes', indexes.data)
    } catch (error) {
        // FIXME: parse the error in a meaningful way.
        commit('error', error)
        // FIXME: push to login again, since we are not authenticated any more
        await dispatch('newauth/actionLogOut', null, { root: true })
    } finally {
        commit('loading', false)
    }
}

/**
 * Fetches Incident by `id`.
 * 
 * @param {Object} {commit,rootState} - vuex methods 
 * @param {int} id - `id` of incident to retrieve
 */
const fetchIncidentById = async ({state, commit, rootState}, id) => {
    try {
        commit('loading', true)
        let incident = await api.getIncidentById(id, rootState.newauth.token)
        commit('SET_INCIDENT_ID', incident)
    } catch (error) {
        // FIXME: parse the error in a meaningful way.
        commit('error', error)
        // FIXME: push to login again, since we are not authenticated any more
        await dispatch('newauth/actionLogOut', null, { root: true })
    } finally {
        commit('loading', false)
    }
}

/**
 * Fetches Incident by `entrycode`.
 * 
 * @param {Object} {commit,rootState} - vuex methods
 * @param {int} entrycode - `entrycode` of incident to retrieve
 */
const fetchIncidentByEntryCode = async ({commit, rootState}, entrycode) => {
    try {
        commit('loading', true)
        let incident = await api.getIncidentByEntryCode(entrycode, rootState.newauth.token)
        commit('SET_INCIDENT_ENTRYCODE', incident)
    } catch (error) {
        // FIXME: parse the error in a meaningful way.
        commit('error', error)
    } finally {
        commit('loading', false)
    }
}

/**
 * Posts a new Incident to the backend.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @returns Incident.data
 */
const createIncident = async ({commit, dispatch, rootState}) => {
    try {
        commit('loading', true)
        let incident = await api.postIncident(rootState.newauth.token)
        dispatch('messages/showMessage',
                {message: `Successfully created new Incident`, color: 'success'},
                {root: true})
        return incident.data
    } catch (error) {
        commit('error', error)
        // FIXME: push to login again, since we are not authenticated any more
        await dispatch('newauth/actionLogOut', null, { root: true })
    } finally {
        commit('loading', false)
    }
}

/**
 * Deletes an `Incident` with the given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {int} entrycode - entrycode of `Incident` to delete
 */
const deleteIncident = async ({commit, dispatch, rootState}, entrycode) => {
    try {
        commit('loading', true)
        await api.deleteIncident(entrycode, rootState.newauth.token)
        commit('DEL_INCIDENT', entrycode)
        dispatch('messages/showMessage',
                {message: `Successfully deleted Incident: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        // FIXME: revisit the error handling.
        commit('error', error)
        throw error
    } finally {
        commit('loading', false)
    }
}

/**
 * Posts an entrycode with a live boolean flag. This sets
 * the `live` status of the `Incident` with the given `entrycode`.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} payload - sets entrycode and live status
 */
const toggleLive = async ({commit, dispatch, rootState}, payload) => {
    try {
        await api.postLive(payload, rootState.newauth.token)
        commit('SET_LIVE', payload)
        dispatch('messages/showMessage',
                {message: `Incident live setting set to: ${payload}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', error)
    }
}

/**
 * Posts InformantDetails to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updInformant = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        // in the case of Informant we need to add the entrycode
        // in the Informant object as well
        payload.entryCode = entrycode
        await api.postInformant(entrycode, payload, rootState.newauth.token)
        commit('SET_INFORMANT', payload)
        dispatch('messages/showMessage',
                {message: `Updated Informant Details for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', error)
        console.log(error)
        // FIXME: push to login again, since we are not authenticated any more
        await dispatch('newauth/actionLogOut', null, { root: true })
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts NotificationInfo to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updNotification = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        // clone payload to format the dates...
        let _payload = JSON.parse(JSON.stringify(payload))
        _payload.eventDate = formatDate(_payload.eventDate)
        _payload.notificationDay = formatDate(_payload.notificationDay)
        await api.postNotification(entrycode, _payload, rootState.newauth.token)
        commit('SET_NOTIFICATION', _payload)
        dispatch('messages/showMessage',
                {message: `Updated Notification info for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', error)
        // FIXME: push to login again, since we are not authenticated any more
        await dispatch('newauth/actionLogOut', null, { root: true })
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts EventLocationInfo to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch, rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updEventLocation = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postEventLocation(entrycode, payload, rootState.newauth.token)
        commit('SET_EVENTLOCATION', payload)
        dispatch('messages/showMessage',
                {message: `Updated Event Location for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', error)
        await dispatch('newauth/actionLogOut', null, { root: true })
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}


/**
 * Posts DeadAnimalFound to Incident with given entrycode.
 * Parses the internal pages for image attachments and uploads them
 * to the server.
 *  
 * @param {Object} {commit,dispatch, rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updDanimal = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    // check if there are images in the payload
    try {
        commit('loading', true)
        for(let i = 0; i < payload.deadanimalinfopages.length; i++) {
            let images = payload.deadanimalinfopages[i].hasOwnProperty('imagebuffer') ? payload.deadanimalinfopages[i].imagebuffer : []
            if (images.length > 0) {
                try {
                    await api.postFiles(
                        entrycode,
                        'DeadAnimalInfo',
                        images)
                    console.log('posting images correctly')
                } catch (error) {
                    console.log('error posting the images')
                    console.log(error)
                }
            }                
        }
    } catch (error) {
        // FIXME: check error handling
        console.log('error posting the images')
        console.log(error)
    } finally {
        commit('loading', false)
    }

    try {
        commit('loading', true)
        await api.postDanimal(entrycode, payload, rootState.newauth.token)
        commit('SET_DANIMAL', payload)
        dispatch('messages/showMessage',
                {message: `Updated Dead Animal Found for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        //FIXME: check error handling
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts AliveAnimalFound to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updAlanimal = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postAlanimal(entrycode, payload, rootState.newauth.token)
        commit('SET_ALANIMAL', payload)
        dispatch('messages/showMessage',
                {message: `Updated Alive Animal Found for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts PoisonBaitFound to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updPoisonbait = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postPoisonbait(entrycode, payload, rootState.newauth.token)
        commit('SET_POISONBAIT', payload)
        dispatch('messages/showMessage',
                {message: `Updated Poison Bait Info for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts EvidenceFound to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updEvidence = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postEvidence(entrycode, payload, rootState.newauth.token)
        commit('SET_EVIDENCE', payload)
        dispatch('messages/showMessage',
                {message: `Updated Evidence Info for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts Motive to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updMotive = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postMotive(entrycode, payload, rootState.newauth.token)
        commit('SET_MOTIVE', payload)
        dispatch('messages/showMessage',
                {message: `Updated Motive for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts Authorities to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updAuthorities = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postAuthorities(entrycode, payload, rootState.newauth.token)
        commit('SET_AUTHROTIES', payload)
        dispatch('messages/showMessage',
                {message: `Updated Authorities Info for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts AntipoisonDogUnit to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updAntipoisondog = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postAntipoisondog(entrycode, payload, rootState.newauth.token)
        commit('SET_ANTIPOISONDOG', payload)
        dispatch('messages/showMessage',
                {message: `Updated AntipoisonDogUnit for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts Samples to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updSamples = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        // We have the date: dateSent
        let _payload = JSON.parse(JSON.stringify(payload))
        _payload.samplecollectedinfopages.forEach(page => {
            page['dateSent'] = formatDate(page['dateSent'])
        })
        await api.postSamples(entrycode, _payload, rootState.newauth.token)
        commit('SET_SAMPLES', _payload)
        dispatch('messages/showMessage',
                {message: `Updated Samples Collected for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts Disposal to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updDisposal = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postDisposal(entrycode, payload, rootState.newauth.token)
        commit('SET_DISPOSAL', payload)
        dispatch('messages/showMessage',
                {message: `Updated Disposal of Animals for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts OtherReasons to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updOtherreasons = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postOtherreasons(entrycode, payload, rootState.newauth.token)
        commit('SET_OTHERREASONS', payload)
        dispatch('messages/showMessage',
                {message: `Updated Other Reasons for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts OfficialComplaint to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updComplaint = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        // We have the date: dateComplaint, dateOfCourt
        let _payload = JSON.parse(JSON.stringify(payload))
        if (_payload.dateComplaint) {
            _payload.dateComplaint = formatDate(_payload.dateComplaint)
        }
        if (_payload.dateOfCourt) {
            _payload.dateOfCourt = formatDate(_payload.dateOfCourt)
        }
        await api.postComplaint(entrycode, _payload, rootState.newauth.token)
        commit('SET_COMPLAINT', _payload)
        dispatch('messages/showMessage',
                {message: `Updated Official Complaint for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts Autopsy to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and paylaod
 */
const updAutopsy = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        // We have the date: dateOfReport
        let _payload = JSON.parse(JSON.stringify(payload))
        _payload.dateOfReport = formatDate(_payload.dateOfReport)
        await api.postAutopsy(entrycode, _payload, rootState.newauth.token)
        commit('SET_AUTOPSY', _payload)
        dispatch('messages/showMessage',
                {message: `Updated Autopsy and Toxicological Analysis for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts Toxins to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updToxins = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postToxins(entrycode, payload, rootState.newauth.token)
        commit('SET_TOXINS', payload)
        dispatch('messages/showMessage',
                {message: `Updated Toxins Used for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts GeneralComment to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updGcomment = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postGcomment(entrycode, payload, rootState.newauth.token)
        commit('SET_GCOMMENT', payload)
        dispatch('messages/showMessage',
                {message: `Updated General Comment for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts PublicInfo to Incident with given entrycode.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident and payload
 */
const updPublic = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postPublic(entrycode, payload, rootState.newauth.token)
        commit('SET_PUBLIC', payload)
        dispatch('messages/showMessage',
                {message: `Updated Public Info for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}


/**
 * Posts base64 encoded files to a given category of an Incident.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,category,payload} - entrycode of Incident, category to upload and payload containing base64 files.
 */
const updFiles = async ({commit, dispatch, rootState}, {entrycode, category,  payload}) => {
    try {
        commit('loading', true)
        // FIXME: should definitely not be awaited
        await api.postFiles(entrycode, category, payload, rootState.newauth.token)
        dispatch('messages/showMessage',
                {message: `Uploaded files for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts base64 encoded files to an Incident with the given entrycode.
 * Focuses on uploading images directly to the Incident, regardless of category.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident to upload to, payload containing base64 files.
 */
const updFilesSimple = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    // FIXME: needs to have a return value for the path of the files on the server
    // and update the thumbnails in the location of the updating of the files.
    try {
        commit('loading', true)
        await api.postFilesSimple(entrycode, payload, rootState.newauth.token)
        dispatch('messages/showMessage',
                {message: `Uploaded Files for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Posts base64 encoded files to an Incident with the given entrycode.
 * Focuses on uploading documents directly to the Incident, regardless of category.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident to upload to, payload containing base64 files.
 */
const updFilesDoc = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.postFilesDoc(entrycode, payload, rootState.newauth.token)
        dispatch('messages/showMessage',
                {message: `Uploaded Files for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Deletes image file from Incident with given entrycode.
 * Payload contains the uuid of the file to delete.
 * 
 * @param {Obejct} {commit,dispatch,rootState} - vuex methods
 * @param {Object} {entrycode,payload} - entrycode of Incident to delete from, payload contains file uuid.
 */
const delFilesSimple = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.delImage(entrycode, payload, rootState.newauth.token)
        dispatch('messages/showMessage',
                {message: `Deleted Files for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Deletes document from Incident with given entrycode.
 * Payload contains the uuid of the file to delete.
 * 
 * @param {Object} {commit,dispatch,rootState} - vuex methods.
 * @param {Object} {entrycode,payload} - entrycode of Incident to delete from, payload conains file uuid.
 */
const delDocuments = async ({commit, dispatch, rootState}, {entrycode, payload}) => {
    try {
        commit('loading', true)
        await api.delDocument(entrycode, payload, rootState.newauth.token)
        dispatch('messages/showMessage',
                {message: `Deleted Files for: ${entrycode}`, color: 'success'},
                {root: true})
    } catch (error) {
        commit('error', JSON.stringify(error))
        throw error
    } finally {
        commit('loading', false)
        dispatch('version/fetchVersionIncident', null, {root:true})
        dispatch('fetchIncidentByEntryCode', entrycode)
    }
}

/**
 * Fetches all Index objects lastly modified by given user.
 * 
 * @param {Object} {commit,rootState} - vuex methods.
 */
const fetchLastModifiedByUser = async ({commit, rootState}) => {
    try {
        commit('loading', true)
        let indexes = await api.getLastModifiedByUser(rootState.newauth.token)
        commit('lastmodifiedbyuser', indexes.data)
    } catch (error) {
        commit('error', error)
    } finally {
        commit('loading', false)
    }
}

/**
 * Fetches all Index objects lastly modified. Method permitted by
 * superusers.
 * 
 * @param {Object} {commit,rootState} - vuex methods.
 */
const fetchLastModified = async ({commit, rootState}) => {
    try {
        commit('loading', true)
        let indexes = await api.getLastModified(rootState.newauth.token)
        commit('lastmodified', indexes.data)
    } catch (error) {
        commit('error', error)
    } finally {
        commit('loading', false)
    }
}

/**
 * Fetches the total number of incidents.
 * 
 * @param {Object} {commit,rootState} - vuex methods.
 */
const fetchTotal = async ({commit, rootState}) => {
    try {
        commit('loading', true)
        let total = await api.getTotal(rootState.newauth.token)
        commit('total', total.data)
    } catch (error) {
        commit('error', error)
    } finally {
        commit('loading', false)
    }
}

const actions = {
    chkLastUpdate,
    fetchIndexes,
    fetchIncidentById,
    fetchIncidentByEntryCode,
    createIncident,
    deleteIncident,
    toggleLive,
    updInformant,
    updNotification,
    updEventLocation,
    updDanimal,
    updAlanimal,
    updPoisonbait,
    updEvidence,
    updMotive,
    updAuthorities,
    updAntipoisondog,
    updSamples,
    updDisposal,
    updOtherreasons,
    updComplaint,
    updAutopsy,
    updToxins,
    updGcomment,
    updPublic,
    updFiles,
    updFilesSimple,
    updFilesDoc,
    delFilesSimple,
    delDocuments,
    fetchLastModified,
    fetchLastModifiedByUser,
    fetchTotal
}

const getters = {
    getIndexes: state => state.indexes,
    getIncident: state => state.incident,
    getIncidentByEntryCode: (state) => _entrycode => {
        return state.incidents.find(({entrycode}) => entrycode === _entrycode)
    },
    // Alternative way to do a getter with conditions and
    // state management.
    // getIncidentByEntryCode: (state) => (entry) => {
    //     console.log('This is the getter getIncidentByEntryCode')
    //     // Check if data is still loading
    //     if (state.loading) {
    //         console.log('Data is still loading ...')
    //         return "Data is still loading..."
    //     }

    //     // Check for any error
    //     if (state.error) {
    //         console.log('there is an error in the process...')
    //         return "An error occurred: " + state.error.message;
    //     }

    //     // Search for an object with the matching entry
    //     const foundObject = state.data.find(item => item.entry === entry)

    //     if (foundObject) {
    //         console.log('we have found our object...')
    //         return foundObject
    //     } else {
    //         console.log('there is no object found')
    //         return `No object found with entry: ${entry}`
    //     }
    // },
    getIncidentById: (state) => _id => {
        return state.incidents.find(({id}) => id === _id)
    },
    getIndexesByCountry: (state) => (_country) => {
        return state.indexes.filter((el) => {
            return el.country === _country
        })
    },
    getLastModified: (state) => state.lastmodified,
    getLastModifiedByUser: (state) => state.lastmodifiedbyuser,
    indexLoading: state => state.loading,
    firstLoad: state => state.firstget,
    getTotal: state => state.total
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}