
export function formatDate(date) {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
}


export function cloneObject(obj) {
    var clone = {};
    for (var i in obj) {
        if (typeof (obj[i]) == "object" && obj[i] != null)
            clone[i] = cloneObject(obj[i]);
        else
            clone[i] = obj[i];
    }
    return clone;
}

// TAKEN FROM: https://stackoverflow.com/a/32922084
export function deepEqual(x, y) {
    return (x && y && typeof x === 'object' && typeof y === 'object') ?
        (Object.keys(x).length === Object.keys(y).length) &&
        Object.keys(x).reduce(function (isEqual, key) {
            return isEqual && deepEqual(x[key], y[key]);
        }, true) : (x === y);
}

export function diffObject (obj1, obj2) {
    let pvt = {}
    Object.keys(obj1).forEach(key => {
        if (obj1[key] !== obj2[key]) {
            pvt[key] = obj2[key]
        }
    })
    return pvt
}

export function flattenObject(obj, parentKey = '', result = {}) {
    for (let key in obj) {
        let newKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
            flattenObject(obj[key], newKey, result);
        } else {
            result[newKey] = obj[key];
        }
    }
    return result;
}


export function print(obj) {
    return console.log(JSON.stringify(obj, null, 2))
}

// Interaction with the localstorage
export const getLocalToken = () => localStorage.getItem('token');

export const saveLocalToken = (token) => localStorage.setItem('token', token);

export const removeLocalToken = () => localStorage.removeItem('token');

// TAKEN FROM: https://stackoverflow.com/a/14794066
export function isInt(value) {
    return !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10));
}


// Get all markers from a given incident.
// Coordinates exist in:
// - PoisonLocationInfo
// - DeadAnimalInfo (loop)
// - AliveAnimalInfo (loop)
// - PoisonBaitInfo (loop)
// - EvidenceInfo (loop)


function uniqueArray1( ar ) {
    var j = {};
  
    ar.forEach( function(v) {
      j[v+ '::' + typeof v] = v;
    });
  
    return Object.keys(j).map(function(v){
      return j[v];
    });
} 

export function getMarkers(incident) {
    let markers = []
    for (let [key,value] of Object.entries(incident.data)) {
        if(!key) continue
        if (key === 'PoisonLocationInfo') {
            if (key) {
                let index = markers.length
                markers.push({
                    'name': `M${index+1}`,
                    draggable: true,
                    visible: true,
                    position: {
                        lat: value['lat'],
                        lng: value['lng']
                    }
                })
            }
        } else if (key === 'DeadAnimalInfo') {
            let index = markers.length
            if (!value.deadanimalinfopages)
                continue
            if (value.deadanimalinfopages.length > 0) {
                for (let _page of value.deadanimalinfopages) {
                    if (_page.lat > 0) {
                        let index = markers.length
                        markers.push({
                            'name': `M${index+1}`,
                            draggable: true,
                            visible: true,
                            position: {
                                lat: _page['lat'],
                                lng: _page['lng']
                            }
                        })
                    }
                } 
            }
        } else if (key === 'AliveAnimalInfo') {
            let index = markers.length
            if (!value.aliveanimalinfopages)
                continue
            if (value.aliveanimalinfopages.length > 0) {
                for (let _page of value.aliveanimalinfopages) {
                    if (_page.lat > 0) {
                        let index = markers.length
                        markers.push({
                            'name': `M${index+1}`,
                            draggable: true,
                            visible: true,
                            position: {
                                lat: _page['lat'],
                                lng: _page['lng']
                            }
                        })
                    }
                }
            }
        } else if (key === 'PoisonBaitInfo') {
            let index = markers.length
            if (!value.poisonbaitinfopages)
                continue
            if (value.poisonbaitinfopages.length > 0) {
                for (let _page of value.poisonbaitinfopages) {
                    if (_page.lat > 0) {
                        let index = markers.length
                        markers.push({
                            'name': `M${index+1}`,
                            draggable: true,
                            visible: true,
                            position: {
                                lat: _page['lat'],
                                lng: _page['lng']
                            }
                        })
                    }
                }
            }
        } else if (key === 'EvidenceInfo') {
            let index = markers.length
            if (!value.evidenceinfopages)
                continue
            if (value.evidenceinfopages.length > 0) {
                for (let _page of value.evidenceinfopages) {
                    if (_page.lat > 0) {
                        let index = markers.length
                        markers.push({
                            'name': `M${index+1}`,
                            draggable: true,
                            visible: true,
                            position: {
                                lat: _page['lat'],
                                lng: _page['lng']
                            }
                        })
                    }
                }
            }
        }
    }
    // make sure the markers are unique
    // var uniq_markers = [...new Set(markers)]
    // var uniq_markers = uniqueArray1(markers);
    // return uniq_markers
    return markers
}