<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Authorities involved in Manage of Event</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-select
          v-model="newData.publicAuthoritiesContacted"
          label="Public authorities contacted"
          :items="StdChoices"
          required
          :rules="required"
          @change="numberFieldSelectChange"
        />
        <v-text-field
          v-model.number="newData.numberAuthorities"
          label="Number of authorities"
          type="number"
          required
          outlined
          filled
          :rules="numberfieldcontrol"
          @input="totalNumber"
          :disabled="numberFieldDisable"
        />

        <!-- AuthoritiesPage -->
        <!-- Extention part -->
        <template v-if="newData.numberAuthorities > 0">
          <div v-for="(addedform, i) in adder" :key="i">
            <v-divider class="mb-5" />
            <p>Use ADD button in the end to extend the form for items that have different information</p>
            <v-text-field 
              v-model.number="addedform.number"
              type="number"
              @input="checknumbers"
              :rules="numberRule" 
              label="# Authorities of same description"
              outlined
            />
            <v-text-field
              v-model="addedform.agency"
              label="Agency"
              :disabled="!addedform.number"
            />
            <v-select
              v-model="addedform.timeNotified"
              label="Time notified"
              :items="TimeNotified"
              :disabled="!addedform.number"
            />
            <v-select
              v-model="addedform.presentAtManagement"
              label="Present at management"
              :items="StdChoices"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.reason"
              label="Specify reason if not present"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.nameSurnameContact"
              label="Name and surname of contact"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.position"
              label="Position"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.phone"
              label="Phone"
              :disabled="!addedform.number"
            />
            <v-text-field
              v-model="addedform.email"
              label="Email"
              :disabled="!addedform.number"
            />
          </div>
        </template>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn
        v-if="addbuttonrules"
        :disabled="addbuttondis"
        color="warning"
        @click.prevent="addform"
      >
        ADD
      </v-btn>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        color="grey lighten-2"
        @click="reset"
      >Clear</v-btn>
      <v-btn
        :disabled="!valid || !isDirty"
        color="#7eb09f"
        @click="validate"
      >Save
        <v-progress-circular color="biscuit" v-if="loading" indeterminate/>      
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { sync, get } from 'vuex-pathify'
import MultiPageMixin from '@/mixins/MultiPage.js'
import AuthoritiesManageOfEvent from '@/models/authoritiesmanageofevent.js'
import AuthoritiesPage from '@/models/authoritiespage.js'
import { StdChoices, TimeNotified } from '@/utils/selectoptions.js'
import { cloneObject } from '@/utils/general.js'

export default {
  mixins: [MultiPageMixin],
  props: {
    AuthoritiesManageOfEvent: {
      type: Object,
      default: function () {
        return {}
      }
    },
    passed: {
      type: Boolean,
      default: false
    },
    entrycode: {
      type: Number
    }
  },
  data: function () {
    return {
      pageclass: AuthoritiesPage,
      pagesarray: 'authoritiespages',
      numbername: 'numberAuthorities',
      valid: false,
      loading: false,
      required: [
        v => !!v || 'Field is required'
      ],
      // The following rule is used to control the number values
      // based on the select option. Used together with the 
      // numberFieldDisable
      numberfieldcontrol: [
        // v => !!v || 'Field is required',
        v => { if (!isNaN(parseFloat(v)) && v >= -1 && v <= 999) return true;
          return 'Number has to be between -1 and 999';}
      ],
      numberFieldDisable: false,
      StdChoices: StdChoices,
      TimeNotified: TimeNotified
    };
  },
  methods: {
    validate () {
      this.loading=true
      if(this.$refs.form.validate()) {
        // check the adder contents
        this.sanitycheck()
        this.newData.authoritiespages = this.adder
        this.$store.dispatch('incidentsnew/updAuthorities',
          {entrycode: this.entrycode,
           payload: this.newData})
          .then(() => {
            this.loading = false
            this.initData = cloneObject(this.newData)
          })
          .catch(e => {
            console.log('WE GOT THE ERROR')
            console.log(this.$store.state.incidentsnew.error)
          })  // FIXME
      } else {
        this.loading = false
      }
    },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    numberFieldSelectChange (e) {
      switch (e) {
        case 'Unknown':
          this.newData.numberAuthorities = -1
          this.numberFieldDisable = true
          break;
        case 'No':
          this.newData.numberAuthorities = 0
          this.numberFieldDisable = true
          break;
        case 'Yes':
          // this.newData.numberAuthorities = null
          this.numberFieldDisable = false
          break;
      }
    }
  },
  created () {
    this.newData = new AuthoritiesManageOfEvent()
  },
  mounted () {
    if (this.passed) {
      this.initData = this.AuthoritiesManageOfEvent
      this.newData = JSON.parse(JSON.stringify(this.AuthoritiesManageOfEvent))

      if ('authoritiespages' in this.initData) {
        if (this.newData.authoritiespages.length > 0) {
          this.adder = [...this.initData.authoritiespages]
          this.maintotal = this.newData.numberAuthorities
        }
      }
      this.$refs.form.resetValidation()
      this.checknumbers()
    }
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...get('form', [
      'isDirty'
    ])
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>