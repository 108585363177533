/* eslint-disable */
import Vue from 'vue'
import VueI18n from "vue-i18n";

// import the languages from the vuetify lib
import en from 'vuetify/lib/locale/en'
import el from 'vuetify/lib/locale/el'
import de from 'vuetify/lib/locale/de'
//import al from 'vuetify/lib/locale/al'
//import ba from 'vuetify/lib/locale/ba'
import bg from 'vuetify/lib/locale/bg'
import hr from 'vuetify/lib/locale/hr'
//import mk from 'vuetify/lib/locale/mk'
import rs from 'vuetify/lib/locale/sr-Cyrl'

Vue.use(VueI18n)

const messages = {
    en: {
        ...require('@/locales/en.json'),
        $vuetify: en,
    },
    el: {
        ...require('@/locales/el.json'),
        $vuetify: el,
    },
    // DON'T NEED GERMAN YET
    // de: {
    //     ...require('@/locales/de.json'),
    //     $vuetify: de,
    // },
    al: {
        ...require('@/locales/al.json'),
        //$vuetify: al,
    },
    ba: {
        ...require('@/locales/ba.json'),
        //$vuetify: ba,
    },
    bg: {
        ...require('@/locales/bg.json'),
        //$vuetify: bg,
    },
    hr: {
        ...require('@/locales/hr.json'),
        $vuetify: hr,
    },
    mk: {
        ...require('@/locales/mk.json'),
        //$vuetify: mk,
    },
    rs: {
        ...require('@/locales/rs.json'),
        $vuetify: rs,
    }
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages
})

// OLD IMPLEMENTATION

// export function setupI18n(Vue, isProduction) {
//     Vue.use(VueI18n)

//     const i18n = new VueI18n({
//         locale: 'en',
//         fallbackLocale: 'en',
//         fallbackRoot: false,
//         silentTranslationWarn: true,

//         missing(locale, key, vm) {
//             // TODO
//             return key;
//         }
//     })

//     i18n.setLocaleMessage('en', require('../../src/locales/en.json'))
//     i18n.setLocaleMessage('gr', require('../../src/locales/gr.json'))
//     i18n.setLocaleMessage('de', require('../../src/locales/de.json'))

//     return i18n
// }

