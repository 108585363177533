<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Official Complaint Info</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-select
          v-model="newData.complaint"
          label="Complaint"
          :items="StdChoices"
          required
          :rules="required"
        />
        <v-text-field
          v-model="newData.ifNotWhy"
          label="If not why"
          v-if="newData.complaint === 'No'"
        />

        <v-menu
          v-model="dateComplaintMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateComplaint"
              label="Date of complaint"
              hint="if different from reporting date"
              persistent-hint
              readonly
              v-on="on"
              outlined
              append-icon="mdi-calendar"
            />
          </template>
          <v-date-picker
            v-model="newData.dateComplaint"
            :locale="locale"
            min="1980-01-01"
            max="2050-01-01"
            @change="setDateComplaint"
            @input="dateComplaintMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-select
          v-model="newData.authority"
          label="Authority"
          :items="Authority"
          required
          :rules="[requiredIfComplaint]"
          :disabled="newData.complaint == 'No'"
        />
        <v-select
          v-model="newData.investCarriedOut"
          label="Investigation carried out"
          :items="StdChoicesExt"
          required
          :rules="[requiredIfComplaint]"
          :disabled="newData.complaint == 'No'"
        />

        <v-textarea
          v-model="newData.authoritiesInInvestigation"
          label="Authorities involved in investigation"
          filled
        />
        <v-select
          v-model="newData.caseTakenToCourt"
          label="Case taken to court"
          :items="StdChoicesExt"
          required
          :rules="[requiredIfComplaint]"
          :disabled="newData.complaint == 'No'"
        />

        <v-menu
          v-model="dateOfCourtMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateOfCourt"
              label="Date of court"
              readonly
              v-on="on"
              append-icon="mdi-calendar"
            />
          </template>
          <v-date-picker
            v-model="newData.dateOfCourt"
            :locale="locale"
            min="1980-01-01"
            max="2050-01-01"
            @change="setDateOfCourt"
            @input="dateOfCourtMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-select
          v-model="newData.caseSolved"
          label="Case solved"
          :items="CaseSolvedChoices"
          required
          :rules="[requiredIfComplaint]"
          :disabled="newData.complaint == 'No'"
        />
        <v-text-field
          v-model="newData.sentence"
          label="Sentence"
        />
        <v-textarea
          v-model="newData.comments"
          label="Comments"
          filled
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        color="grey lighten-2"
        @click="reset"
      >Clear</v-btn>
      <v-btn
        :disabled="!valid || !isDirty"
        color="#7eb09f"
        @click="validate"
      >Save
        <v-progress-circular color="biscuit" v-if="loading" indeterminate/>          
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { sync, get } from 'vuex-pathify'
import OfficialComplaintInfo from '@/models/officialcomplaintinfo.js'
import { StdChoices, StdChoicesExt, Authority, CaseSolvedChoices } from '@/utils/selectoptions.js'
import { formatDate, cloneObject } from '@/utils/general.js'
import { DateTime } from 'luxon'

export default {
  props: {
    OfficialComplaintInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    passed: {
      type: Boolean,
      default: false
    },
    entrycode: {
      type: Number
    }
  },
  data: function () {
    return {
      valid: false,
      loading: false,
      required: [
        v => !!v || 'Field is required'
      ],
      dateOfCourtMenu:  false,
      dateComplaintMenu: false,
      dateComplaint: this.passed ? this.OfficialComplaintInfo.dateComplaint : "",
      dateOfCourt: this.passed ? this.OfficialComplaintInfo.dateOfCourt : "",
      StdChoices: StdChoices,
      StdChoicesExt: StdChoicesExt,
      Authority: Authority,
      CaseSolvedChoices: CaseSolvedChoices
    };
  },
  methods: {
    validate () {
      this.loading = true
      if (this.$refs.form.validate()) {
      this.$store.dispatch('incidentsnew/updComplaint', {entrycode: this.entrycode, payload: this.newData})
          .then(() => {
            this.loading = false
            this.initData = cloneObject(this.newData)
          })
          .catch(e => {
            console.log('WE GOT THE ERROR')
            console.log(this.$store.state.incidentsnew.error)
          })  // FIXME
      } else {
        this.loading = false
      }
    },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    setDateOfCourt (evt) {
      this.dateOfCourt = formatDate(evt) 
    },
    setDateComplaint (evt) {
      this.dateComplaint = formatDate(evt)
    },
    requiredIfComplaint (evt) {
      if (this.newData.complaint == 'Yes') {
        return !!evt || 'Field is required'
      } else {
        return true
      }
    }
  },
  created () {
    // this.newData = new OfficialComplaintInfo()
  },
  mounted () {
    if (this.passed) {
      this.initData = {...this.OfficialComplaintInfo}
      this.newData =  JSON.parse(JSON.stringify(this.OfficialComplaintInfo))

      if (this.newData.dateComplaint) {
        this.newData.dateComplaint = DateTime.fromFormat(this.newData.dateComplaint, 'dd/MM/yyyy').toISODate()
        this.initData.dateComplaint = DateTime.fromFormat(this.initData.dateComplaint, 'dd/MM/yyyy').toISODate()
      }
      if (this.newData.dateOfCourt) {
        this.newData.dateOfCourt = DateTime.fromFormat(this.newData.dateOfCourt, 'dd/MM/yyyy').toISODate()
        this.initData.dateOfCourt = DateTime.fromFormat(this.initData.dateOfCourt, 'dd/MM/yyyy').toISODate()
      }
      this.$refs.form.resetValidation()
    }
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...get('form', [
      'isDirty'
    ]),
    locale () {
      return this.$i18n.locale
    }
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>