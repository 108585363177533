// Utilities
import { make } from 'vuex-pathify'

const state = {
  token: "",
  isLoggedIn: false,
  logInError: false,
  userProfile: null,
  notification: {}
}

const mutations = make.mutations(state)

const actions = {

  

}

const getters = {
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
