<template>
  <v-card>
    <v-card-title class="cardtitle mb-2 rounded-0">Upload documents</v-card-title>
    <v-card-text>
      <v-data-table
        v-model="foobar"
        :items="media"
        :headers="headers"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn @click.stop="downloadDocument(item)">Download</v-btn>
          <v-btn @click=getConfirm(item.id)>Delete</v-btn>
        </template>
      </v-data-table>
        <!-- <v-card v-for="(image, index) in media"
            :key=index
        >
            <v-card-text>
                <v-img
                    :src="image.thumb"
                    max-height="150"
                    max-width="150"
                />
                <h4 class="pa-2">Description:</h4>
                {{ image.caption }}
                <template v-if="image.tags.length > 0">
                  <h4 class="pa-2">Tags:</h4>
                  <v-chip v-for="(tag, i) in image.tags" :key="i">{{ tag }}</v-chip>
                </template>
            </v-card-text>
            <v-card-actions>
                <v-btn @click=getConfirm(image.id,index) >
                    Delete
                </v-btn>
            </v-card-actions>
        </v-card>     -->
    </v-card-text>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <handy-uploader
            :documentAttachment.sync="handyAttachments"
            :fileUploaderType="'table'"
            :maxFileSize="10240"
            :imageCompressor="true"
            :imageCompressLevel="0.8"
            :maxFileCount="10"
            :badgeCounter="true"
            :changeFilename="true"
            :addFileDescription="true"
            :addFileTag="true"
            :tags="tags"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="error"
        @click="cancel"
      >Cancel</v-btn>
      <v-btn
        :disabled="!hasAttachments"
        color="#7eb09f"
        @click="validate"
      >Save
         <v-progress-circular color="biscuit" v-if="loading" indeterminate/>      
      </v-btn>
    </v-card-actions>

    <!-- START - CONFIRM DELETE DIALOG -->
    <v-dialog
      v-model="deletedialog"
      persistent
      width="350"
      :ripple="foo"
      style="z-index: 1301"
    >
      <v-card>
        <v-toolbar color="warning" class="justify-center mb-2 rounded-0">
                <v-toolbar-title>Confirm Document Delete</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <p>You are about to delete a document. If you are sure,
                    type the following string:</p>
                <p class="justify-center text-center" style="background-color: #e6e6e6">
                    <pre>delete</pre>
                </p>
                <p>in the text input below and press confirm.</p>
                <v-form
                    v-model="delvalid"
                    ref="confirmform"
                    lazy-validation
                > 
                    <v-text-field
                        v-model="deletecode"
                        :rules="confirmRule"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="error" @click.stop="delcancel">Cancel</v-btn>
                <v-btn color="warning" @click.stop="delconfirm">Confirm</v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END -->
  </v-card>
</template>

<script>
import { sync, get } from 'vuex-pathify'
import Documents from '@/models/documents.js'
import handyUploader from 'handy-uploader/src/components/handyUploader.vue'
import { documentTags } from '@/utils/tags.js'

import ExportFileService from '@/services/ExportFileService'

export default {
  components: {
    handyUploader,
  },
  props: {
    Documents: {
      type: Object,
      default: function () {
        return {};
      },
    },
    passed: {
      type: Boolean,
      default: false,
    },
    entrycode: {
      type: Number
    }
  },
  data: function () {
    return {
      index: null,
      handyAttachments: [],
      tags: documentTags,
      valid: false,
      loading: false,
      required: [
        v => !!v || 'Field is required'
      ],
      // START DELETE DIALOG
      delvalid: false,
      deletedialog: false,
      todelete: "delete",
      deletecode: "",
      delid: "",
      delindex: "",
      foo: false,
      // END DELETE DIALOG,
      foobar: [],
      headers: [
        {
          text: 'id',
          value: 'id',
        },
        {
          text: 'Filename',
          value: 'filename'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Tags',
          value: 'tags'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    };
  },
  methods: {
    async validate () {
      this.loading = true
      console.log('testing now mate')

      try {
          await this.$store.dispatch('incidentsnew/updFilesDoc', {
              entrycode: this.entrycode,
              payload: this.handyAttachments
          })
          this.loading = false
          window.location.reload()
      } catch (error) {
          console.log(error)
          this.loading = false
      }
    },
    cancel () {
      this.$router.back();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    getConfirm (id, index) {
      this.delid = id
      this.delindex = index
      this.deletedialog = true
    },
    delcancel () {
        this.$refs.confirmform.resetValidation()
        this.deletecode = ""
        this.todelete = ""
        this.delid = ""
        this.delindex = ""
        this.deletedialog = false
    },
    async delconfirm () {
        if (this.$refs.confirmform.validate()) {
            this.deletecode = ""
            // delete the image
            try {
              await this.$store.dispatch('incidentsnew/delDocuments', {
                entrycode: this.entrycode,
                payload: this.delid
              })
            } catch (error) {
              console.log(error)
            }
            let i = this.initData.documents.map(item => item.id).indexOf(this.delid)
            this.initData.documents.splice(i,1)
        } else {
            this.foo = true
            console.log('the validation is not correct')
        }
        this.deletecode = ""
        this.delentrycode = ""
        this.delid = ""
        this.delindex = ""
        this.$refs.confirmform.resetValidation()
        this.deletedialog = false
    },
    async downloadDocument (item) {
      this.loading = true

      // Get the filetype of the file
      let filetype = item.filename.toLowerCase().split('.')[1]

      ExportFileService.exportDocument(this.entrycode, item.id, item.filename, this.$store.state.newauth.token).then((res) => {
        var FILE = window.URL.createObjectURL(
          new Blob([res.data],
          // { type: 'text/csv' }
          )
        );
        this.loading = false;
        var docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', item.filename);
        document.body.appendChild(docUrl);
        docUrl.click();
      }).catch((error) => {
        console.log(error)
        this.loading=false
      })

    }
  },
  created () {
    this.newData = new Documents()
  },
  mounted () {
    if (this.passed) {
      this.initData = {...this.Documents}
      this.newData = this.Documents
      this.$refs.form.resetValidation()
    }
  },
  beforeDestroy () {
    this.handyAttachments = []
  },
  computed: {
    ...sync('form', [
      'initData',
      'newData'
    ]),
    ...get('form', [
      'isDirty'
    ]),
    hasAttachments () {
       if (this.handyAttachments.length == 0) {
           return false
       } else {
           return true
       }
    },
    media () {
        if (!this.initData.documents) return []
        if (this.initData.documents.length > 0 ) {
            let _media = []
            for (let i=0; i != this.initData.documents.length; i ++) {
                let tmp = this.initData.documents[i]
                _media.push({
                    id: tmp.id,
                    filename: tmp.filename,
                    description: tmp.description,
                    tags: tmp.tags
                })
            }
            return _media
        } else {
            return []
        }
    },
    confirmRule() {
        return [
            () => (this.todelete === this.deletecode) || 'Value does not match requested code',
            v => !!v || 'Fill value to confirm'
        ];
      },
  }
};
</script>

<style scoped>
.cardtitle {
  background-color: #7eb09f;
}
</style>