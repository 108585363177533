<template>
  <v-card>
    <v-card-title> LiveEvent </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field v-model="liveEvent" label="Liveevent" />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    liveEvent: False,
  }),
};
</script>