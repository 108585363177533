import ReportService from '@/services/ReportService'

export default {
    async getReports({ commit, rootState }) {
        try {
            commit('SET_LOADING', true)
            let reports = await ReportService.getReports(rootState.newauth.token)
            commit('ADD_REPORTS', reports.data)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log('Error when getting Reports')
            commit('REPORT_ERROR', error)
        }
    },
    async getReport({ commit, rootState }, id) {
        try {
            commit('SET_LOADING', true)
            let report = await ReportService.getReportById(id, rootState.newauth.token)
            commit('ADD_REPORT', report.data)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log(`Error when getting Report with ${id}`)
            commit('REPORT_ERROR', error)
        }
    },
    async createReport({ commit, rootState }, report) {
        try {
            commit('SET_LOADING', true)
            let report_post = await ReportService.postReport(report, rootState.newauth.token)
            commit('ADD_REPORT', report_post.data)
            commit('PUSH_REPORT', report_post.data)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log('Something went wrong when creating the report')
            commit('REPORT_ERROR', error)
        }
    },
    async updateReport({ commit, rootState }, {id, report}) {
        try {
            commit('SET_LOADING', true)
            let report_upd = await ReportService.putReport(id, report, rootState.newauth.token)
            let repData = report_upd.data
            commit('ADD_REPORT', repData)
            commit('UPDATE_REPORT', {id, repData })
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log(`Something went wrong when updating the report: ${id}`)
            commit('REPORT_ERROR', error)
        }
    },
    async deleteReport({ commit, rootState }, id) {
        try {
            commit('SET_LOADING', true)
            await ReportService.deleteReport(id, rootState.newauth.token)
            commit('DELETE_REPORT', id)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            console.log(`Something went wrong when deleting report: ${id}`)
            commit('REPORT_ERROR', error)
        }
    },
    async generateIncident({ commit, rootState }, id) {
        try {
            commit('SET_LOADING', true)
            let response = await ReportService.createIncident(id, rootState.newauth.token)
            commit('SET_NEW_INCIDENT', response.data)
            commit('SET_LOADING', false)
        } catch (error) {
            commit('SET_LOADING', false)
            commit('REPORT_ERROR', error)
        }
    }
}