export default {
    SET_LOADING(state, loading) {
        state.loading = loading
    },
    STATICPAGES_ERROR(state, error) {
        state.error = error
    },
    ADD_PAGE(state, page) {
        state.page = page
    },
    ADD_PAGES(state, pages) {
        state.pages = pages
    },
    PUSH_PAGE(state, page) {
        state.pages.push(page)
    },
    UPDATE_PAGE(state, {id, page}) {
        state.pages[id] = page
    },
    DELETE_PAGE(state, id) {
        for (let i = 0; i < state.pages.length; i++ ) {
            if (state.pages[i] == id ) {
                state.pages.splice(i, 1)
                return
            }
        }
    }
}