// https://vuex.vuejs.org/en/modules.html

const requireModule = require.context('.', true, /\.js$/)
const modules = {}

requireModule.keys().forEach(fileName => {
    if (fileName === './index.js') return

    // Replace ./ and .js
    const path = fileName.replace(/(\.\/|\.js)/g, '')
    const [moduleName, imported] = path.split('/')

    if (!modules[moduleName]) {
        modules[moduleName] = {
            namespaced: true
        }
    }

    modules[moduleName][imported] = requireModule(fileName).default
})

import app from './app.js'
import user from './user.js'
import form from './form.js'
import mapform from './mapform.js'
import adminusers from './adminusers.js'
import newauth from './newauth.js'
import notifications from './notifications.js'
import exportdata from './exportdata.js'
import incidentsnew from './incidentsnew.js'
import exportserv from './exports.js'
import messages from './messages.js'
import activity from './activity.js'
import chart from './chart.js'
import search from './search.js'
import undelete from './undelete.js'
import version from './version.js'

modules['app'] = app
modules['user'] = user
modules['form'] = form
modules['mapform'] = mapform
modules['adminusers'] = adminusers
modules['newauth'] = newauth
modules['notifications'] = notifications
modules['exportdata'] = exportdata
modules['incidentsnew'] = incidentsnew
modules['exportserv'] = exportserv
modules['messages'] = messages
modules['activity'] = activity
modules['chart'] = chart
modules['search'] = search
modules['undelete'] = undelete
modules['version'] = version

export default modules