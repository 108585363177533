<template>
  <v-card>
    <v-card-title> AuthoritiesPage </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field v-model="agency" label="Agency" />
        <v-select
          v-model="timeNotified"
          label="Time Notified"
          :items="TimeNotified"
        />
        <v-select
          v-model="presentAtManagement"
          label="Present at Management"
          :items="StdChoices"
        />
        <v-text-field v-model="reason" label="Specify reason if not present" />
        <v-text-field v-model="nameSurnameContact" label="Name and Surname of contact" />
        <v-text-field v-model="position" label="Position" />
        <v-text-field v-model="phone" label="Phone" />
        <v-text-field v-model="email" label="Email" />
        
        
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    agency: "",
    timeNotified: "null",
    presentAtManagement: "null",
    reason: "",
    nameSurnameContact: "",
    position: "",
    phone: "",
    email: "",
    TimeNotified: [
      "Before incident management",
      "After incident management",
      "Authority was informant",
      "Unknown",
    ],
    StdChoices: ["Yes", "No", "Unknown"],
  }),
};
</script>