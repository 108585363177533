export const imageTags = [
    'Dead animal',
    'Alive animal',
    'Poison bait',
    'Evidence'
]

export const documentTags = [
    'Report',
    'Analysis'
]